import { Reducer } from 'redux'
import { DADOS_COMPLEMENTARES_INFOS_PESSOAL, TYPES } from './types'

const INITIAL_STATE: DADOS_COMPLEMENTARES_INFOS_PESSOAL = {
  cpf: '',
  formulario: {
    sexo: '',
    estadoCivil: '',
    nomeConjuge: '',
    nacionalidade: 'Brasileira',
    profissaoId: '',
    escolaridadeId: '',
    ufNascimento: '',
    cidadeNascimento: ''
  },
  invalidFormulario: {
    sexo: {
      invalid: false,
      message: ''
    },
    estadoCivil: {
      invalid: false,
      message: ''
    },
    nomeConjuge: {
      invalid: false,
      message: ''
    },
    nacionalidade: {
      invalid: false,
      message: ''
    },
    profissaoId: {
      invalid: false,
      message: ''
    },
    escolaridadeId: {
      invalid: false,
      message: ''
    },
    ufNascimento: {
      invalid: false,
      message: ''
    },
    cidadeNascimento: {
      invalid: false,
      message: ''
    }
  },
  optionsSelects: {
    generos: [],
    escolaridades: [],
    ufsNascimento: [],
    cidadesNascimento: [],
    estadosCivis: [],
    profissoes: []
  },
  loadingSelects: {
    generos: false,
    escolaridades: false,
    ufsNascimento: false,
    cidadesNascimento: false,
    estadosCivis: false,
    profissoes: false
  }
}

const reducer: Reducer<DADOS_COMPLEMENTARES_INFOS_PESSOAL> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TYPES.SET_CPF:
      return {
        ...state,
        cpf: action.value
      }
    case TYPES.SET_FORMULARIO:
      return {
        ...state,
        formulario: action.value
      }
    case TYPES.SET_FORMULARIO_FIELD:
      return {
        ...state,
        formulario: {
          ...state.formulario,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_FORMULARIO:
      return {
        ...state,
        formulario: INITIAL_STATE.formulario
      }
    case TYPES.SET_INVALID_FORMULARIO:
      return {
        ...state,
        invalidFormulario: {
          ...state.invalidFormulario,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_INVALIDS_FORMULARIO:
      return {
        ...state,
        invalidFormulario: INITIAL_STATE.invalidFormulario
      }
    case TYPES.SET_OPTIONS_SELECT:
      return {
        ...state,
        optionsSelects: {
          ...state.optionsSelects,
          [action.field]: action.value
        }
      }
    case TYPES.SET_LOADING_SELECTS:
      return {
        ...state,
        loadingSelects: {
          ...state.loadingSelects,
          [action.field]: action.value
        }
      }
    default:
      return state
  }
}

export default reducer

import {
  Banco,
  Convenio,
  ProdutosSimulacao,
  TipoConta,
  Perfil,
  Invalid
} from 'models/types'

export enum TYPES {
  SET_NOME_COMPLETO = '@@simulacao/SET_NOME_COMPLETO',
  SET_CPF = '@@simulacao/SET_CPF',
  SET_BENEFICIOS = '@@simulacao/SET_BENEFICIOS',
  TOGGLE_DETALHAMENTO = '@@simulacao/TOGGLE_DETALHAMENTO',
  TOGGLE_MODAL_RESUMO_CONTRATACAO = '@@simulacao/TOGGLE_MODAL_RESUMO_CONTRATACAO',
  SET_INFOS_CLIENTE_RESUMO = '@@simulacao/SET_INFOS_CLIENTE_RESUMO',
  SET_LOADING_SIMULACAO = '@@simulacao/SET_LOADING_SIMULACAO',
  SET_INFOS_CARTAO_CONSIG = '@@simulacao/SET_INFOS_CARTAO_CONSIG',
  SET_INFOS_CARTAO_BENEFICIO = '@@simulacao/SET_INFOS_CARTAO_BENEFICIO',
  SET_INFOS_SAQUE_COMPLEMENTAR_CONSIGNADO = '@@simulacao/SET_INFOS_SAQUE_COMPLEMENTAR_CONSIGNADO',
  SET_INFOS_SAQUE_COMPLEMENTAR_BENEFICIO = '@@simulacao/SET_INFOS_SAQUE_COMPLEMENTAR_BENEFICIO',
  SET_INVALID_CONTRATACAO = '@@simulacao/SET_INVALID_CONTRATACAO',
  SELECT_PRODUTO = '@@simulacao/SELECT_PRODUTO',
  SET_PRODUTOS_DISPONIVEIS = '@@simulacao/SET_PRODUTOS_DISPONIVEIS',
  SET_PRODUTOS_SELECIONADOS = '@@simulacao/SET_PRODUTOS_SELECIONADOS',
  SET_SOMATORIO_TOTAL_PRODUTOS = '@@simulacao/SET_SOMATORIO_TOTAL_PRODUTOS',
  SET_SOMATORIO_RESUMO = '@@simulacao/SET_SOMATORIO_RESUMO',
  SET_INFOS_MODAL_INDICACAO = '@@simulacao/SET_INFOS_MODAL_INDICACAO',
  CLEAN_INFOS_MODAL_INDICACAO = '@@simulacao/CLEAN_INFOS_MODAL_INDICACAO',
  SET_OPTIONS_SELECT = '@@simulacao/SET_OPTIONS_SELECT',
  SET_LOADING_SELECTS = '@@simulacao/SET_LOADING_SELECTS',
  SET_VALIDACAO_FIELDS = '@@simulacao/SET_VALIDACAO_FIELDS',
  CLEAN_VALIDACAO_FIELDS = '@@simulacao/CLEAN_VALIDACAO_FIELDS',
  SET_VALIDACAO_FIELDS_INVALIDS = '@@simulacao/SET_VALIDACAO_FIELDS_INVALIDS',
  CLEAN_VALIDACAO_FIELDS_INVALIDS = '@@simulacao/CLEAN_VALIDACAO_FIELDS_INVALIDS',
  TOGGLE_MODAL_IN100 = '@@simulacao/TOGGLE_MODAL_IN100',
  SET_MATRICULAS_VALIDACAO = '@@simulacao/SET_MATRICULAS_VALIDACAO',
  SET_JA_SIMULADO = '@@simulacao/SET_JA_SIMULADO',

  GET_CLIENTE_MATRICULA_CPF_SAGA = '@@simulacao/GET_CLIENTE_MATRICULA_CPF_SAGA',
  VALIDA_STATUS_IN100_SAGA = '@@simulacao/VALIDA_STATUS_IN100_SAGA',
  GET_PROPOSTAS_SAGA = '@@simulacao/GET_PROPOSTAS_SAGA',
  SOLICITAR_SIMULACAO_FGTS_SAGA = '@@simulacao/SOLICITAR_SIMULACAO_FGTS_SAGA',
  GET_PARCELAS_FGTS_SAGA = '@@simulacao/GET_PARCELAS_FGTS_SAGA',
  RECALCULO_PROPOSTA_SAGA = '@@simulacao/RECALCULO_PROPOSTA_SAGA',
  RECALCULO_PROPOSTA_FGTS_SAGA = '@@simulacao/RECALCULO_PROPOSTA_FGTS_SAGA',
  VALIDACAO_CONTRATACAO_SAGA = '@@simulacao/VALIDACAO_CONTRATACAO_SAGA',
  GET_RESUMO_CONTRATACAO_SAGA = '@@simulacao/GET_RESUMO_CONTRATACAO_SAGA',
  FINALIZACAO_CONTRATACAO_SAGA = '@@simulacao/FINALIZACAO_CONTRATACAO_SAGA',
  GET_PERFIS_RESUMO_SAGA = '@@simulacao/GET_PERFIS_RESUMO_SAGA',
  GET_TIPOS_CONTA_RESUMO_SAGA = '@@simulacao/GET_TIPOS_CONTA_RESUMO_SAGA',
  GET_BANCOS_RESUMO_SAGA = '@@simulacao/GET_BANCOS_RESUMO_SAGA',
  GET_CONVENIOS_RESUMO_SAGA = '@@simulacao/GET_CONVENIOS_RESUMO_SAGA',
  INSERIR_SOLICITACOES_IN100_SAGA = '@@simulacao/INSERIR_SOLICITACOES_IN100_SAGA',
  VALIDAR_TOKEN_IN100_SAGA = '@@simulacao/VALIDAR_TOKEN_IN100_SAGA'
}

export interface SIMULACAO {
  nomeCompleto: string
  cpf: string
  beneficios: Beneficio[]
  somatorioTotalProdutos: number
  somatorioResumo: number
  produtosDisponiveis: ProdutosSimulacao[]
  produtosSelecionados: ProdutosSimulacao[]
  detalhamentoIsOpen: boolean
  produtoSelected: {
    servico: number
    matricula: string
  }
  infosClienteResumo: InfosClienteResumo
  modalResumoContratacao: boolean
  loadingSimulacao: boolean
  invalidContratacao: boolean
  optionsSelect: OptionsSelect
  loadingSelect: LoadingSelect
  perfis: Perfil[]
  modalIndicacaoFgts: ModalIndicacaoFgts
  validacaoFields: ValidacaoFields
  validacaoFieldsInvalids: ValidacaoFieldsInvalids
  modalIN100: boolean
  matriculasValidacao: string[]
  jaSimulado: boolean
}

export interface Beneficio {
  numero: string
  dataAtualizacaoToken: string
  dataConsentimento: string
  clientePerfil: ClientePerfil
}

interface ClientePerfil {
  id: string
  descricao: string
  tipoPerfil: number
}

export interface InfosClienteResumo {
  nome: string
  cpf: string
  dataNascimento: string
  numeroIdentidade: string
  emissorIdentidade: string
  ufIdentidade: string
  dataEmissao: string
  nomePai: string
  nomeMae: string
  cep: string
  logradouro: string
  numeroEndereco: string
  bairro: string
  cidade: string
  uf: string
  complemento: string
  numero: string
  ddd: string
  sexo: string
  estadoCivil: string
  nomeConjuge: string
  cidadeNascimento: string
  ufNascimento: string
  nacionalidade: string
  grauInstrucao: string
  matriculas: MatriculaResumo[]
}

export interface MatriculaResumo {
  id: string
  numeroMatricula: string
  tipoPerfil: number
  descricaoPerfil: string
  tipoPerfilId: string
  convenioId?: string
  margemDisponivel: number
  margemCartaoDisponivel: number
  margemCartaoBeneficio: number
  descricaoBanco: string
  contaCorrenteId: string
  tipoContaId: string
  bancoDadosBancariosId: string
  numeroAgencia: string
  digitoVerificadorAgencia: string | null
  numeroConta: string
  digitoVerificadorConta: string
}

interface OptionsSelect {
  perfis: Perfil[]
  tiposConta: TipoConta[]
  bancos: Banco[]
  convenios: Convenio[]
}

type LoadingSelect = {
  [k in keyof OptionsSelect]: boolean
}

export interface ModalIndicacaoFgts {
  isOpen: boolean
  indicacao: number | null
  metodoFgts: MetodosFgts | null
}

export enum MetodosFgts {
  SolicitarSimulacao = 1,
  SolicitarParcelas = 2,
  Recalculo = 3
}

interface ValidacaoFields {
  celular: string
  codigo: string
  matricula: string
}

type ValidacaoFieldsInvalids = {
  [k in keyof ValidacaoFields]: Invalid
}

import { Reducer } from 'redux'
import { CALENDARIO, TYPES } from './types'

const INITIAL_STATE: CALENDARIO = {
  defaultValue: ''
}

const reducer: Reducer<CALENDARIO> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.ACTION_DEFAULT:
      return { ...state, defaultValue: action.value }
    default:
      return state
  }
}

export default reducer

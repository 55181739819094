import { DadosComplementaresDadosBancariosReq } from 'models/cadastros/dadosComplementaresDadosBancarios/types'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)
export const schema: yup.SchemaOf<DadosComplementaresDadosBancariosReq> = yup
  .object()
  .shape({
    dadosBancarios: yup.array().of(
      yup.object().shape({
        id: yup.string().nullable().label('Id'),
        numeroMatricula: yup
          .string()
          .max(20)
          .when('tipoPerfil', {
            is: (tipoPerfil: number) =>
              tipoPerfil !== 2 && tipoPerfil !== 0 && tipoPerfil !== 6,
            then: yup
              .string()
              .transform((_, val) => (val ? val : ''))
              .required()
          })
          .nullable()
          .label('Matrícula'),
        tipoPerfil: yup
          .number()
          .typeError('${label} deve ser um número válido')
          .nullable()
          .label('Tipo perfil'),
        descricaoPerfil: yup.string().nullable().label('Descrição perfil'),
        tipoPerfilId: yup.string().required().ensure().label('Tipo perfil'),
        convenioId: yup
          .string()
          .when('tipoPerfil', {
            is: (tipoPerfil: number) => {
              return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
            },
            then: yup
              .string()
              .required()
              .transform((_, val) => (val ? val : ''))
          })
          .nullable()
          .label('Convênio'),
        margemDisponivel: yup
          .number()
          .typeError('${label} deve ser um número válido')
          .when('tipoPerfil', {
            is: (tipoPerfil: number) => {
              return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
            },
            then: yup
              .number()
              .typeError('${label} deve ser um número válido')
              .lessThan(1000000000.0)
          })
          .nullable()
          .transform((_, val) => (val === Number(val) ? val : null))
          .label('Margem consignável de empréstimo'),
        margemCartaoDisponivel: yup
          .number()
          .typeError('${label} deve ser um número válido')
          .when('tipoPerfil', {
            is: (tipoPerfil: number) => {
              return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
            },
            then: yup
              .number()
              .typeError('${label} deve ser um número válido')
              .lessThan(1000000000.0)
          })
          .nullable()
          .transform((_, val) => (val === Number(val) ? val : null))
          .label('Margem consignável de cartão'),
        margemCartaoBeneficio: yup
          .number()
          .typeError('${label} deve ser um número válido')
          .when('tipoPerfil', {
            is: (tipoPerfil: number) => {
              return tipoPerfil === 3 || tipoPerfil === 4 || tipoPerfil === 5
            },
            then: yup
              .number()
              .typeError('${label} deve ser um número válido')
              .lessThan(1000000000.0)
          })
          .nullable()
          .transform((_, val) => (val === Number(val) ? val : null))
          .label('Margem cartão benefício'),
        valorRendaIndividual: yup
          .number()
          .typeError('${label} deve ser um número válido')
          .required()
          .moreThan(0)
          .lessThan(1000000000.0)
          .transform((_, val) => (val === Number(val) ? val : null))
          .label('Salário'),
        contaCorrenteId: yup.string().nullable().label('Conta corrente'),
        tipoContaId: yup
          .string()
          .required()
          .transform((_, val) => (val ? val : ''))
          .ensure()
          .label('Tipo conta'),
        bancoDadosBancariosId: yup
          .string()
          .required()
          .transform((_, val) => (val ? val : ''))
          .ensure()
          .label('Banco'),
        agencia: yup.string().required().min(1).max(11).label('Agência'),
        digitoAgencia: yup
          .string()
          .nullable()
          .max(1)
          .transform((_, val) => (val === Number(val) ? val : null))
          .label('Dígito agência'),
        numeroConta: yup
          .string()
          .required()
          .min(1)
          .max(11)
          .label('Número conta'),
        digitoConta: yup.string().required().length(1).label('Dígito conta')
      })
    )
  })

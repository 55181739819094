import {
  RequestCadastroAposentadoDto,
  RequestCadastroCltDto,
  RequestCadastroServidorPublicoDto
} from 'models/cadastros/profissional/types'
import { ServidoresPublicoEnum } from 'models/types'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)
export const schemaCadastroAposentado: yup.SchemaOf<RequestCadastroAposentadoDto> =
  yup.object().shape({
    matricula: yup.string().required().label('Matricula'),
    salario: yup
      .number()
      .required()
      .transform((_, val) => (!isNaN(val) ? val : null))
      .moreThan(0)
      .lessThan(1000000000.0)
      .label('Salário'),
    siglaEstado: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Estado'),
    cidadeId: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Cidade')
  })

const servidores: ServidoresPublicoEnum[] = [3, 4, 5]

export const schemaCadastroServidor: yup.SchemaOf<RequestCadastroServidorPublicoDto> =
  yup.object().shape({
    matricula: yup.string().required().label('Matricula'),
    convenioId: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Convênio'),
    salario: yup
      .number()
      .required()
      .transform((_, val) => (!isNaN(val) ? val : null))
      .moreThan(0)
      .lessThan(1000000000.0)
      .label('Salário'),
    siglaEstado: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Estado'),
    cidadeId: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Cidade'),
    margemEmprestimoConsignado: yup
      .number()
      .required()
      .lessThan(1000000000.0)
      .label('Margem consignável de empréstimo'),
    margemCartaoConsignado: yup
      .number()
      .required()
      .lessThan(1000000000.0)
      .label('Margem consignável de cartão'),
    margemCartaoBeneficio: yup
      .number()
      .required()
      .lessThan(1000000000.0)
      .label('Margem cartão benefício'),
    servidoresPublicoEnum: yup
      .mixed<ServidoresPublicoEnum>()
      .oneOf(Object.values(servidores), 'Valor inválido')
      .required()
      .label('Tipo perfil')
  })

export const schemaCadastroClt: yup.SchemaOf<RequestCadastroCltDto> = yup
  .object()
  .shape({
    salario: yup
      .number()
      .required()
      .transform((_, val) => (!isNaN(val) ? val : null))
      .moreThan(0)
      .lessThan(1000000000.0)
      .label('Salário'),
    siglaEstado: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Estado'),
    cidadeId: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .required()
      .label('Cidade')
  })

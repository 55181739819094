import { Reducer } from 'redux'
import { DADOS_COMPLEMENTARES_DADOS_BANCARIOS, TYPES } from './types'

const INITIAL_STATE: DADOS_COMPLEMENTARES_DADOS_BANCARIOS = {
  cpf: '',
  formulario: {
    dadosBancarios: []
  },
  invalidFormulario: {
    dadosBancarios: []
  },
  activeTabBeneficio: 0,
  optionsSelects: {
    bancos: [],
    convenios: [],
    perfis: [],
    tiposConta: []
  },
  loadingSelects: {
    bancos: false,
    convenios: false,
    perfis: false,
    tiposConta: false
  }
}

const reducer: Reducer<DADOS_COMPLEMENTARES_DADOS_BANCARIOS> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TYPES.SET_CPF:
      return {
        ...state,
        cpf: action.value
      }
    case TYPES.SET_FORMULARIO:
      return {
        ...state,
        formulario: action.value
      }
    case TYPES.SET_FORMULARIO_FIELD:
      return {
        ...state,
        formulario: {
          ...state.formulario,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_FORMULARIO:
      return {
        ...state,
        formulario: INITIAL_STATE.formulario
      }
    case TYPES.SET_INVALID_FORMULARIO:
      return {
        ...state,
        invalidFormulario: action.value
      }
    case TYPES.SET_INVALID_FORMULARIO_FIELD:
      return {
        ...state,
        invalidFormulario: {
          ...state.invalidFormulario,
          [action.field]: action.value
        }
      }
    case TYPES.SET_INVALID_FORMULARIO_FIELD_ARRAY: {
      const invalids = JSON.parse(
        JSON.stringify({ ...state.invalidFormulario })
      )
      invalids[action.field][action.index] = {
        ...invalids[action.field][action.index],
        [action.nestedNameArray]: action.value
      }
      return {
        ...state,
        invalidFormulario: invalids
      }
    }
    case TYPES.CLEAN_INVALIDS_FORMULARIO: {
      let invalids = JSON.parse(JSON.stringify({ ...state.invalidFormulario }))
      invalids = {
        dadosBancarios: invalids.dadosBancarios.map(() => {
          return {
            id: {
              invalid: false,
              message: ''
            },
            numeroMatricula: {
              invalid: false,
              message: ''
            },
            tipoPerfil: {
              invalid: false,
              message: ''
            },
            descricaoPerfil: {
              invalid: false,
              message: ''
            },
            tipoPerfilId: {
              invalid: false,
              message: ''
            },
            convenioId: {
              invalid: false,
              message: ''
            },
            margemDisponivel: {
              invalid: false,
              message: ''
            },
            margemCartaoDisponivel: {
              invalid: false,
              message: ''
            },
            margemCartaoBeneficio: {
              invalid: false,
              message: ''
            },
            contaCorrenteId: {
              invalid: false,
              message: ''
            },
            tipoContaId: {
              invalid: false,
              message: ''
            },
            bancoDadosBancariosId: {
              invalid: false,
              message: ''
            },
            agencia: {
              invalid: false,
              message: ''
            },
            digitoAgencia: {
              invalid: false,
              message: ''
            },
            numeroConta: {
              invalid: false,
              message: ''
            },
            digitoConta: {
              invalid: false,
              message: ''
            }
          }
        })
      }
      return {
        ...state,
        invalidFormulario: invalids
      }
    }
    case TYPES.SET_ACTIVE_TAB_BENEFICIO:
      return { ...state, activeTabBeneficio: action.value }
    case TYPES.ADICIONAR_PERFIL: {
      const dadosBancarios = [...state.formulario.dadosBancarios]
      const dadosBancariosInvalids = [...state.invalidFormulario.dadosBancarios]
      dadosBancarios.push({
        id: '',
        numeroMatricula: '',
        tipoPerfil: action.value.tipoPerfil,
        descricaoPerfil: action.value.descricao,
        tipoPerfilId: action.value.id,
        convenioId: '',
        margemDisponivel: '',
        margemCartaoDisponivel: '',
        margemCartaoBeneficio: '',
        contaCorrenteId: '',
        tipoContaId: '',
        bancoDadosBancariosId: '',
        agencia: null,
        digitoAgencia: null,
        numeroConta: null,
        digitoConta: null
      })
      dadosBancariosInvalids.push({
        id: {
          invalid: false,
          message: ''
        },
        numeroMatricula: {
          invalid: false,
          message: ''
        },
        tipoPerfil: {
          invalid: false,
          message: ''
        },
        descricaoPerfil: {
          invalid: false,
          message: ''
        },
        tipoPerfilId: {
          invalid: false,
          message: ''
        },
        convenioId: {
          invalid: false,
          message: ''
        },
        margemDisponivel: {
          invalid: false,
          message: ''
        },
        margemCartaoDisponivel: {
          invalid: false,
          message: ''
        },
        margemCartaoBeneficio: {
          invalid: false,
          message: ''
        },
        contaCorrenteId: {
          invalid: false,
          message: ''
        },
        tipoContaId: {
          invalid: false,
          message: ''
        },
        bancoDadosBancariosId: {
          invalid: false,
          message: ''
        },
        agencia: {
          invalid: false,
          message: ''
        },
        digitoAgencia: {
          invalid: false,
          message: ''
        },
        numeroConta: {
          invalid: false,
          message: ''
        },
        digitoConta: {
          invalid: false,
          message: ''
        }
      })
      return {
        ...state,
        formulario: {
          ...state.formulario,
          dadosBancarios: dadosBancarios
        },
        invalidFormulario: {
          ...state.invalidFormulario,
          dadosBancarios: dadosBancariosInvalids
        }
      }
    }
    case TYPES.REMOVER_PERFIL: {
      const perfisCliente = [...(state.formulario.dadosBancarios || [])]
      const invalidsDadosBancarios = [
        ...(state.invalidFormulario.dadosBancarios || [])
      ]
      perfisCliente.splice(action.indexPerfil, 1)
      invalidsDadosBancarios.splice(action.indexPerfil, 1)
      return {
        ...state,
        formulario: {
          ...state.formulario,
          dadosBancarios: perfisCliente
        },
        invalidFormulario: {
          ...state.invalidFormulario,
          dadosBancarios: invalidsDadosBancarios
        }
      }
    }
    case TYPES.SET_OPTIONS_SELECT:
      return {
        ...state,
        optionsSelects: {
          ...state.optionsSelects,
          [action.field]: action.value
        }
      }
    case TYPES.SET_LOADING_SELECTS:
      return {
        ...state,
        loadingSelects: {
          ...state.loadingSelects,
          [action.field]: action.value
        }
      }
    default:
      return state
  }
}

export default reducer

import React from 'react'
import { LayoutCadastro } from './style'
import JustNavbar from 'layouts/justNavbar'

interface SimpleCadastroProps {
  banner: string
  children?: React.ReactNode
  title?: string
  subTitle?: string
}

const SimpleCadastro = ({
  banner,
  children,
  title,
  subTitle
}: SimpleCadastroProps) => {
  return (
    <JustNavbar>
      <LayoutCadastro banner={banner}>
        <aside className="banner">
          <h1>{title}</h1>
          <h2>{subTitle}</h2>
        </aside>
        <div className="main">
          <div className="titles-main">
            <h1>{title}</h1>
            <h2>{subTitle}</h2>
          </div>
          {children}
        </div>
      </LayoutCadastro>
    </JustNavbar>
  )
}

export default SimpleCadastro

import React from 'react'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { CustomButton, SimpleTitleCard } from 'styles/global'

interface ModalExclusaoProps {
  isOpen: boolean
  toggle: (e: boolean) => void
  onRemove: () => void
  message?: string
  labelBtnLeft?: string
  labelBtnRight?: string
}

const ModalExclusao = ({
  isOpen,
  toggle,
  onRemove,
  message,
  labelBtnLeft,
  labelBtnRight
}: ModalExclusaoProps) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={() => toggle(!isOpen)}>
        <SimpleTitleCard colorIcon="var(--amarelo-riber)">
          <AiOutlineExclamationCircle className="icon" />
          Atenção!
        </SimpleTitleCard>
      </ModalHeader>
      <ModalBody>
        <p>{message ? message : 'Deseja realmente excluir este registro?'}</p>
      </ModalBody>
      <ModalFooter>
        <Row className="d-flex column-gap-20">
          <CustomButton
            onClick={() => toggle(!isOpen)}
            background="var(--roxo-riber)"
          >
            {labelBtnLeft ? labelBtnLeft : 'Cancelar'}
          </CustomButton>
          <CustomButton
            onClick={() => {
              onRemove()
              toggle(!isOpen)
            }}
          >
            {labelBtnRight ? labelBtnRight : 'Excluir'}
          </CustomButton>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

export default ModalExclusao

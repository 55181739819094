import React from 'react'
import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled
} from '@mui/material'

interface StepsProps {
  steps: {
    descricao: string
    index: number
  }[]
  currentStep: number
}

const Steps = ({ steps, currentStep }: StepsProps) => {
  const ColorlibConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: 'var(--verde-riber)'
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: 'var(--verde-riber)'
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: '#ccc',
      borderRadius: 1
    }
  }))

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean }
  }>(({ ownerState }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: 'var(--branco)',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      background: 'var(--violetaGradiente-riber)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    }),
    ...(ownerState.completed && {
      background: 'var(--verde-riber)'
    })
  }))

  function ColorlibStepIcon(props: StepIconProps, step: number) {
    const { active, completed, className } = props

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {step}
      </ColorlibStepIconRoot>
    )
  }
  return (
    <Stepper
      alternativeLabel
      activeStep={currentStep}
      connector={<ColorlibConnector />}
    >
      {steps.map((step, index) => (
        <Step
          sx={{
            '& .MuiStepIcon-root': {
              color: 'var(--roxo-riber)',
              borderRadius: 2,
              background: 'var(--roxo-riber)'
            },
            '& .MuiStepIcon-root .Mui-completed': {
              color: 'var(--branco)',
              borderRadius: 2,
              background: 'var(--roxo-riber)'
            },
            '& .Mui-disabled .MuiStepIcon-root': {
              color: 'var(--roxo-riber)',
              borderRadius: 2,
              background: 'var(--roxo-riber)'
            }
          }}
          key={index}
          completed={currentStep > step.index || false}
          active={currentStep === step.index}
        >
          <StepLabel StepIconComponent={(e) => ColorlibStepIcon(e, step.index)}>
            {step.descricao}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default Steps

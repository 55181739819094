import styled from 'styled-components'

export const SeletorPerfilWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  input[type='radio'] {
    display: none;
  }

  input[type='radio'] + label {
    border: 1px solid var(--roxo-riber);
    border-radius: 18px;
    color: var(--roxo-riber);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 700;
    overflow-wrap: break-word;
    padding: 16px;
    row-gap: 9px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    @media (min-width: 992px) and (max-width: 1350px) {
      font-size: 0.5rem;
    }
  }

  input[type='radio']:checked + label {
    background: var(--roxo-riber);
    color: var(--branco);
  }

  label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
`

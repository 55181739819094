import React, { useState } from 'react'
import {
  Alert,
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { APICore } from 'helpers/api/apiCore'
import { toast } from 'react-toastify'

const PasswordInput = ({
  value,
  onChange
}: {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <FormGroup style={{ position: 'relative' }}>
      <Input
        type={showPassword ? 'text' : 'password'}
        placeholder="Digite aqui ..."
        value={value}
        onChange={onChange}
        className="form-control"
        style={{
          paddingRight: '0.5em',
          borderRadius: '0.25rem',
          color: '#D0D5DD'
        }}
      />
      <div
        className="password-toggle"
        onClick={() => setShowPassword(!showPassword)}
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: '1'
        }}
      >
        {showPassword ? (
          <FaEyeSlash style={{ color: '#D0D5DD' }} />
        ) : (
          <FaEye style={{ color: '#D0D5DD' }} />
        )}
      </div>
    </FormGroup>
  )
}

const ModalTrocarSenha = ({
  isOpen,
  toggle
}: {
  isOpen: boolean
  toggle: () => void
}) => {
  const [senhaAtual, setSenhaAtual] = useState('')
  const [senhaNova, setNovaSenha] = useState('')
  const [confirmacaoSenha, setConfirmacaoSenha] = useState('')
  const [error, setError] = useState('')

  const api = new APICore()

  const trocarSenha = () => {
    if (!senhaNova || senhaNova.trim() === '') {
      setError('Por favor, insira uma nova senha válida')
      return
    }

    if (senhaNova !== confirmacaoSenha) {
      setError('As senhas não conferem')
      return
    }

    console.log('Chamando API para trocar senha:', {
      senhaAtual,
      senhaNova
    })

    api
      .update('Autenticacao/alterar-senha', {
        senhaAtual,
        senhaNova
      })
      .then((response) => {
        if (response && response.data && response.data.success) {
          toast.success('Senha alterada com sucesso.')
          toggle()
        } else {
          setError(response.data.message || 'Erro ao trocar a senha.')
          toast.error(response.data.message || 'Erro ao trocar a senha.')
        }
      })
      .catch((error) => {
        setError(error.response?.data?.message || 'Erro ao trocar a senha.')
        toast.error(error.response?.data?.message || 'Erro ao trocar a senha.')
      })
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      style={{ height: '432px', width: '380px' }}
    >
      <ModalHeader
        style={{
          background: '#00AB4F',
          color: '#fff',
          textAlign: 'center',
          borderRadius: '0.3rem 0.3rem 0 0',
          fontWeight: '900',
          fontSize: '1.25rem',
          border: 'none',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        Alterar senha
      </ModalHeader>
      <ModalBody style={{ border: 'none', padding: '1rem 1.8rem' }}>
        <FormGroup>
          <Label style={{ fontWeight: '600', fontSize: '14px' }}>
            Senha atual
          </Label>
          <PasswordInput
            value={senhaAtual}
            onChange={(e) => setSenhaAtual(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label style={{ fontWeight: '600', fontSize: '14px' }}>
            Nova senha
          </Label>
          <PasswordInput
            value={senhaNova}
            onChange={(e) => setNovaSenha(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label style={{ fontWeight: '600', fontSize: '14px' }}>
            Confirmar nova senha
          </Label>
          <PasswordInput
            value={confirmacaoSenha}
            onChange={(e) => setConfirmacaoSenha(e.target.value)}
          />
        </FormGroup>
        {error && <Alert color="danger">{error}</Alert>}
      </ModalBody>
      <ModalFooter
        style={{
          justifyContent: 'center',
          border: 'none',
          marginBottom: '20px'
        }}
      >
        <Button
          style={{
            background: '#FEE4E2',
            color: '#B42318',
            border: 'none',
            padding: '0.5rem 1rem',
            marginRight: '20px'
          }}
          onClick={toggle}
        >
          Cancelar
        </Button>
        <Button
          style={{
            background: '#662D91',
            color: '#fff',
            border: 'none',
            padding: '0.5rem 1.8rem'
          }}
          onClick={trocarSenha}
        >
          Salvar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalTrocarSenha

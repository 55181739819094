import React from 'react'
import SimpleModal from 'components/SimpleModal'
import { MdOutlinePolicy } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { CADASTRO_INICIAL } from 'store/modules/cadastros/cadastroInicial/types'
import { SimpleTitleCard } from 'styles/global'
import * as cadastroInicialActions from 'store/modules/cadastros/cadastroInicial/actions'
import useRedux from 'hooks/useRedux'
import { IframeAvisoPrivacidade } from '../style'

const ModalAvisoPrivacidade = () => {
  const { avisoPrivacidadeBase64, modalAvisoPrivacidade } = useSelector<
    ApplicationState,
    CADASTRO_INICIAL
  >((state) => state.cadastroInicial)

  const { dispatch } = useRedux()

  return (
    <SimpleModal
      isOpen={modalAvisoPrivacidade}
      toggle={() => {
        dispatch(cadastroInicialActions.setShowModalAvisoPrivacidade(false))
      }}
      dataBackdrop="static"
      size="xl"
      header={
        <SimpleTitleCard>
          <MdOutlinePolicy className="icon" />
          Politicas de compartilhamento
        </SimpleTitleCard>
      }
    >
      <IframeAvisoPrivacidade
        src={`data:application/pdf;base64,${avisoPrivacidadeBase64}`}
      />
    </SimpleModal>
  )
}

export default ModalAvisoPrivacidade

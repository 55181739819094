import { DadosComplementaresInfoPessoalReq } from 'models/cadastros/dadosComplementaresInfoPessoal/types'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)
export const schema: yup.SchemaOf<DadosComplementaresInfoPessoalReq> = yup
  .object()
  .shape({
    sexo: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Gênero'),
    estadoCivil: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Estado civil'),
    nomeConjuge: yup
      .string()
      .when('estadoCivil', {
        is: (estadoCivil: string) =>
          estadoCivil === 'CASADO' || estadoCivil === 'MARITAL',
        then: yup
          .string()
          .transform((_, val) => (val ? val : ''))
          .min(3)
          .max(100)
          .required()
      })
      .nullable()
      .label('Nome do Cônjuge'),
    nacionalidade: yup.string().required().label('Nacionalidade'),
    profissaoId: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Profissão'),
    escolaridadeId: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Escolaridade'),
    ufNascimento: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('UF nascimento'),
    cidadeNascimento: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Cidade de nascimento')
  })

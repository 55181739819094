import { APICore } from 'helpers/api/apiCore'
import { SejaParceiroReq } from 'models/landingPage/types'
import { removeMaskCPF } from 'util/masks'

const api = new APICore()

export function validaCpf(cpf: string) {
  const cpfFormatted = removeMaskCPF(cpf)
  return api.get(`LandingPage/valida-cpf`, {
    cpf: cpfFormatted
  })
}

export function sejaParceiro(params: SejaParceiroReq) {
  return api.create(`LandingPage/parceiro`, params)
}

export function getEstados() {
  return api.get(`Generic/estados-lojas`, null)
}

export function getCidades(siglaEstado: string) {
  return api.get(`Generic/cidades-lojas`, {
    siglaEstado
  })
}

export function getRiberMaisPerto(siglaEstado: string, cidade: string) {
  return api.get(`LandingPage/lojas`, {
    siglaEstado,
    cidade
  })
}

export function getAvisoPrivacidade() {
  return api.get(`pdf/aviso-privacidade`)
}

export function getPoliticaPrivacidade() {
  return api.get(`pdf/politica-privacidade`)
}

export function getTermoPolitica() {
  return api.get(`pdf/termo-uso`)
}

import { Invalid } from 'models/types'

export enum TYPES {
  SET_VALIDACAO_FIELDS = '@@validacao/SET_VALIDACAO_FIELDS',
  CLEAN_VALIDACAO_FIELDS = '@@validacao/CLEAN_VALIDACAO_FIELDS',
  SET_VALIDACAO_FIELDS_INVALIDS = '@@validacao/SET_VALIDACAO_FIELDS_INVALIDS',
  CLEAN_VALIDACAO_FIELDS_INVALIDS = '@@validacao/CLEAN_VALIDACAO_FIELDS_INVALIDS',

  INSERIR_SOLICITACOES_IN100_SAGA = '@@validacao/INSERIR_SOLICITACOES_IN100_SAGA',
  PESQUISAR_SOLICITACAO_IN100_SAGA = '@@validacao/PESQUISAR_SOLICITACAO_IN100_SAGA',
  VALIDAR_TOKEN_IN100_SAGA = '@@validacao/VALIDAR_TOKEN_IN100_SAGA',
  GET_TELEFONE_MATRICULA_INSERE_SOLICITACAO_SAGA = '@@validacao/GET_TELEFONE_MATRICULA_INSERE_SOLICITACAO_SAGA'
}

export interface VALIDACAO {
  validacaoFields: ValidacaoFields
  validacaoFieldsInvalids: ValidacaoFieldsInvalids
}

interface ValidacaoFields {
  celular: string
  codigo: string
  matricula: string
}

type ValidacaoFieldsInvalids = {
  [k in keyof ValidacaoFields]: Invalid
}

import { Invalid } from 'models/types'

export enum TYPES {
  SET_FORMULARIO = '@@cadastroInicial/SET_FORMULARIO',
  CLEAN_FORMULARIO = '@@cadastroInicial/CLEAN_FORMULARIO',
  SET_INVALID_FORMULARIO = '@@cadastroInicial/SET_INVALID_FORMULARIO',
  CLEAN_INVALIDS_FORMULARIO = '@@cadastroInicial/CLEAN_INVALIDS_FORMULARIO',
  SET_MODAL_SENHA_FIELD = '@@cadastroInicial/SET_MODAL_SENHA_FIELD',
  CLEAN_MODAL_SENHA = '@@cadastroInicial/CLEAN_MODAL_SENHA',
  SET_INVALID_MODAL_SENHA_FIELD = '@@cadastroInicial/SET_INVALID_MODAL_SENHA_FIELD',
  CLEAN_INVALIDS_MODAL_SENHA = '@@cadastroInicial/CLEAN_INVALIDS_MODAL_SENHA',
  SET_SHOW_MODAL_SENHA = '@@cadastroInicial/SET_SHOW_MODAL_SENHA',
  SET_SHOW_MODAL_AVISO_PRIVACIDADE = '@@cadastroInicial/SET_SHOW_MODAL_AVISO_PRIVACIDADE',
  SET_AVISO_PRIVACIDADE = '@@cadastroInicial/SET_AVISO_PRIVACIDADE',
  SET_LOADING_AVISO_PRIVACIDADE = '@@cadastroInicial/SET_LOADING_AVISO_PRIVACIDADE',

  SALVAR_CADASTRO_INICIAL_SAGA = '@@cadastroInicial/SALVAR_CADASTRO_INICIAL_SAGA',
  GET_AVISO_PRIVACIDADE_SAGA = '@@cadastroInicial/GET_AVISO_PRIVACIDADE_SAGA'
}

export interface CADASTRO_INICIAL {
  formulario: FormCadastroInicial
  invalidFormulario: InvalidFormCadastroInicial
  showModalSenha: boolean
  modalSenha: ModalSenha
  invalidModalSenha: InvalidModalSenha
  avisoPrivacidadeBase64: string
  loadingAvisoPrivacidade: boolean
  modalAvisoPrivacidade: boolean
}

interface FormCadastroInicial {
  nome: string
  cpf: string
  dataNascimento: string
  celular: string
  email: string
  politicaCompartilhamento: boolean
}

type InvalidFormCadastroInicial = {
  [K in keyof FormCadastroInicial]: Invalid
}

interface ModalSenha {
  senha: string
  confirmacaoSenha: string
}

type InvalidModalSenha = {
  [K in keyof ModalSenha]: Invalid
}

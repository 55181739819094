import React, { useEffect } from 'react'
import Main from 'layouts/main'
import CardProposta from './components/CardProposta'
import { SimpleTitleCard } from 'styles/global'
import useRedux from 'hooks/useRedux'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import { PROPOSTAS } from 'store/modules/propostas/types'
import * as propostasActions from 'store/modules/propostas/actions'
import { NAVBAR } from 'store/modules/navbar/types'

const Propostas = () => {
  const { propostas } = useSelector<ApplicationState, PROPOSTAS>(
    (state) => state.propostas
  )

  const { nomeUsuario } = useSelector<ApplicationState, NAVBAR>(
    (state) => state.navbar
  )

  const { dispatch } = useRedux()

  useEffect(() => {
    dispatch(propostasActions.getPropostasJaContratadasSaga())
  }, [dispatch])

  return (
    <Main
      titleBreadcrumbs={`Bem vindo(a), ${nomeUsuario}!`}
      subtitleBreadcrumbs="Confira o andamento dos seus contratos."
    >
      <SimpleTitleCard>Propostas contratadas</SimpleTitleCard>
      {propostas.map((proposta, i) => {
        return <CardProposta key={i} proposta={proposta} />
      })}
    </Main>
  )
}

export default Propostas

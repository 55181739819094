import React from 'react'
import Main from 'layouts/main'
import { MenuItemWrapper } from './style'
import calculadora from 'assets/svg/calculadora.svg'
import calendario from 'assets/svg/calendario.svg'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { NAVBAR } from 'store/modules/navbar/types'

const Utilitarios = () => {
  const { nomeUsuario } = useSelector<ApplicationState, NAVBAR>(
    (state) => state.navbar
  )

  return (
    <Main
      titleBreadcrumbs={`Olá, ${nomeUsuario}!`}
      subtitleBreadcrumbs="Confira seus utilitários."
    >
      <div className="d-flex justify-content-center justify-content-sm-start flex-wrap gap-3">
        <MenuItemWrapper to="calculadora">
          <div>
            <img src={calculadora} />
            <span>CALCULADORA</span>
          </div>
        </MenuItemWrapper>
        <MenuItemWrapper to="calendario">
          <div>
            <img src={calendario} />
            <span>CALENDÁRIO</span>
          </div>
        </MenuItemWrapper>
      </div>
    </Main>
  )
}

export default Utilitarios

import {
  Cidade,
  Escolaridade,
  Estado,
  EstadoCivil,
  Genero,
  Invalid,
  Profissao
} from 'models/types'

export enum TYPES {
  SET_FORMULARIO = '@@dadosComplementaresInfoPessoal/SET_FORMULARIO',
  SET_FORMULARIO_FIELD = '@@dadosComplementaresInfoPessoal/SET_FORMULARIO_FIELD',
  CLEAN_FORMULARIO = '@@dadosComplementaresInfoPessoal/CLEAN_FORMULARIO',
  SET_INVALID_FORMULARIO = '@@dadosComplementaresInfoPessoal/SET_INVALID_FORMULARIO',
  CLEAN_INVALIDS_FORMULARIO = '@@dadosComplementaresInfoPessoal/CLEAN_INVALIDS_FORMULARIO',
  SET_OPTIONS_SELECT = '@@dadosComplementaresInfoPessoal/SET_OPTIONS_SELECT',
  SET_LOADING_SELECTS = '@@dadosComplementaresInfoPessoal/SET_LOADING_SELECTS',
  SET_CPF = '@@dadosComplementaresInfoPessoal/SET_CPF',

  GET_DADOS_COMPLEMENTARES_INFO_PESSOAL_SAGA = '@@dadosComplementaresInfoPessoal/GET_DADOS_COMPLEMENTARES_INFO_PESSOAL_SAGA',
  SALVAR_CADASTRO_SAGA = '@@dadosComplementaresInfoPessoal/SALVAR_CADASTRO_SAGA',
  GET_GENEROS_SAGA = '@@dadosComplementaresInfoPessoal/GET_GENEROS_SAGA',
  GET_ESTADOS_CIVIS_SAGA = '@@dadosComplementaresInfoPessoal/GET_ESTADOS_CIVIS_SAGA',
  GET_PROFISSOES_SAGA = '@@dadosComplementaresInfoPessoal/GET_PROFISSOES_SAGA',
  GET_ESCOLARIDADES_SAGA = '@@dadosComplementaresInfoPessoal/GET_ESCOLARIDADES_SAGA',
  GET_UFS_NASCIMENTO_SAGA = '@@dadosComplementaresInfoPessoal/GET_UFS_NASCIMENTO_SAGA',
  GET_CIDADES_NASCIMENTO_SAGA = '@@dadosComplementaresInfoPessoal/GET_CIDADES_NASCIMENTO_SAGA',
  GET_CPF_SAGA = '@@dadosComplementaresInfoPessoal/GET_CPF_SAGA'
}

export interface DADOS_COMPLEMENTARES_INFOS_PESSOAL {
  cpf: string
  formulario: FormCadastro
  invalidFormulario: InvalidFormCadastro
  optionsSelects: OptionsSelects
  loadingSelects: LoadingSelects
}

interface FormCadastro {
  sexo: string
  estadoCivil: string
  nomeConjuge: string
  profissaoId: string
  nacionalidade: string
  escolaridadeId: string
  ufNascimento: string
  cidadeNascimento: string
}

type InvalidFormCadastro = {
  [K in keyof FormCadastro]: Invalid
}

interface OptionsSelects {
  generos: Genero[]
  escolaridades: Escolaridade[]
  ufsNascimento: Estado[]
  cidadesNascimento: Cidade[]
  estadosCivis: EstadoCivil[]
  profissoes: Profissao[]
}

type LoadingSelects = {
  [k in keyof OptionsSelects]: boolean
}

import { hot } from 'react-hot-loader/root'
import { Provider } from 'react-redux'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import GlobalStyle from 'styles/global'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import store from 'store'
import { createTheme, ThemeProvider } from '@mui/material'
import { ptBR } from '@mui/material/locale'
import history from 'util/history'
import AppRoutes from 'components/AppRoutes'

const theme = createTheme(ptBR)

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <HistoryRouter history={history}>
          <AppRoutes />
        </HistoryRouter>
        <ToastContainer />
        <GlobalStyle />
      </ThemeProvider>
    </Provider>
  )
}

export default hot(App)

import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import React from 'react'
import { FiGlobe } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { MEUS_DADOS } from 'store/modules/meusDados/types'
import { SimpleTitleCard } from 'styles/global'
import { maskNumber } from 'util/masks'
import * as meusDadosActions from 'store/modules/meusDados/actions'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import useRedux from 'hooks/useRedux'

const InformacoesComplementares = () => {
  const { meusDados, invalidMeusDados, optionsSelects, loadingSelects } =
    useSelector<ApplicationState, MEUS_DADOS>((state) => state.meusDados)

  const { dispatch } = useRedux()

  type MeusDadosField = keyof MEUS_DADOS['meusDados']
  const onChange = <T extends MeusDadosField>(
    value: T extends keyof MEUS_DADOS['meusDados']
      ? MEUS_DADOS['meusDados'][T]
      : never,
    field: T
  ) => {
    switch (field) {
      case 'ufNascimento':
        dispatch(meusDadosActions.setMeusDadosField('', 'cidadeNascimento'))
        dispatch(meusDadosActions.setMeusDadosField(value, field))
        break
      default:
        dispatch(meusDadosActions.setMeusDadosField(value, field))
        break
    }
    dispatch(meusDadosActions.setMeusDadosField(value, field))
  }

  return (
    <>
      <SimpleTitleCard>
        <FiGlobe className="icon" /> Informações complementares
      </SimpleTitleCard>
      <div className="container-fluid mb-4">
        <Row>
          <Col md={4}>
            <label className="label-12">DDD*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskNumber(e.target.value), 'ddd')
              }}
              value={meusDados.ddd}
              invalid={invalidMeusDados.ddd.invalid}
              errorMessage={invalidMeusDados.ddd.message}
              maxLength={3}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Número*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskNumber(e.target.value), 'numero')
              }}
              value={meusDados.numero}
              invalid={invalidMeusDados.numero.invalid}
              errorMessage={invalidMeusDados.numero.message}
              maxLength={9}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Escolaridade*</label>
            <CustomSelect
              options={optionsSelects.escolaridades}
              isLoading={loadingSelects.escolaridades}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'escolaridadeId')
              }}
              value={meusDados.escolaridadeId}
              accessorLabel="descricao"
              accessorValue="id"
              invalid={invalidMeusDados.escolaridadeId.invalid}
              errorMessage={invalidMeusDados.escolaridadeId.message}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label className="label-12">UF nascimento*</label>
            <CustomSelect
              options={optionsSelects.ufsNascimento}
              isLoading={loadingSelects.ufsNascimento}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'ufNascimento')
              }}
              value={meusDados.ufNascimento}
              accessorLabel="nome"
              accessorValue="sigla"
              invalid={invalidMeusDados.ufNascimento.invalid}
              errorMessage={invalidMeusDados.ufNascimento.message}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Cidade de nascimento*</label>
            <CustomSelect
              options={optionsSelects.cidadesNascimento}
              isLoading={loadingSelects.cidadesNascimento}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'cidadeNascimento')
              }}
              value={meusDados.cidadeNascimento}
              accessorLabel="nome"
              accessorValue="nome"
              invalid={invalidMeusDados.cidadeNascimento.invalid}
              errorMessage={invalidMeusDados.cidadeNascimento.message}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Estado civil*</label>
            <CustomSelect
              options={optionsSelects.estadosCivis}
              isLoading={loadingSelects.estadosCivis}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'estadoCivil')
              }}
              value={meusDados.estadoCivil}
              accessorLabel="descricao"
              accessorValue="value"
              invalid={invalidMeusDados.estadoCivil.invalid}
              errorMessage={invalidMeusDados.estadoCivil.message}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label className="label-12">
              Nome do Cônjuge
              {(meusDados.estadoCivil === 'CASADO' ||
                meusDados.estadoCivil === 'MARITAL') &&
                '*'}
            </label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'nomeConjuge')
              }}
              value={meusDados.nomeConjuge || ''}
              invalid={invalidMeusDados.nomeConjuge?.invalid}
              errorMessage={invalidMeusDados.nomeConjuge?.message}
              maxLength={100}
              infoMessage="Obrigatório se o estado civil for casado ou marital"
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Nome da mãe*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'nomeMae')
              }}
              value={meusDados.nomeMae}
              invalid={invalidMeusDados.nomeMae.invalid}
              errorMessage={invalidMeusDados.nomeMae.message}
              maxLength={100}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Nome do pai</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'nomePai')
              }}
              value={meusDados.nomePai || ''}
              invalid={invalidMeusDados.nomePai?.invalid}
              errorMessage={invalidMeusDados.nomePai?.message}
              maxLength={100}
            />
          </Col>
        </Row>
      </div>
      <div className="linha-horizontal mb-2"></div>
    </>
  )
}

export default InformacoesComplementares

import React from 'react'
import SimpleModal from 'components/SimpleModal'
import { Col, Row } from 'reactstrap'
import { CustomButton } from 'styles/global'
import { ModalIndicacaoWrapper } from 'views/simulacao/style'
import { MetodosFgts } from 'store/modules/simulacao/types'
import useRedux from 'hooks/useRedux'
import * as simulacaoActions from 'store/modules/simulacao/actions'

interface Indicacao {
  isOpen: boolean
  toggle: () => void
  metodo: MetodosFgts | null
}

const Indicacao5 = ({ isOpen, toggle, metodo }: Indicacao) => {
  const { dispatch } = useRedux()
  function onToggle() {
    if (metodo === MetodosFgts.SolicitarSimulacao) {
      dispatch(simulacaoActions.getParcelasFgtsSaga())
    }
    toggle()
  }
  return (
    <SimpleModal toggle={onToggle} isOpen={isOpen} size="sm">
      <ModalIndicacaoWrapper>
        <div className="container-fluid d-flex flex-column row-gap-15 position-relative">
          {/* Botão de fechar estilizado */}
          <button
            onClick={onToggle}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'none',
              border: 'none',
              fontSize: '20px',
              cursor: 'pointer',
              color: 'gray'
            }}
            aria-label="Close"
          >
            &times;
          </button>

          <div className="d-flex justify-content-center mt-4"></div>
          <h3 className="main-title">Ops... Algo deu errado</h3>
          <p className="text-center common-text">
            Você deve tentar realizar esta operação em alguns instantes.
          </p>
          <Row>
            <Col className="d-flex align-items-center justify-content-center">
              <CustomButton
                background="var(--roxo-riber)"
                onClick={() => {
                  dispatch(simulacaoActions.getParcelasFgtsSaga())
                  dispatch(simulacaoActions.getPropostasSaga())
                  onToggle()
                }}
              >
                REALIZAR SIMULAÇÃO
              </CustomButton>
            </Col>
          </Row>
        </div>
      </ModalIndicacaoWrapper>
    </SimpleModal>
  )
}

export default Indicacao5

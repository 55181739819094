import { DadosComplementaresEnderecoReq } from 'models/cadastros/dadosComplementaresEndereco/types'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)
export const schema: yup.SchemaOf<DadosComplementaresEnderecoReq> = yup
  .object()
  .shape({
    cep: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .required()
      .min(9)
      .max(9)
      .label('CEP'),
    siglaEstado: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('UF'),
    cidade: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Cidade'),
    bairro: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .required()
      .min(3)
      .max(100)
      .label('Bairro'),
    logradouro: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .required()
      .min(3)
      .max(100)
      .label('Logradouro'),
    numero: yup
      .string()
      .required()
      .transform((_, val) => (!isNaN(val) ? val : ''))
      .max(6)
      .label('Número'),
    complemento: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .nullable()
      .label('Complemento')
  })

import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { CustomButton } from 'styles/global'
import {
  CustomLabel,
  CustomLink,
  CustomTitleCadastro
} from 'views/cadastros/style'
import SimpleCadastro from 'components/SimpleCadastro/SimpleCadastro'
import primeiroCadastro from 'assets/images/cadastros/primeiro-cadastro.png'
import InputCustom from 'components/Inputs'
import Steps from 'components/Steps'
import { STEPS_CADASTRO } from 'views/cadastros/constants/constants'
import { ApplicationState } from 'store'
import { CADASTRO_INICIAL } from 'store/modules/cadastros/cadastroInicial/types'
import { useSelector } from 'react-redux'
import useRedux from 'hooks/useRedux'
import * as cadastroInicialActions from 'store/modules/cadastros/cadastroInicial/actions'
import {
  maskCPF,
  maskCharactersOnly,
  maskPhoneWithDDD,
  removeMaskPhone
} from 'util/masks'
import ModalSenha from './components/ModalSenha'
import { schemaFormulario } from './constants/schema'
import * as yup from 'yup'
import { Tooltip } from '@mui/material'
import ModalAvisoPrivacidade from './components/ModalAvisoPrivacidade'

const CadastroInicial = () => {
  const { formulario, invalidFormulario, loadingAvisoPrivacidade } =
    useSelector<ApplicationState, CADASTRO_INICIAL>(
      (state) => state.cadastroInicial
    )

  const { dispatch } = useRedux()

  type FormularioField = keyof CADASTRO_INICIAL['formulario']
  const onChange = <T extends FormularioField>(
    value: T extends keyof CADASTRO_INICIAL['formulario']
      ? CADASTRO_INICIAL['formulario'][T]
      : never,
    field: T
  ) => {
    dispatch(cadastroInicialActions.setFormularioField(value, field))
  }

  const onSave = () => {
    dispatch(cadastroInicialActions.cleanInvalidsFormulario())
    schemaFormulario
      .validate(
        {
          nome: maskCharactersOnly(formulario.nome),
          cpf: formulario.cpf,
          dataNascimento: formulario.dataNascimento,
          celular: removeMaskPhone(formulario.celular),
          email: formulario.email,
          politicaCompartilhamento: formulario.politicaCompartilhamento
        },
        {
          abortEarly: false
        }
      )
      .then(() => {
        dispatch(cadastroInicialActions.setShowModalSenha(true))
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            cadastroInicialActions.setInvalidFormularioField(
              {
                invalid: true,
                message: e.message
              },
              e.path as keyof CADASTRO_INICIAL['invalidFormulario']
            )
          )
        })
      })
  }

  useEffect(() => {
    dispatch(cadastroInicialActions.getAvisoPrivacidadeBase64())
  }, [dispatch])

  return (
    <SimpleCadastro
      banner={primeiroCadastro}
      title="Simulação de crédito"
      subTitle="Preencha os campos ao lado para fazer sua
simulação de  crédito!"
    >
      <div className="container-fluid d-flex flex-column row-gap-20">
        <Steps steps={STEPS_CADASTRO} currentStep={1} />

        <Row className="justify-content-center">
          <Col md={6}>
            <CustomTitleCadastro>Campos para preenchimento</CustomTitleCadastro>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6}>
            <label className="label-12">CPF*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskCPF(e.target.value), 'cpf')
              }}
              value={formulario.cpf}
              invalid={invalidFormulario.cpf.invalid}
              errorMessage={invalidFormulario.cpf.message}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6}>
            <label className="label-12">Nome*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskCharactersOnly(e.target.value), 'nome')
              }}
              value={formulario.nome}
              invalid={invalidFormulario.nome.invalid}
              errorMessage={invalidFormulario.nome.message}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6}>
            <label className="label-12">Data de nascimento*</label>
            <InputCustom
              type="date"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'dataNascimento')
              }}
              value={formulario.dataNascimento}
              invalid={invalidFormulario.dataNascimento.invalid}
              errorMessage={invalidFormulario.dataNascimento.message}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6}>
            <label className="label-12">E-mail*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'email')
              }}
              value={formulario.email}
              invalid={invalidFormulario.email.invalid}
              errorMessage={invalidFormulario.email.message}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6}>
            <label className="label-12">Celular*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskPhoneWithDDD(e.target.value), 'celular')
              }}
              value={formulario.celular}
              invalid={invalidFormulario.celular.invalid}
              errorMessage={invalidFormulario.celular.message}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6} className="d-flex align-items-center gap-3">
            <InputCustom
              className="checkbox-roxo"
              type="checkbox"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.checked, 'politicaCompartilhamento')
              }}
              checked={formulario.politicaCompartilhamento}
              invalid={invalidFormulario.politicaCompartilhamento.invalid}
            />
            {loadingAvisoPrivacidade ? (
              <CustomLabel>
                Carregando as politicas de compartilhamento...
              </CustomLabel>
            ) : (
              <CustomLink
                onClick={() =>
                  dispatch(
                    cadastroInicialActions.setShowModalAvisoPrivacidade(true)
                  )
                }
              >
                Aceito os termos de Uso
              </CustomLink>
            )}
          </Col>
          <Tooltip
            title={invalidFormulario.politicaCompartilhamento.message}
            placement="bottom-start"
          >
            <span className="error-message">
              {invalidFormulario.politicaCompartilhamento.message}
            </span>
          </Tooltip>
        </Row>
        <Row className="justify-content-center">
          <Col md={6}>
            <CustomButton
              background="var(--roxo-riber)"
              width="100%"
              padding="20"
              height="50px"
              borderRadius={21}
              onClick={() => {
                onSave()
              }}
            >
              AVANÇAR
            </CustomButton>
          </Col>
        </Row>
      </div>
      <ModalSenha />
      <ModalAvisoPrivacidade />
    </SimpleCadastro>
  )
}

export default CadastroInicial

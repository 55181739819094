import { Celular, Token } from 'models/cadastros/validacao/types'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)
export const schemaToken: yup.SchemaOf<Token> = yup.object().shape({
  codigo: yup.string().required().label('Código')
})

export const schemaCelular: yup.SchemaOf<Celular> = yup.object().shape({
  celular: yup.string().length(11).required().label('Celular')
})

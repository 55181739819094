import { TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as loadingActions from 'store/modules/loading/actions'
import * as dadosComplementaresDadosBancariosController from 'controller/dadosComplementaresDadosBancariosController'
import * as dadosComplementaresDadosBancariosActions from 'store/modules/cadastros/dadosComplementaresDadosBancarios/actions'
import { DADOS_COMPLEMENTARES_DADOS_BANCARIOS } from './types'
import { ApplicationState } from 'store'
import { handlerError } from 'util/handlerError'
import { toast } from 'react-toastify'
import { maskMoneyReq, removeMaskCPF, removeMaskMoney } from 'util/masks'
import { MatriculaDadosComplementaresReq } from 'models/cadastros/dadosComplementaresDadosBancarios/types'
import { stepCadastroComplementarRedirect } from 'util/stepCadastroComplementarRedirect'
import * as simulacaoActions from 'store/modules/simulacao/actions'

function* getDadosComplementaresDadosBancarios(): SagaIterator {
  const { cpf }: DADOS_COMPLEMENTARES_DADOS_BANCARIOS = yield select(
    (state: ApplicationState) => state.dadosComplementaresDadosBancarios
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpf)
    const response = yield call(
      dadosComplementaresDadosBancariosController.getDadosComplementaresDadosBancarios,
      cpfFormatted
    )
    yield put(
      dadosComplementaresDadosBancariosActions.setFormulario({
        dadosBancarios: response?.data?.content.dadosBancarios?.map(
          (matricula: MatriculaDadosComplementaresReq) => {
            return {
              id: matricula.id,
              numeroMatricula: matricula.numeroMatricula,
              tipoPerfil: matricula.tipoPerfil,
              descricaoPerfil: matricula.descricaoPerfil,
              tipoPerfilId: matricula.tipoPerfilId,
              convenioId: matricula.convenioId,
              margemCartaoDisponivel: maskMoneyReq(
                matricula.margemCartaoDisponivel || 0
              ),
              margemDisponivel: maskMoneyReq(matricula.margemDisponivel || 0),
              margemCartaoBeneficio: maskMoneyReq(
                matricula.margemCartaoBeneficio || 0
              ),
              valorRendaIndividual: maskMoneyReq(
                matricula.valorRendaIndividual || 0
              ),
              contaCorrenteId: matricula.contaCorrenteId,
              tipoContaId: matricula.tipoContaId,
              bancoDadosBancariosId: matricula.bancoDadosBancariosId,
              agencia: matricula.agencia,
              digitoAgencia: matricula.digitoAgencia,
              numeroConta: matricula.numeroConta,
              digitoConta: matricula.digitoConta
            }
          }
        )
      })
    )
    yield put(
      dadosComplementaresDadosBancariosActions.setInvalidFormulario({
        dadosBancarios: response?.data?.content.dadosBancarios?.map(() => {
          return {
            id: {
              invalid: false,
              message: ''
            },
            numeroMatricula: {
              invalid: false,
              message: ''
            },
            tipoPerfil: {
              invalid: false,
              message: ''
            },
            descricaoPerfil: {
              invalid: false,
              message: ''
            },
            tipoPerfilId: {
              invalid: false,
              message: ''
            },
            convenioId: {
              invalid: false,
              message: ''
            },
            margemCartaoDisponivel: {
              invalid: false,
              message: ''
            },
            margemDisponivel: {
              invalid: false,
              message: ''
            },
            margemCartaoBeneficio: {
              invalid: false,
              message: ''
            },
            valorRendaIndividual: {
              invalid: false,
              message: ''
            },
            contaCorrenteId: {
              invalid: false,
              message: ''
            },
            tipoContaId: {
              invalid: false,
              message: ''
            },
            bancoDadosBancariosId: {
              invalid: false,
              message: ''
            },
            agencia: {
              invalid: false,
              message: ''
            },
            digitoAgencia: {
              invalid: false,
              message: ''
            },
            numeroConta: {
              invalid: false,
              message: ''
            },
            digitoConta: {
              invalid: false,
              message: ''
            }
          }
        })
      })
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* salvarCadastro(): SagaIterator {
  const { formulario, cpf }: DADOS_COMPLEMENTARES_DADOS_BANCARIOS =
    yield select(
      (state: ApplicationState) => state.dadosComplementaresDadosBancarios
    )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpf)
    const response = yield call(
      dadosComplementaresDadosBancariosController.salvarCadastro,
      {
        cpf: cpfFormatted,
        dadosBancarios:
          formulario.dadosBancarios?.map((matricula) => {
            return {
              id: matricula.id,
              numeroMatricula: matricula.numeroMatricula,
              tipoPerfil: matricula.tipoPerfil,
              descricaoPerfil: matricula.descricaoPerfil,
              tipoPerfilId: matricula.tipoPerfilId,
              convenioId: matricula.convenioId,
              margemDisponivel: Number(
                removeMaskMoney(matricula.margemDisponivel)
              ),
              margemCartaoDisponivel: Number(
                removeMaskMoney(matricula.margemCartaoDisponivel)
              ),
              margemCartaoBeneficio: Number(
                removeMaskMoney(matricula.margemCartaoBeneficio)
              ),
              valorRendaIndividual: Number(
                removeMaskMoney(matricula.valorRendaIndividual)
              ),
              contaCorrenteId: matricula.contaCorrenteId,
              tipoContaId: matricula.tipoContaId,
              bancoDadosBancariosId: matricula.bancoDadosBancariosId,
              agencia: matricula.agencia ?? '',
              digitoAgencia: matricula.digitoAgencia,
              numeroConta: matricula.numeroConta ?? '',
              digitoConta: matricula.digitoConta ?? ''
            }
          }) || []
      }
    )
    yield put(
      dadosComplementaresDadosBancariosActions.setInvalidFormulario({
        dadosBancarios: []
      })
    )
    yield put(dadosComplementaresDadosBancariosActions.cleanFormulario())
    toast.success('Informações salvas com sucesso!')
    yield put(simulacaoActions.validaStatusIN100Saga())
    stepCadastroComplementarRedirect(
      response?.data?.content.dadosComplementaresEnum
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getPerfis(): SagaIterator {
  try {
    yield put(
      dadosComplementaresDadosBancariosActions.setLoadingSelect(true, 'perfis')
    )
    const response = yield call(
      dadosComplementaresDadosBancariosController.getPerfis
    )
    yield put(
      dadosComplementaresDadosBancariosActions.setOptionsSelect(
        response?.data?.content,
        'perfis'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresDadosBancariosActions.setLoadingSelect(false, 'perfis')
    )
  }
}

function* getTiposConta(): SagaIterator {
  try {
    yield put(
      dadosComplementaresDadosBancariosActions.setLoadingSelect(
        true,
        'tiposConta'
      )
    )
    const response = yield call(
      dadosComplementaresDadosBancariosController.getTiposConta
    )
    yield put(
      dadosComplementaresDadosBancariosActions.setOptionsSelect(
        response?.data?.content,
        'tiposConta'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresDadosBancariosActions.setLoadingSelect(
        false,
        'tiposConta'
      )
    )
  }
}

function* getBancos(): SagaIterator {
  try {
    yield put(
      dadosComplementaresDadosBancariosActions.setLoadingSelect(true, 'bancos')
    )
    const response = yield call(
      dadosComplementaresDadosBancariosController.getBancos
    )
    yield put(
      dadosComplementaresDadosBancariosActions.setOptionsSelect(
        response?.data?.content,
        'bancos'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresDadosBancariosActions.setLoadingSelect(false, 'bancos')
    )
  }
}

function* getConvenios(): SagaIterator {
  try {
    yield put(
      dadosComplementaresDadosBancariosActions.setLoadingSelect(
        true,
        'convenios'
      )
    )
    const response = yield call(
      dadosComplementaresDadosBancariosController.getConvenios
    )
    yield put(
      dadosComplementaresDadosBancariosActions.setOptionsSelect(
        response?.data?.content,
        'convenios'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresDadosBancariosActions.setLoadingSelect(
        false,
        'convenios'
      )
    )
  }
}

function* getCpf(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(
      dadosComplementaresDadosBancariosController.getCpf
    )
    yield put(
      dadosComplementaresDadosBancariosActions.setCpf(
        response?.data?.content.cpf
      )
    )
    yield put(
      dadosComplementaresDadosBancariosActions.getDadosComplementaresDadosBancariosSaga()
    )
  } catch (error) {
    handlerError(
      error,
      'Não foi possível obter CPF. Tente novamente mais tarde'
    )
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetDadosComplementaresDadosBancarios() {
  yield takeEvery(
    TYPES.GET_DADOS_COMPLEMENTARES_DADOS_BANCARIOS_SAGA,
    getDadosComplementaresDadosBancarios
  )
}

export function* watchSalvarCadastro() {
  yield takeEvery(TYPES.SALVAR_CADASTRO_SAGA, salvarCadastro)
}

export function* watchGetPerfis() {
  yield takeEvery(TYPES.GET_PERFIS_SAGA, getPerfis)
}

export function* watchGetTiposConta() {
  yield takeEvery(TYPES.GET_TIPOS_CONTA_SAGA, getTiposConta)
}

export function* watchGetBancos() {
  yield takeEvery(TYPES.GET_BANCOS_SAGA, getBancos)
}

export function* watchGetConvenios() {
  yield takeEvery(TYPES.GET_CONVENIOS_SAGA, getConvenios)
}

export function* watchGetCpfSaga() {
  yield takeEvery(TYPES.GET_CPF_SAGA, getCpf)
}

function* dadosComplementaresDadosBancarios() {
  yield all([
    fork(watchGetDadosComplementaresDadosBancarios),
    fork(watchSalvarCadastro),
    fork(watchGetPerfis),
    fork(watchGetTiposConta),
    fork(watchGetBancos),
    fork(watchGetConvenios),
    fork(watchGetCpfSaga)
  ])
}

export default dadosComplementaresDadosBancarios

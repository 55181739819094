import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

interface Props {
  isOpen: boolean
  toggle: () => void
  onRedirectToLogin: () => void
}

const CPFJaCadastradoModal: React.FC<Props> = ({
  isOpen,
  toggle,
  onRedirectToLogin
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader
        style={{
          border: 'none',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '1.5rem',
          position: 'relative' // Para posicionamento absoluto do ícone de fechar
        }}
      >
        <p
          style={{
            fontSize: '1.25rem',
            fontWeight: 'bold',
            textAlign: 'center',
            margin: 0,
            flex: 1 // Para ocupar o espaço restante
          }}
        >
          CPF já cadastrado
        </p>
        <div
          style={{
            position: 'absolute',
            right: '1rem', // Posiciona à direita
            top: '1rem', // Posiciona no topo
            cursor: 'pointer',
            fontSize: '1.5rem'
          }}
          onClick={toggle}
        >
          &times;
        </div>
      </ModalHeader>
      <ModalBody
        style={{ textAlign: 'center', padding: '1.5rem', fontSize: '1rem' }}
      >
        <p>
          Identificamos que o CPF informado já está registrado em nosso sistema.
          Por favor, clique no botão abaixo para acessar sua conta através da
          tela de login.
        </p>
      </ModalBody>
      <ModalFooter
        style={{ borderTop: 'none', display: 'flex', justifyContent: 'center' }}
      >
        <Button
          color="primary"
          onClick={onRedirectToLogin}
          style={{
            backgroundColor: '#00AB4F',
            borderColor: '#00AB4F',
            padding: '0.75rem 2rem',
            width: '100%',
            fontSize: '1rem',
            fontWeight: 'bold',
            borderRadius: '25px'
          }}
        >
          Entendi
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CPFJaCadastradoModal

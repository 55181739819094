export const STEPS_CADASTRO = [
  {
    descricao: 'Cadastro',
    index: 1
  },
  {
    descricao: 'Eu sou ...',
    index: 2
  },
  {
    descricao: 'Informações',
    index: 3
  },
  {
    descricao: 'Validação',
    index: 4
  }
]

export const STEPS_CADASTRO_APOSENTADO = [
  {
    descricao: 'Cadastro',
    index: 1
  },
  {
    descricao: 'Eu sou ...',
    index: 2
  },
  {
    descricao: 'Informações',
    index: 3
  },
  {
    descricao: 'Validação',
    index: 4
  }
]

export const STEPS_CADASTRO_COMPLEMENTAR = [
  {
    descricao: 'Documento',
    index: 1
  },
  {
    descricao: 'Informações pessoais',
    index: 2
  },
  {
    descricao: 'Endereço',
    index: 3
  },
  {
    descricao: 'Dados bancários',
    index: 4
  }
]

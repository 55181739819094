import React from 'react'
import Simple from 'layouts/simple'
import { Col, Row } from 'reactstrap'
import Banner from './components/Banner'
import { ArticleWrapper } from './style'
import fgts1 from 'assets/images/apresentacao/fgts1.png'
import fgts2 from 'assets/images/apresentacao/fgts2.png'
import fgts3 from 'assets/images/apresentacao/fgts3.png'
import FaqFgts from './components/FaqFgts'

const EmprestimoGarantia = () => {
  return (
    <Simple>
      <div>
        <Banner
          title={'Antecipação Saque Aniversário FGTS'}
          subtitle={'Dinheiro liberado no mesmo dia'}
          image={fgts1}
        />
        <ArticleWrapper>
          <div className="container-fluid d-flex flex-column align-items-center">
            <Row className="grid">
              <Col md={6} className="align-items-center p-5 text-right">
                <h4 className="text-center text-md-end">
                  Por que antecipar seu FGTS?
                </h4>
                <p className="text-center text-md-end">
                  A antecipação do Saque-Aniversário FGTS é uma opção
                  inteligente. Com ela, você pode receber até 10 anos de saldo
                  de uma só vez, sem impactar seu planejamento financeiro. E as
                  vantagens não param por aí:
                </p>
                <p className="text-center text-md-end">
                  Dinheiro na conta via Pix: Receba seu dinheiro de forma rápida
                  e prática.
                </p>
                <p className="text-center text-md-end">
                  Não compromete sua renda mensal: Antecipe seu FGTS sem afetar
                  suas finanças mensais.
                </p>
                <p className="text-center text-md-end">
                  Juros mais baixos que outros empréstimos: Economize com taxas
                  competitivas.
                </p>
                <p className="text-center text-md-end">
                  Tudo 100% digital: Sem burocracia, todo o processo é
                  simplificado e digital.
                </p>
              </Col>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <img src={fgts2} />
              </Col>
            </Row>
            <Row className="grid flex-column-reverse flex-md-row">
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <img src={fgts3} />
              </Col>
              <Col md={6} className="align-items-center p-5">
                <h4 className="text-center text-md-start">
                  Mais informações sobre o produto
                </h4>
                <p className="text-center text-md-start">
                  É um tipo de empréstimo no qual você pode oferecer o seu bem
                  pra conseguir o crédito que precisa. É o tipo de empréstimo
                  com mais vantagens no mercado, pois além dele oferecer um
                  valor maior de crédito com juros menores, ainda permite um
                  parcelamento mais longo facilitando os pagamentos.
                </p>
              </Col>
            </Row>
          </div>
        </ArticleWrapper>
        <FaqFgts />
      </div>
    </Simple>
  )
}

export default EmprestimoGarantia

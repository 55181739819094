import { DadosComplementaresDocumentoReq } from 'models/cadastros/dadosComplementaresDocumento/types'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)
export const schema: yup.SchemaOf<DadosComplementaresDocumentoReq> = yup
  .object()
  .shape({
    numeroRg: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .required()
      .max(15)
      .label('RG'),
    orgaoEmissorId: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('Orgão emissor'),
    ufOrgaoEmissor: yup
      .string()
      .required()
      .transform((_, val) => (val ? val : ''))
      .label('UF emissor'),
    dataEmissao: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .test({
        name: 'dataAtualCheck',
        message: '${label} deve ser anterior a data atual',
        test: function (value) {
          if (value && new Date(value) < new Date()) {
            return true
          } else {
            return false
          }
        }
      })
      .required()
      .label('Data de expedição'),
    nomePai: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .nullable()
      .label('Nome do pai'),
    nomeMae: yup
      .string()
      .transform((_, val) => (val ? val : ''))
      .required()
      .min(3)
      .max(100)
      .label('Nome da mãe')
  })

import React from 'react'
import { ApresentacaoEmprestimoWrapper } from '../style'
import apresentacaoEmprestimo1 from 'assets/images/apresentacao/apresentacaoEmprestimo1.png'
import apresentacaoEmprestimo2 from 'assets/images/apresentacao/apresentacaoEmprestimo2.png'

const ApresentacaoEmprestimo = () => {
  return (
    <ApresentacaoEmprestimoWrapper id="conheca">
      <div className="primeira-linha">
        <div>
          <label>
            Por que escolher a <span>Riber</span> para o seu{' '}
            <span>empréstimo</span>?
          </label>
          <p>
            Com duas décadas de experiência no mercado de crédito, a Riber
            transformou os sonhos de mais de meio milhão de clientes em
            realidade. Nosso compromisso vai além de fornecer serviços
            financeiros; nós nos dedicamos a criar caminhos para o crescimento,
            realização pessoal e prosperidade dos nossos clientes, sempre
            pautados pela ética e pela transparência.
          </p>
        </div>
        <div>
          <img src={apresentacaoEmprestimo1} style={{ width: '350px' }} />
        </div>
      </div>
      <div className="segunda-linha">
        <div>
          <img src={apresentacaoEmprestimo2} style={{ width: '350px' }} />
        </div>
        <div>
          <label>
            <span>As Mais Competitivas Taxas</span> de Juros do Mercado
          </label>
          <p>
            Na Riber, você encontra soluções de crédito sob medida para
            reestruturar suas finanças e desfrutar de paz de espírito. Nossas
            opções de crédito se destacam por oferecer as taxas mais
            competitivas do mercado, garantindo que você tenha acesso ao melhor
            custo-benefício. Escolha a Riber e dê o passo certo rumo à
            estabilidade financeira.
          </p>
        </div>
      </div>
    </ApresentacaoEmprestimoWrapper>
  )
}

export default ApresentacaoEmprestimo

import React, { useEffect } from 'react'
import InputCustom from 'components/Inputs'
import { Col, Row } from 'reactstrap'
import SimpleCadastro from 'components/SimpleCadastro/SimpleCadastro'
import dadosComplementares from 'assets/images/cadastros/dados-complementares.png'
import Steps from 'components/Steps'
import { STEPS_CADASTRO_COMPLEMENTAR } from 'views/cadastros/constants/constants'
import { CustomButton } from 'styles/global'
import { DADOS_COMPLEMENTARES_ENDERECO } from 'store/modules/cadastros/dadosComplementaresEndereco/types'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import useRedux from 'hooks/useRedux'
import * as dadosComplementaresEnderecoActions from 'store/modules/cadastros/dadosComplementaresEndereco/actions'
import CustomSelect from 'components/Select'
import { maskCEP, maskNumber } from 'util/masks'
import { schema } from './constants/schema'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { stepCadastroComplementarRedirect } from 'util/stepCadastroComplementarRedirect'

const DadosComplementaresEndereco = () => {
  const { formulario, invalidFormulario, optionsSelects, loadingSelects } =
    useSelector<ApplicationState, DADOS_COMPLEMENTARES_ENDERECO>(
      (state) => state.dadosComplementaresEndereco
    )

  const { dispatch } = useRedux()

  type FormularioField = keyof DADOS_COMPLEMENTARES_ENDERECO['formulario']
  const onChange = <T extends FormularioField>(
    value: T extends keyof DADOS_COMPLEMENTARES_ENDERECO['formulario']
      ? DADOS_COMPLEMENTARES_ENDERECO['formulario'][T]
      : never,
    field: T
  ) => {
    if (field === 'siglaEstado') {
      dispatch(
        dadosComplementaresEnderecoActions.setFormularioField('', 'cidade')
      )
    }
    dispatch(
      dadosComplementaresEnderecoActions.setFormularioField(value, field)
    )
  }

  useEffect(() => {
    dispatch(dadosComplementaresEnderecoActions.getEstadosSaga())
    dispatch(dadosComplementaresEnderecoActions.getCpfSaga())
  }, [dispatch])

  useEffect(() => {
    if (formulario.siglaEstado) {
      dispatch(
        dadosComplementaresEnderecoActions.getCidadesSaga(
          formulario.siglaEstado
        )
      )
    }
  }, [dispatch, formulario.siglaEstado])

  useEffect(() => {
    if (formulario.cep.toString().length === 9) {
      dispatch(dadosComplementaresEnderecoActions.getDadosEnderecoSaga())
    }
  }, [dispatch, formulario.cep])

  const onSave = () => {
    dispatch(dadosComplementaresEnderecoActions.cleanInvalidsFormulario())
    schema
      .validate(
        {
          cep: formulario.cep,
          siglaEstado: formulario.siglaEstado,
          cidade: formulario.cidade,
          bairro: formulario.bairro,
          logradouro: formulario.logradouro,
          numero: formulario.numero,
          complemento: formulario.complemento
        },
        {
          abortEarly: false
        }
      )
      .then(() => {
        dispatch(dadosComplementaresEnderecoActions.salvarCadastroSaga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            dadosComplementaresEnderecoActions.setInvalidFormularioField(
              {
                invalid: true,
                message: e.message
              },
              e.path as keyof DADOS_COMPLEMENTARES_ENDERECO['invalidFormulario']
            )
          )
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  return (
    <SimpleCadastro banner={dadosComplementares}>
      <div className="container-fluid d-flex flex-column row-gap-20">
        <Steps steps={STEPS_CADASTRO_COMPLEMENTAR} currentStep={3} />
        <Row>
          <Col>
            <label className="label-12">CEP*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskCEP(e.target.value), 'cep')
              }}
              value={maskCEP(formulario.cep)}
              invalid={invalidFormulario.cep.invalid}
              errorMessage={invalidFormulario.cep.message}
              maxLength={9}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">UF*</label>
            <CustomSelect
              isLoading={loadingSelects.estados}
              options={optionsSelects.estados}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'siglaEstado')
              }}
              value={formulario.siglaEstado}
              accessorLabel="nome"
              accessorValue="sigla"
              invalid={invalidFormulario.siglaEstado.invalid}
              errorMessage={invalidFormulario.siglaEstado.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Cidade*</label>
            <CustomSelect
              isLoading={loadingSelects.cidades}
              options={optionsSelects.cidades}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'cidade')
              }}
              value={formulario.cidade}
              accessorLabel="nome"
              accessorValue="nome"
              invalid={invalidFormulario.cidade.invalid}
              errorMessage={invalidFormulario.cidade.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Bairro*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'bairro')
              }}
              value={formulario.bairro}
              invalid={invalidFormulario.bairro.invalid}
              errorMessage={invalidFormulario.bairro.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Logradouro*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'logradouro')
              }}
              value={formulario.logradouro}
              invalid={invalidFormulario.logradouro.invalid}
              errorMessage={invalidFormulario.logradouro.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Número*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskNumber(e.target.value), 'numero')
              }}
              value={formulario.numero}
              invalid={invalidFormulario.numero.invalid}
              errorMessage={invalidFormulario.numero.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Complemento</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'complemento')
              }}
              value={formulario.complemento}
              invalid={invalidFormulario.complemento.invalid}
              errorMessage={invalidFormulario.complemento.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomButton
              background="var(--roxo-riber)"
              width="90%"
              padding="20"
              height="50px"
              borderRadius={21}
              onClick={() => {
                stepCadastroComplementarRedirect(2)
              }}
            >
              VOLTAR
            </CustomButton>
          </Col>
          <Col>
            <CustomButton
              background="var(--roxo-riber)"
              width="90%"
              padding="20"
              height="50px"
              borderRadius={21}
              onClick={() => {
                onSave()
              }}
            >
              AVANÇAR
            </CustomButton>
          </Col>
        </Row>
      </div>
    </SimpleCadastro>
  )
}

export default DadosComplementaresEndereco

import { Estado, Invalid, OrgaoEmissor } from 'models/types'

export enum TYPES {
  SET_FORMULARIO = '@@dadosComplementaresDocumento/SET_FORMULARIO',
  SET_FORMULARIO_FIELD = '@@dadosComplementaresDocumento/SET_FORMULARIO_FIELD',
  CLEAN_FORMULARIO = '@@dadosComplementaresDocumento/CLEAN_FORMULARIO',
  SET_INVALID_FORMULARIO = '@@dadosComplementaresDocumento/SET_INVALID_FORMULARIO',
  CLEAN_INVALIDS_FORMULARIO = '@@dadosComplementaresDocumento/CLEAN_INVALIDS_FORMULARIO',
  SET_OPTIONS_SELECT = '@@dadosComplementaresDocumento/SET_OPTIONS_SELECT',
  SET_LOADING_SELECTS = '@@dadosComplementaresDocumento/SET_LOADING_SELECTS',
  SET_CPF = '@@dadosComplementaresDocumento/SET_CPF',

  GET_DADOS_COMPLEMENTARES_DOCUMENTO_SAGA = '@@dadosComplementaresDocumento/GET_DADOS_COMPLEMENTARES_DOCUMENTO_SAGA',
  SALVAR_CADASTRO_SAGA = '@@dadosComplementaresDocumento/SALVAR_CADASTRO_SAGA',
  GET_ORGAOS_EMISSORES_SAGA = '@@dadosComplementaresDocumento/GET_ORGAOS_EMISSORES_SAGA',
  GET_UFS_ORGAOS_EMISSOR_SAGA = '@@dadosComplementaresDocumento/GET_UFS_ORGAOS_EMISSOR_SAGA',
  GET_CPF_SAGA = '@@dadosComplementaresDocumento/GET_CPF_SAGA'
}

export interface DADOS_COMPLEMENTARES_DOCUMENTO {
  cpf: string
  formulario: FormCadastro
  invalidFormulario: InvalidFormCadastro
  optionsSelects: OptionsSelects
  loadingSelects: LoadingSelects
}

interface FormCadastro {
  numeroRg: string
  orgaoEmissorId: string
  ufOrgaoEmissor: string
  dataEmissao: string
  nomeMae: string
  nomePai: string
}

type InvalidFormCadastro = {
  [K in keyof FormCadastro]: Invalid
}

interface OptionsSelects {
  orgaosEmissor: OrgaoEmissor[]
  ufsOrgaoEmissor: Estado[]
}

type LoadingSelects = {
  [k in keyof OptionsSelects]: boolean
}

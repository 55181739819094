import { APICore } from 'helpers/api/apiCore'
import {
  RequestInserirSolicitacaoDto,
  RequestValidacaoTokenIn100Dto
} from 'models/cadastros/validacao/types'
import { removeMaskCPF } from 'util/masks'

const api = new APICore()

export function getClienteMatriculaCpf() {
  return api.get(`Generic/informacoes-cliente-matricula`, null)
}

export function validaStatusIN100(cpf: string, matricula: string) {
  const cpfFormatted = removeMaskCPF(cpf)
  return api.create(`In100/valida-status-in100`, {
    cpf: cpfFormatted,
    matricula
  })
}

export function getPropostasCrm(cpf: string, numeroMatricula: string) {
  const cpfFormatted = removeMaskCPF(cpf)
  return api.get(`Simulacao/simulacao`, {
    cpf: cpfFormatted,
    numeroMatricula
  })
}

export function solicitarSimulacaoFgts() {
  return api.create(`fgts/solicitar-simulacao`, {
    valorSolicitado: 0,
    quantidadeParcela: 10
  })
}

export function getParcelaFgts() {
  return api.get(`fgts/parcela`, null)
}

export function recalculoProposta(
  cpf: string,
  servico: number,
  parcelas: number,
  valorTotalFinanciado: number | null,
  valorParcela: number | null,
  tabela: number,
  numeroMatricula: string,
  banco: number
) {
  const cpfFormatted = removeMaskCPF(cpf)
  return api.getWithoutUndefined(`Simulacao/recalculo`, {
    cpf: cpfFormatted,
    quantidadeParcelas: parcelas,
    codigoProduto: tabela,
    servico,
    valorTotalFinanciado,
    valorParcela,
    numeroMatricula,
    banco
  })
}

export function recalculoPropostaFgts(valor: number, parcelas: number) {
  return api.create(`fgts/solicitar-simulacao`, {
    valorSolicitado: valor,
    quantidadeParcela: parcelas
  })
}

export function validacaoContratacao(cpf: string, matriculas: string[]) {
  return api.create(`Contratacao/validacao-contratacao`, {
    matriculas,
    cpf
  })
}

export function getResumoContratacao(cpf: string, matriculas: string[]) {
  return api.create(`Contratacao/resumo-contratacao`, {
    matriculas,
    cpf
  })
}

interface FinalizacaoEmprestimoBmg {
  cpf: string
  numeroMatricula: string
  quantidadeParcelas: number
  valorParcela: number
  servico: number
  numeroProduto: number
  bancoSimulacaoEnum: number
  codigoFormaEnvioTermo: string
  valorLiberado: number
}

export async function finalizacaoEmprestimoBmg({
  cpf,
  ...params
}: FinalizacaoEmprestimoBmg) {
  const cpfFormatted = removeMaskCPF(cpf)
  return api.create(`Contratacao/emprestimo_bmg`, {
    cpf: cpfFormatted,
    ...params
  })
}

interface FinalizacaoEmprestimoItau {
  codigoSimulacao: number
  cpf: string
  numeroMatricula: string
  quantidadeParcelas: number
  valorParcela: number
  servico: number
  bancoSimulacaoEnum: number
  valorLiberado: number
}

export async function finalizacaoEmprestimoItau({
  cpf,
  ...params
}: FinalizacaoEmprestimoItau) {
  const cpfFormatted = removeMaskCPF(cpf)
  return api.create(`Contratacao/emprestimo_itau`, {
    cpf: cpfFormatted,
    ...params
  })
}

interface FinalizacaoCartaoCreditoBmg {
  cpf: string
  numeroMatricula: string
  valorSaque: number
  servico: number
  flagAberturaConta: boolean
  flagSaqueAutorizado: boolean
  bancoSimulacaoEnum: number
}

export async function finalizacaoCartaoCreditoBmg({
  cpf,
  ...params
}: FinalizacaoCartaoCreditoBmg) {
  const cpfFormatted = removeMaskCPF(cpf)
  return api.create(`Contratacao/cartao-credito-bmg`, {
    cpf: cpfFormatted,
    ...params
  })
}

interface FinalizacaoCartaoBeneficioBmg {
  cpf: string
  numeroMatricula: string
  flagSaqueAutorizado: boolean
  flagSaqueParcelado: boolean
  quantidadeParcelas: number
  valorParcela: number
  valorSaque: number
  servico: number
  flagAberturaConta: boolean
  bancoSimulacaoEnum: number
}

export async function finalizacaoCartaoBeneficioBmg({
  cpf,
  ...params
}: FinalizacaoCartaoBeneficioBmg) {
  const cpfFormatted = removeMaskCPF(cpf)
  return api.create(`Contratacao/cartao-beneficio-bmg`, {
    cpf: cpfFormatted,
    ...params
  })
}

interface FinalizacaoSaqueCartaoBmg {
  cpf: string
  numeroMatricula: string
  numeroContaInterna: number
  quantidadeParcelas: number
  valorParcela: number
  valorSaque: number
  flagSaqueAutorizado: boolean
  flagAberturaConta: boolean
  servico: number
  bancoSimulacaoEnum: number
}

export async function finalizacaoSaqueCartaoBmg({
  cpf,
  ...params
}: FinalizacaoSaqueCartaoBmg) {
  const cpfFormatted = removeMaskCPF(cpf)
  return api.create(`Contratacao/saque-cartao-bmg`, {
    cpf: cpfFormatted,
    ...params
  })
}

export async function finalizacaoContratacaoFgts() {
  return api.create(`fgts/solicitar-proposta`, null)
}

interface FinalizacaoSeguroSindinapiBmg {
  cpf: string
  numeroMatricula: string
  servico: number
  bancoSimulacaoEnum: number
}

export async function finalizacaoContratacaoSeguroSindinapiBmg({
  cpf,
  ...params
}: FinalizacaoSeguroSindinapiBmg) {
  const cpfFormatted = removeMaskCPF(cpf)
  return api.create(`Contratacao/seguro-sindnapi-bmg`, {
    cpf: cpfFormatted,
    ...params
  })
}

export function getPerfis() {
  return api.get(`Generic/perfis`, null)
}

export function getTiposConta() {
  return api.get(`Generic/tipos-conta`, null)
}

export function getBancos() {
  return api.get(`Generic/bancos-dados-bancarios`, null)
}

export function getConvenios() {
  return api.get(`Generic/convenios`, null)
}

export function inserirSolicitacaoIn100(params: RequestInserirSolicitacaoDto) {
  return api.create(`In100/inserir-solicitacao`, params)
}

export function validarTokenIn100(params: RequestValidacaoTokenIn100Dto) {
  return api.create(`In100/validar-token`, params)
}

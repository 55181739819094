import React, { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Collapse } from 'reactstrap'
import { CardFaq, FaqWrapper } from '../style'
import imageFaq from 'assets/images/apresentacao/fgts4.png'

const FaqFgts = () => {
  const [cardsState, setCardsState] = useState({
    firstCard: false,
    secondCard: false,
    thirdCard: false,
    fourthCard: false,
    fifthCard: false,
    sixthCard: false,
    seventhCard: false
  })

  const toggleCardVisibility = (cardName: keyof typeof cardsState) => {
    setCardsState((prevCards) => ({
      ...prevCards,
      [cardName]: !prevCards[cardName]
    }))
  }

  return (
    <FaqWrapper backgroundImage={imageFaq}>
      <div className="d-none d-lg-flex">
        <img className="img-faq" src={imageFaq} />
      </div>
      <div className="faq">
        <h3>Perguntas Frequentes</h3>
        <CardFaq>
          <label>
            O que é a Antecipação Saque Aniversário FGTS?
            {cardsState.firstCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('firstCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('firstCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.firstCard}>
            <p>
              É um empréstimo que permite a antecipação do valor de até 10 (dez)
              parcelas de Saque Aniversário do FGTS às quais o cliente tem
              direito de sacar anualmente no mês do seu aniversário.
            </p>
            <p>
              Para ter direito ao Saque Aniversário, é necessário que o
              trabalhador faça a opção pela modalidade Saque Aniversário através
              do App FGTS.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Quem pode contratar a Antecipação do Saque Aniversário?
            {cardsState.secondCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('secondCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('secondCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.secondCard}>
            <p>
              Todo cliente com conta vinculada de FGTS, ativa ou inativa, que
              tenha aderido à modalidade Saque Aniversário através de um dos
              canais disponibilizados pela CAIXA, possua conta corrente na CAIXA
              e não possua empréstimo em atraso com a CAIXA.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Quais as vantagens em contratar a Antecipação do Saque Aniversário
            FGTS?
            {cardsState.thirdCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('thirdCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('thirdCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.thirdCard}>
            <p>
              Rapidez e facilidade: acesso imediato ao recurso, sem esperar a
              data do Saque Aniversário;
            </p>
            <p>
              Uma das menores taxas de juros em produtos de crédito comercial;
            </p>
            <p>
              Possibilidade de antecipação até 10 (dez) parcelas anuais de Saque
              Aniversário FGTS;
            </p>
            <p>
              Sem prestação mensal, assim você não compromete o seu orçamento
              mensal com pagamento de parcelas;
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Quanto será possível antecipar?
            {cardsState.fourthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('fourthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('fourthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.fourthCard}>
            <p>
              É possível antecipar os créditos de até10 (dez) parcelas anuais do
              Saque Aniversário do FGTS, sendo celebrado um contrato para cada
              período antecipado.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Onde posso consultar o valor que tenho disponível para antecipar?
            {cardsState.fifthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('fifthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('fifthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.fifthCard}>
            <p>
              O cliente deve acessar suas contas vinculadas do FGTS, podendo
              realizar simulações aqui no nosso site ou no nosso 0800 941 1111
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            A operação compromete minha capacidade de pagamento?
            {cardsState.sixthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('sixthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('sixthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.sixthCard}>
            <p>
              Não, pois na Antecipação de Saque Aniversário, o cliente quita o
              empréstimo numa única parcela utilizando o valor que tem direito
              ao Saque Aniversário, sem comprometer a sua renda mensal.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            O processo de contratação é burocrático?
            {cardsState.seventhCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('seventhCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('seventhCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.seventhCard}>
            <p>
              Não. A contratação se dará de forma online, sendo que consultas de
              saldo em conta e liberação do crédito pode acorrer no mesmo dia.
            </p>
          </Collapse>
        </CardFaq>
      </div>
    </FaqWrapper>
  )
}

export default FaqFgts

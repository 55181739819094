import { TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as loadingActions from 'store/modules/loading/actions'
import * as validacaoController from 'controller/validacaoController'
import * as validacaoActions from 'store/modules/cadastros/validacao/actions'
import { VALIDACAO } from './types'
import { ApplicationState } from 'store'
import { handlerError } from 'util/handlerError'
import { toast } from 'react-toastify'
import { maskPhoneWithDDD } from 'util/masks'
import history from 'util/history'

function* inserirSolicitacoesIn100(): SagaIterator {
  const { validacaoFields }: VALIDACAO = yield select(
    (state: ApplicationState) => state.validacao
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(validacaoController.inserirSolicitacaoIn100, {
      ddd: validacaoFields.celular.substring(1, 3),
      telefone: validacaoFields.celular.substring(5).replace('-', ''),
      matricula: validacaoFields.matricula
    })
    toast.success(
      'Verifique a mensagem no seu celular e informe no campo abaixo.'
    )
  } catch (error) {
    handlerError(error, 'Não foi possível enviar a solicitação')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* pesquisarSolicitacaoIn100(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    yield call(validacaoController.pesquisarSolicitacaoIn100, {
      cpf: '',
      numeroSolicitacao: '',
      dataAtualizacao: '',
      tokenAutenticacao: ''
    })
    toast.success('')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* validarTokenIn100(): SagaIterator {
  const { validacaoFields }: VALIDACAO = yield select(
    (state: ApplicationState) => state.validacao
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(validacaoController.validarTokenIn100, {
      token: validacaoFields.codigo,
      matricula: validacaoFields.matricula
    })
    toast.success('Código validado com sucesso')
    history.push('/simulacao')
  } catch (error) {
    handlerError(error, 'Não foi possível validar o código')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getTelefoneMatriculaInsereSolicitacao(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(validacaoController.getTelefoneMatricula)
    yield put(
      validacaoActions.setValidacaoFields(
        maskPhoneWithDDD(
          (response?.data?.content?.[0].ddd ?? '') +
            (response?.data?.content?.[0].numero ?? '')
        ),
        'celular'
      )
    )
    yield put(
      validacaoActions.setValidacaoFields(
        response?.data?.content?.[0].numeroMatricula ?? '',
        'matricula'
      )
    )
    yield put(validacaoActions.inserirSolicitacoesIn100Saga())
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchInserirSolicitacoesIn100() {
  yield takeEvery(
    TYPES.INSERIR_SOLICITACOES_IN100_SAGA,
    inserirSolicitacoesIn100
  )
}

export function* watchPesquisarSolicitacaoIn100() {
  yield takeEvery(
    TYPES.PESQUISAR_SOLICITACAO_IN100_SAGA,
    pesquisarSolicitacaoIn100
  )
}

export function* watchValidarTokenIn100() {
  yield takeEvery(TYPES.VALIDAR_TOKEN_IN100_SAGA, validarTokenIn100)
}

export function* watchGetTelefoneMatriculaInsereSolicitacao() {
  yield takeEvery(
    TYPES.GET_TELEFONE_MATRICULA_INSERE_SOLICITACAO_SAGA,
    getTelefoneMatriculaInsereSolicitacao
  )
}

function* valicao() {
  yield all([
    fork(watchInserirSolicitacoesIn100),
    fork(watchPesquisarSolicitacaoIn100),
    fork(watchValidarTokenIn100),
    fork(watchGetTelefoneMatriculaInsereSolicitacao)
  ])
}

export default valicao

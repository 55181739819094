import { APICore } from 'helpers/api/apiCore'

const api = new APICore()

export function abandonoClienteRedirect(status: number) {
  const routeMappings: Record<number, string> = {
    2: '/profissional',
    3: '/validacao'
  }

  let redirectTo = window.location.pathname.toLowerCase()

  if (
    (!routeMappings[status] &&
      Object.values(routeMappings).some(
        (route) => route === window.location.pathname.toLowerCase()
      )) ||
    (!routeMappings[status] &&
      window.location.pathname.toLowerCase() === '/login' &&
      api.isAuthenticated())
  ) {
    redirectTo = '/simulacao'
  }

  if (routeMappings[status]) {
    redirectTo = routeMappings[status]
  }

  if (window.location.pathname.toLowerCase() !== redirectTo) {
    window.location.replace(redirectTo)
  }
}

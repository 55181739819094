import { Invalid, Perfil } from 'models/types'
import { DADOS_COMPLEMENTARES_DADOS_BANCARIOS, TYPES } from './types'

export const setFormulario = (
  value: DADOS_COMPLEMENTARES_DADOS_BANCARIOS['formulario']
) => ({
  type: TYPES.SET_FORMULARIO,
  value
})

type FormularioField = keyof DADOS_COMPLEMENTARES_DADOS_BANCARIOS['formulario']
export const setFormularioField = <T extends FormularioField>(
  value: T extends keyof DADOS_COMPLEMENTARES_DADOS_BANCARIOS['formulario']
    ? DADOS_COMPLEMENTARES_DADOS_BANCARIOS['formulario'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_FORMULARIO_FIELD,
  value,
  field
})

export const cleanFormulario = () => ({
  type: TYPES.CLEAN_FORMULARIO
})

export const setInvalidFormulario = (
  value: DADOS_COMPLEMENTARES_DADOS_BANCARIOS['invalidFormulario']
) => ({
  type: TYPES.SET_INVALID_FORMULARIO,
  value
})

type InvalidFormularioField =
  keyof DADOS_COMPLEMENTARES_DADOS_BANCARIOS['invalidFormulario']
export const setInvalidFormularioField = <T extends InvalidFormularioField>(
  value: T extends keyof DADOS_COMPLEMENTARES_DADOS_BANCARIOS['invalidFormulario']
    ? DADOS_COMPLEMENTARES_DADOS_BANCARIOS['invalidFormulario'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_INVALID_FORMULARIO_FIELD,
  value,
  field
})

export const setInvalidFormularioFieldArray = <
  T extends InvalidFormularioField,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  N extends keyof MEUS_DADOS['invalidMeusDados'][T][number]
>(
  value: Invalid,
  field: T,
  index: number,
  nestedNameArray: N
) => ({
  type: TYPES.SET_INVALID_FORMULARIO_FIELD_ARRAY,
  value,
  field,
  index,
  nestedNameArray
})

export const cleanInvalidsFormulario = () => ({
  type: TYPES.CLEAN_INVALIDS_FORMULARIO
})

export const salvarCadastroSaga = () => ({
  type: TYPES.SALVAR_CADASTRO_SAGA
})

export const setActiveTabBeneficio = (value: number) => ({
  type: TYPES.SET_ACTIVE_TAB_BENEFICIO,
  value
})

export const adicionarPerfil = (value: Perfil) => ({
  type: TYPES.ADICIONAR_PERFIL,
  value
})

export const removerPerfil = (indexPerfil: number) => ({
  type: TYPES.REMOVER_PERFIL,
  indexPerfil
})

export const getPerfisSaga = () => ({
  type: TYPES.GET_PERFIS_SAGA
})

export const getTiposContaSaga = () => ({
  type: TYPES.GET_TIPOS_CONTA_SAGA
})

export const getBancosSaga = () => ({
  type: TYPES.GET_BANCOS_SAGA
})

export const getConveniosSaga = () => ({
  type: TYPES.GET_CONVENIOS_SAGA
})

type LoadingSelectFields =
  keyof DADOS_COMPLEMENTARES_DADOS_BANCARIOS['loadingSelects']
export const setLoadingSelect = <T extends OptionsSelectFields>(
  value: T extends LoadingSelectFields
    ? DADOS_COMPLEMENTARES_DADOS_BANCARIOS['loadingSelects'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_LOADING_SELECTS,
  value,
  field
})

type OptionsSelectFields =
  keyof DADOS_COMPLEMENTARES_DADOS_BANCARIOS['optionsSelects']
export const setOptionsSelect = <T extends OptionsSelectFields>(
  value: T extends OptionsSelectFields
    ? DADOS_COMPLEMENTARES_DADOS_BANCARIOS['optionsSelects'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_OPTIONS_SELECT,
  value,
  field
})

export const getDadosComplementaresDadosBancariosSaga = () => ({
  type: TYPES.GET_DADOS_COMPLEMENTARES_DADOS_BANCARIOS_SAGA
})

export const getCpfSaga = () => ({
  type: TYPES.GET_CPF_SAGA
})

export const setCpf = (value: string) => ({
  type: TYPES.SET_CPF,
  value
})

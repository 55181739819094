import React from 'react'
import { AiOutlineWarning } from 'react-icons/ai'
import { AtencaoWrapper } from '../style'

const Atencao = () => {
  return (
    <AtencaoWrapper>
      <div>
        <AiOutlineWarning className="icon" size={30} />
        <span>
          Atenção: Não solicitamos pagamentos antecipados para a liberação do
          seu empréstimo.
        </span>
      </div>
    </AtencaoWrapper>
  )
}

export default Atencao

import React from 'react'
import {
  FiBriefcase,
  FiArrowRight,
  FiCreditCard,
  FiSmartphone
} from 'react-icons/fi'
import { BsCashCoin } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { InfoProdutosWrapper, CardInfoProduto } from '../style'

const InfoProdutos = () => {
  return (
    <InfoProdutosWrapper id="produtos">
      <CardInfoProduto>
        <div className="content-infos">
          <FiBriefcase className="icon" size={60} />
          <label>Crédito consignado</label>
          <span>
            Contrate o empréstimo consignado com taxas vantajosas de forma
            rápida, fácil e 100% digital.
          </span>
        </div>
        <Link to="/produtos/credito-consignado">
          Saiba mais <FiArrowRight size={20} />
        </Link>
      </CardInfoProduto>
      <CardInfoProduto>
        <div className="content-infos">
          <FiCreditCard className="icon" size={60} />
          <label>Cartão consignado</label>
          <span>
            Exclusivo para aposentados, pensionistas do INSS e servidores
            públicos com taxas de juros muito mais baixas do que um cartão de
            crédito comum.
          </span>
        </div>
        <Link to="/produtos/cartao-consignado">
          Saiba mais <FiArrowRight size={20} />
        </Link>
      </CardInfoProduto>
      <CardInfoProduto>
        <div className="content-infos">
          <FiSmartphone className="icon" size={60} />
          <label>Antecipação saque aniversário FGTS</label>
          <span>
            Antecipe seu saldo FGTS, sem comprometer sua renda mensal. Contrate
            Online e receba o dinheiro no mesmo dia.
          </span>
        </div>
        <Link to="/produtos/FGTS">
          Saiba mais <FiArrowRight size={20} />
        </Link>
      </CardInfoProduto>
      <CardInfoProduto>
        <div className="content-infos">
          <BsCashCoin className="icon" size={60} />
          <label>Crédito pessoal</label>
          <span>
            Crédito pessoal para aposentados, pensionistas do INSS e servidores
            públicos mesmo sem margem consignável. Simule e veja as ofertas.
          </span>
        </div>
        <Link to="/produtos/credito-pessoal">
          Saiba mais <FiArrowRight size={20} />
        </Link>
      </CardInfoProduto>
    </InfoProdutosWrapper>
  )
}

export default InfoProdutos

import styled from 'styled-components'

export const CardPropostaWrapper = styled.div`
  background: var(--banco);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  color: var(--dark-gray);
  font-size: 14px;
  padding: 40px 20px;
  row-gap: 10px;
  width: 100%;

  & label {
    color: var(--dark-gray);
    font-size: 14px;
    line-height: 24px;
  }

  & .strong-green,
  & .strong-black {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }

  & .strong-green {
    color: var(--verde-riber);
  }

  & .strong-black {
    color: var(--preto-riber);
  }

  & .icon {
    width: 32px;
    height: 32px;
  }
`

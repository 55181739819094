import styled from 'styled-components'

export const CustomLabel = styled.label`
  color: var(--dark-gray);
  font-size: 16px;
  font-weight: 600;
`

export const CustomTitleCadastro = styled.h3`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
`
export const CardInfoPurple = styled.div`
  background: #662d9133;
  border: solid 1px var(--roxo-riber);
  border-radius: 15px;
  color: var(--roxo-riber);
  font-weight: 500;
  padding: 18px;
`

export const CustomLink = styled.label`
  color: var(--dark-gray);
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
`

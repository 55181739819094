import React, { useEffect } from 'react'
import Main from 'layouts/main'
import SimpleCard from 'components/SimpleCard'
import { FiDownload } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import Beneficios from './components/Beneficios'
import InformacoesBasicas from './components/InformacoesBasicas'
import InformacoesComplementares from './components/InformacoesComplementares'
import Endereco from './components/Endereco'
import { schema } from './constants/schema'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { MEUS_DADOS } from 'store/modules/meusDados/types'
import * as meusDadosActions from 'store/modules/meusDados/actions'
import * as yup from 'yup'
import useRedux from 'hooks/useRedux'
import { MeusDados as MeusDadosType } from 'models/meusDados/types'
import { maskNumber, removeMaskMoney } from 'util/masks'
import { toast } from 'react-toastify'

const MeusDados = () => {
  const { meusDados } = useSelector<ApplicationState, MEUS_DADOS>(
    (state) => state.meusDados
  )

  const { dispatch } = useRedux()

  function onSave() {
    dispatch(meusDadosActions.cleanInvalidMeusDados())
    schema
      .validate(
        {
          nome: meusDados.nome,
          cpf: meusDados.cpf,
          dataNascimento: meusDados.dataNascimento,
          nomePai: meusDados.nomePai,
          nomeMae: meusDados.nomeMae,
          rg: meusDados.rg,
          orgaoEmissorId: meusDados.orgaoEmissorId,
          ufOrgaoEmissor: meusDados.ufOrgaoEmissor,
          dataEmissao: meusDados.dataEmissao,
          ddd: meusDados.ddd,
          numero: maskNumber(meusDados.numero),
          email: meusDados.email,
          sexo: meusDados.sexo,
          estadoCivil: meusDados.estadoCivil,
          nomeConjuge: meusDados.nomeConjuge,
          cidadeNascimento: meusDados.cidadeNascimento,
          ufNascimento: meusDados.ufNascimento,
          matriculas: meusDados.matriculas?.map((matricula) => {
            return {
              id: matricula.id,
              numeroMatricula: matricula.numeroMatricula,
              tipoPerfil: matricula.tipoPerfil,
              descricaoPerfil: matricula.descricaoPerfil,
              tipoPerfilId: matricula.tipoPerfilId,
              convenioId: matricula.convenioId,
              margemDisponivel: Number(
                removeMaskMoney(matricula.margemDisponivel)
              ),
              margemCartaoDisponivel: Number(
                removeMaskMoney(matricula.margemCartaoDisponivel)
              ),
              margemCartaoBeneficio: Number(
                removeMaskMoney(matricula.margemCartaoBeneficio)
              ),
              valorRendaIndividual: Number(
                removeMaskMoney(matricula.valorRendaIndividual)
              ),
              contaCorrenteId: matricula.contaCorrenteId,
              tipoContaId: matricula.tipoContaId,
              bancoDadosBancariosId: matricula.bancoDadosBancariosId,
              numeroAgencia: matricula.numeroAgencia,
              digitoVerificadorAgencia: matricula.digitoVerificadorAgencia,
              numeroConta: matricula.numeroConta,
              digitoVerificadorConta: matricula.digitoVerificadorConta
            }
          }),
          escolaridadeId: meusDados.escolaridadeId,
          cep: meusDados.cep,
          cidade: meusDados.cidade,
          siglaEstado: meusDados.siglaEstado,
          logradouro: meusDados.logradouro,
          numeroEndereco: meusDados.numeroEndereco,
          bairro: meusDados.bairro,
          complemento: meusDados.complemento,
          profissaoId: meusDados.profissaoId,
          novaSenha: meusDados.novaSenha
        },
        {
          abortEarly: false
        }
      )
      .then(() => {
        if (meusDados.matriculas?.length <= 0) {
          toast.warn(
            'Por favor, lembre-se de preencher pelo menos uma matrícula antes de salvar.'
          )
        } else {
          dispatch(meusDadosActions.salvarInformacoesSaga())
        }
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          if (e.path?.includes('[')) {
            const field = e.path?.split('[')
            const indexArray = e.path?.match(/\[(.*?)\]/) || []
            const nestedFieldName = e.path?.split('.')
            dispatch(
              meusDadosActions.setInvalidMeusDadosFieldArray(
                {
                  invalid: true,
                  message: e.message
                },
                field[0] as keyof MeusDadosType,
                Number(indexArray[1]),
                nestedFieldName[1] as never
              )
            )
            dispatch(
              meusDadosActions.setActiveTabBeneficio(Number(indexArray[1]))
            )
          } else {
            dispatch(
              meusDadosActions.setInvalidMeusDadosField(
                {
                  invalid: true,
                  message: e.message
                },
                e.path as keyof MeusDadosType
              )
            )
          }
        })
        toast.warn('Preencha todos os campos corretamente para salvar!')
      })
  }

  useEffect(() => {
    dispatch(meusDadosActions.getPerfisSaga())
    dispatch(meusDadosActions.getGenerosSaga())
    dispatch(meusDadosActions.getOrgaosEmissoresSaga())
    dispatch(meusDadosActions.getUfsOrgaosEmissoresSaga())
    dispatch(meusDadosActions.getEscolaridadesSaga())
    dispatch(meusDadosActions.getUfsNascimentosSaga())
    dispatch(meusDadosActions.getEstadosCivisSaga())
    dispatch(meusDadosActions.getUfsSaga())
    dispatch(meusDadosActions.getTiposContaSaga())
    dispatch(meusDadosActions.getBancosSaga())
    dispatch(meusDadosActions.getConveniosSaga())
    dispatch(meusDadosActions.getProfissoesSaga())
    dispatch(meusDadosActions.getInformacoesSaga())
  }, [dispatch])

  useEffect(() => {
    if (meusDados.ufNascimento) {
      dispatch(
        meusDadosActions.getCidadesNascimentoSaga(meusDados.ufNascimento)
      )
    }
  }, [dispatch, meusDados.ufNascimento])

  useEffect(() => {
    if (meusDados.siglaEstado) {
      dispatch(meusDadosActions.getCidadesSaga(meusDados.siglaEstado))
    }
  }, [dispatch, meusDados.siglaEstado])

  return (
    <Main>
      <SimpleCard>
        <div className="container-fluid">
          <Beneficios />
          <InformacoesBasicas />
          <InformacoesComplementares />
          <Endereco />
        </div>
      </SimpleCard>
      <Row>
        <Col className="d-flex justify-content-end">
          <div
            onClick={() => {
              onSave()
            }}
            className="btn-outline-purple"
          >
            <FiDownload /> Salvar Informações
          </div>
        </Col>
      </Row>
    </Main>
  )
}

export default MeusDados

import React, { useEffect } from 'react'
import history from 'util/history'

const Produtos = () => {
  useEffect(() => {
    history.push('/')
  }, [])

  return <></>
}

export default Produtos

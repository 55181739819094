import styled from 'styled-components'
import { goLeft } from 'styles/global'

interface SidebarProps {
  toggle?: boolean
}

export const ContentSidebar = styled.div<SidebarProps>`
  background: var(--branco);
  box-shadow: 0 0 5.41032px rgba(0, 0, 0, 0.18);
  width: 18vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 624px;
  height: 80vh;
  position: absolute;
  top: calc((100vh * 0.08) + 20px);
  left: 10px;
  border-radius: 20px;
  padding: 37px;
  color: var(--roxo-riber);
  font-size: 14px;
  align-items: center;
  animation: ${goLeft} 1.2s;
  z-index: 99;

  a {
    color: var(--roxo-riber);
    display: flex;
    flex-direction: row;
    font-family: 'OpenSans' sans-serif;
    font-size: 14px;
    font-weight: 700;
    align-items: center;
    column-gap: 15px;
    padding: 9px 9px 9px 13px;
    cursor: pointer;
    z-index: 99;
    text-decoration: none;

    &.active {
      border-left: 4px solid var(--roxo-riber);
      padding: 9px;
    }

    & .icon {
      height: 20px;
      width: 20px;
    }
  }

  @media (max-width: 992px) {
    min-width: 200px;
    left: ${(props) => (props.toggle ? '10px' : 'calc(-200px - 40px)')};
    transition: all 1.2s;
  }
`

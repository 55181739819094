import { FormLogin, FormRecuperacao, TYPES } from './types'

export const login = () => ({
  type: TYPES.LOGIN_SAGA
})

export const logout = () => ({
  type: TYPES.LOGOUT_SAGA
})

type LoginFields = keyof FormLogin
export const setLogin = (value: string | boolean, field: LoginFields) => ({
  type: TYPES.SET_LOGIN,
  value,
  field
})

export const cleanLogin = () => ({
  type: TYPES.CLEAN_LOGIN
})

export const setLoading = (value: boolean) => ({
  type: TYPES.SET_LOADING,
  value
})

export const recuperarAcessoSaga = () => ({
  type: TYPES.RECUPERAR_ACESSO_SAGA
})

type RecuperacaoFields = keyof FormRecuperacao
export const setRecuperacao = (value: string, field: RecuperacaoFields) => ({
  type: TYPES.SET_RECUPERACAO,
  value,
  field
})

export const validaGuidRecuperacaoSaga = (id: string) => ({
  type: TYPES.VALIDA_GUID_RECUPERACAO_SAGA,
  id
})

export const redefinirSenhaSaga = (id: string) => ({
  type: TYPES.REDEFINIR_SENHA_SAGA,
  id
})

export const getStatusLeadSaga = () => ({
  type: TYPES.GET_STATUS_LEAD_SAGA
})

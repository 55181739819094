import { toast } from 'react-toastify'
import { Indicacao } from 'models/types'

export function handlerErrorSimulacaoFgts(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  defaultMessage?: string,
  autoClose?: number | false | undefined
) {
  if (error?.messages && !error?.stack) {
    error?.messages.map((e: Indicacao) => {
      toast.warn('FGTS: ' + e?.mensagem, {
        bodyClassName: 'text-break',
        autoClose: autoClose
      })
    })
  } else {
    defaultMessage
      ? toast.warn(defaultMessage, {
          bodyClassName: 'text-break',
          autoClose: autoClose
        })
      : toast.warn('FGTS: Não foi possível!', {
          bodyClassName: 'text-break',
          autoClose: autoClose
        })
  }
}

import React from 'react'
import logoBranca from 'assets/images/logo-branca.png'
import { FiFacebook, FiInstagram, FiPhone, FiYoutube } from 'react-icons/fi'
import { FaLinkedinIn, FaWhatsapp } from 'react-icons/fa'
import { BsChatSquare } from 'react-icons/bs'
import { Button, Col, Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { LANDING_PAGE } from 'store/modules/landingPage/types'
import { useSelector } from 'react-redux'
import * as landingPageActions from 'store/modules/landingPage/actions'
import useRedux from 'hooks/useRedux'
import ModalPdf from './Modals/ModalPdf'

const Footer = () => {
  const { downloads, loadingDownloads, modalPdf } = useSelector<
    ApplicationState,
    LANDING_PAGE
  >((state) => state.landingPage)

  const { dispatch } = useRedux()

  return (
    <footer>
      <div className="redes-sociais">
        <div>
          <img src={logoBranca} alt="Logo Riber" />
        </div>
        <div className="d-flex justify-content-between">
          <a
            target="_blank"
            href="https://www.facebook.com/ribercred/"
            rel="noreferrer"
          >
            <FiFacebook size={24} />
          </a>
          <a
            target="_blank"
            className="youtube"
            href="https://www.youtube.com/channel/UCBq11uE1in7u3-NeIiJxDng/featured"
            rel="noreferrer"
          >
            <FiYoutube size={24} />
          </a>
          <a
            target="_blank"
            className="instagram"
            href="https://www.instagram.com/riberbr/"
            rel="noreferrer"
          >
            <FiInstagram size={24} />
          </a>
          <a
            target="_blank"
            className="linkedin"
            href="https://br.linkedin.com/company/riber-servicos-financeiros"
            rel="noreferrer"
          >
            <FaLinkedinIn size={24} />
          </a>
        </div>
      </div>
      <div className="container-fluid">
        <Row className="contato">
          <Col
            md={4}
            className="d-flex justify-content-center align-items-center gap-3"
          >
            <FaWhatsapp size={30} />
            <span>Fale conosco por Whatsapp 0800 941 1111</span>
          </Col>
          <Col
            md={4}
            className="d-flex justify-content-center align-items-center gap-3"
          >
            <FiPhone size={30} />
            <span>SAC: sac@ribercred.com.br</span>
          </Col>
          <Col md={4}>
            <a
              className="d-flex justify-content-center align-items-center gap-3"
              href="https://forms.office.com/r/i704jS6xwn"
            >
              <BsChatSquare size={30} />
              <span>Ouvidoria</span>
            </a>
          </Col>
        </Row>
      </div>
      <div className="informacoes">
        <span>
          © {new Date().getFullYear()} Riber - A plataforma Riber não realiza
          análise de crédito e a liberação do valor na conta do cliente, esta
          responsabilidade é única e exclusivamente das instituições financeiras
          parceiras. A aprovação de créditos consignados descritos nesta
          plataforma está sujeita a validação de margem consignável e a
          averbação por parte do órgão pagador, já a aprovação do crédito
          referente ao produto antecipação do FGTS, está sujeito a
          disponibilidade do saldo fornecido pela Caixa Econômica Federal e as
          políticas das instituições financeiras parceiras. Embora a Riber
          trabalhe para democratizar o acesso ao crédito nas melhores condições
          e taxas de juros praticadas no empréstimo consignado e no cartão de
          crédito consignado, referidas condições são determinadas pelas
          instituições financeiras parceiras, as quais deverão ser confirmadas e
          exibidas antes de qualquer contratação.
        </span>
        <span>Todo crédito deve ser utilizado de forma consciente.</span>
      </div>
      <div className="informacoes">
        <span>
          A Ribercred, com sede à Avenida Antônio Diederichesen, nº. 400, sala
          16, Jardim América, CEP: 14020-250, Ribeirão Preto, Estado de São
          Paulo, inscrita no CNPJ sob o nº. 04.380.132/0001-47, atua como
          correspondente bancário, atividade regulada pelo Banco Central do
          Brasil, nos termos da Resolução nº 3.954 das instituições financeiras:
          Banco Itaú Consignado S/A, Banco BMG S/A, Banco Daycoval S/A, Banco
          Master S/A, Banco Pan S/A, Franqueados da Help! Franchising, Banco
          Bradesco Financiamentos, Creditas Soluções Financeiras LTDA, Crefaz
          Scm EPP LTDA, Vctex Correspondente Bancário e Meios de Pagamentos
          LTDA, Icred Soluções Financeiras S/A, BV Financeira S/A, Banco
          Santander (Brasil) S/A, Banco Mercantil do Brasil S/A, Start Serviços
          Administrativos LTDA, Banco Paulista S/A.
        </span>
      </div>
      <div className="copyright">
        <Button
          onClick={() => {
            dispatch(landingPageActions.getAvisoPrivacidadeSaga())
          }}
          color="transparent"
        >
          AVISO DE PRIVACIDADE
        </Button>
        <Button
          onClick={() => {
            dispatch(landingPageActions.getPoliticaPrivacidadeSaga())
          }}
          color="transparent"
        >
          POLÍTICA DE PRIVACIDADE
        </Button>
        <Button
          onClick={() => {
            dispatch(landingPageActions.getTermoPoliticaSaga())
          }}
          color="transparent"
        >
          TERMO DE POLÍTICA E USO
        </Button>
      </div>
      <ModalPdf
        isOpen={modalPdf}
        toggle={() => {
          dispatch(landingPageActions.setModalPdf(false))
        }}
        pdf={downloads.pdf}
        loading={loadingDownloads.pdf}
      />
    </footer>
  )
}

export default Footer

import { Reducer } from 'redux'
import { CADASTRO_INICIAL, TYPES } from './types'

const INITIAL_STATE: CADASTRO_INICIAL = {
  formulario: {
    nome: '',
    cpf: '',
    dataNascimento: '',
    celular: '',
    email: '',
    politicaCompartilhamento: false
  },
  invalidFormulario: {
    nome: {
      message: '',
      invalid: false
    },
    cpf: {
      message: '',
      invalid: false
    },
    dataNascimento: {
      message: '',
      invalid: false
    },
    celular: {
      message: '',
      invalid: false
    },
    email: {
      message: '',
      invalid: false
    },
    politicaCompartilhamento: {
      message: '',
      invalid: false
    }
  },
  modalSenha: {
    senha: '',
    confirmacaoSenha: ''
  },
  invalidModalSenha: {
    senha: {
      message: '',
      invalid: false
    },
    confirmacaoSenha: {
      message: '',
      invalid: false
    }
  },
  showModalSenha: false,
  avisoPrivacidadeBase64: '',
  loadingAvisoPrivacidade: false,
  modalAvisoPrivacidade: false
}

const reducer: Reducer<CADASTRO_INICIAL> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_FORMULARIO:
      return {
        ...state,
        formulario: {
          ...state.formulario,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_FORMULARIO:
      return {
        ...state,
        formulario: INITIAL_STATE.formulario
      }
    case TYPES.SET_INVALID_FORMULARIO:
      return {
        ...state,
        invalidFormulario: {
          ...state.invalidFormulario,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_INVALIDS_FORMULARIO:
      return {
        ...state,
        invalidFormulario: INITIAL_STATE.invalidFormulario
      }
    case TYPES.SET_MODAL_SENHA_FIELD:
      return {
        ...state,
        modalSenha: {
          ...state.modalSenha,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_MODAL_SENHA:
      return {
        ...state,
        modalSenha: INITIAL_STATE.modalSenha
      }
    case TYPES.SET_INVALID_MODAL_SENHA_FIELD:
      return {
        ...state,
        invalidModalSenha: {
          ...state.invalidModalSenha,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_INVALIDS_MODAL_SENHA:
      return {
        ...state,
        invalidModalSenha: INITIAL_STATE.invalidModalSenha
      }
    case TYPES.SET_SHOW_MODAL_SENHA:
      return {
        ...state,
        showModalSenha: action.value
      }
    case TYPES.SET_AVISO_PRIVACIDADE:
      return {
        ...state,
        avisoPrivacidadeBase64: action.value
      }
    case TYPES.SET_LOADING_AVISO_PRIVACIDADE:
      return {
        ...state,
        loadingAvisoPrivacidade: action.value
      }
    case TYPES.SET_SHOW_MODAL_AVISO_PRIVACIDADE:
      return {
        ...state,
        modalAvisoPrivacidade: action.value
      }
    default:
      return state
  }
}

export default reducer

import { Cidade, Estado, Invalid } from 'models/types'

export enum TYPES {
  SET_FORMULARIO = '@@dadosComplementaresEndereco/SET_FORMULARIO',
  SET_FORMULARIO_FIELD = '@@dadosComplementaresEndereco/SET_FORMULARIO_FIELD',
  CLEAN_FORMULARIO = '@@dadosComplementaresEndereco/CLEAN_FORMULARIO',
  SET_INVALID_FORMULARIO = '@@dadosComplementaresEndereco/SET_INVALID_FORMULARIO',
  CLEAN_INVALIDS_FORMULARIO = '@@dadosComplementaresEndereco/CLEAN_INVALIDS_FORMULARIO',
  SET_OPTIONS_SELECT = '@@dadosComplementaresEndereco/SET_OPTIONS_SELECT',
  SET_LOADING_SELECTS = '@@dadosComplementaresEndereco/SET_LOADING_SELECTS',
  SET_CPF = '@@dadosComplementaresEndereco/SET_CPF',

  GET_DADOS_COMPLEMENTARES_ENDERECO_SAGA = '@@dadosComplementaresEndereco/GET_DADOS_COMPLEMENTARES_ENDERECO_SAGA',
  SALVAR_CADASTRO_SAGA = '@@dadosComplementaresEndereco/SALVAR_CADASTRO_SAGA',
  GET_CIDADES_SAGA = '@@dadosComplementaresEndereco/GET_CIDADES_SAGA',
  GET_ESTADOS_SAGA = '@@dadosComplementaresEndereco/GET_ESTADOS_SAGA',
  GET_DADOS_ENDERECO_SAGA = '@@dadosComplementaresEndereco/GET_DADOS_ENDERECO_SAGA',
  GET_CPF_SAGA = '@@dadosComplementaresEndereco/GET_CPF_SAGA'
}

export interface DADOS_COMPLEMENTARES_ENDERECO {
  cpf: string
  formulario: FormCadastro
  invalidFormulario: InvalidFormCadastro
  optionsSelects: OptionsSelects
  loadingSelects: LoadingSelects
}

interface FormCadastro {
  cep: string
  siglaEstado: string
  cidade: string
  bairro: string
  logradouro: string
  numero: string
  complemento: string
}

type InvalidFormCadastro = {
  [K in keyof FormCadastro]: Invalid
}

interface OptionsSelects {
  cidades: Cidade[]
  estados: Estado[]
}

type LoadingSelects = {
  [k in keyof OptionsSelects]: boolean
}

import { APICore } from 'helpers/api/apiCore'
import { DadosComplementaresDocumentoReq } from 'models/cadastros/dadosComplementaresDocumento/types'

const api = new APICore()

export function getDadosComplementaresDocumento(cpf: string) {
  return api.get(`DadosComplementares/dados-documento`, {
    cpf
  })
}

type Cadastro = DadosComplementaresDocumentoReq & {
  cpf: string
}
export function salvarCadastro(params: Cadastro) {
  return api.update(`DadosComplementares/dados-documento`, params)
}

export function getOrgaosEmissores() {
  return api.get(`Generic/orgaos-emissores`, null)
}

export function getEstados() {
  return api.get(`Generic/estados`, null)
}

export function getCpf() {
  return api.get(`Generic/informacoes-cliente-matricula`, null)
}

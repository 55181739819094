import { TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as loadingActions from 'store/modules/loading/actions'
import * as dadosComplementaresEnderecoController from 'controller/dadosComplementaresEnderecoController'
import * as dadosComplementaresEnderecoActions from 'store/modules/cadastros/dadosComplementaresEndereco/actions'
import { DADOS_COMPLEMENTARES_ENDERECO } from './types'
import { ApplicationState } from 'store'
import { handlerError } from 'util/handlerError'
import { toast } from 'react-toastify'
import { maskCEP, removeMaskCEP, removeMaskCPF } from 'util/masks'
import { stepCadastroComplementarRedirect } from 'util/stepCadastroComplementarRedirect'
import * as simulacaoActions from 'store/modules/simulacao/actions'

interface GenericProps {
  type: string
}

function* getDadosComplementaresEndereco(): SagaIterator {
  const { cpf }: DADOS_COMPLEMENTARES_ENDERECO = yield select(
    (state: ApplicationState) => state.dadosComplementaresEndereco
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpf)
    const response = yield call(
      dadosComplementaresEnderecoController.getDadosComplementaresEndereco,
      cpfFormatted
    )
    yield put(
      dadosComplementaresEnderecoActions.setFormulario({
        bairro: response?.data?.content.bairro,
        cep: maskCEP(response?.data?.content.cep),
        cidade: response?.data?.content.cidade,
        complemento: response?.data?.content.complemento,
        logradouro: response?.data?.content.logradouro,
        numero: response?.data?.content.numero,
        siglaEstado: response?.data?.content.siglaEstado
      })
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* salvarCadastro(): SagaIterator {
  const { formulario, cpf }: DADOS_COMPLEMENTARES_ENDERECO = yield select(
    (state: ApplicationState) => state.dadosComplementaresEndereco
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpf)
    const response = yield call(
      dadosComplementaresEnderecoController.salvarCadastro,
      {
        cpf: cpfFormatted,
        bairro: formulario.bairro,
        cep: formulario.cep,
        cidade: formulario.cidade,
        complemento: formulario.complemento,
        logradouro: formulario.logradouro,
        numero: formulario.numero,
        siglaEstado: formulario.siglaEstado
      }
    )
    yield put(dadosComplementaresEnderecoActions.cleanFormulario())
    toast.success('Informações salvas com sucesso!')
    yield put(simulacaoActions.validaStatusIN100Saga())
    stepCadastroComplementarRedirect(
      response?.data?.content.dadosComplementaresEnum
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getEstados(): SagaIterator {
  try {
    yield put(
      dadosComplementaresEnderecoActions.setLoadingSelect(true, 'estados')
    )
    const response = yield call(
      dadosComplementaresEnderecoController.getEstados
    )
    yield put(
      dadosComplementaresEnderecoActions.setOptionsSelect(
        response?.data?.content,
        'estados'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresEnderecoActions.setLoadingSelect(false, 'estados')
    )
  }
}

interface GetCidadesProps extends GenericProps {
  siglaEstado: string
}

function* getCidades({ siglaEstado }: GetCidadesProps): SagaIterator {
  try {
    yield put(
      dadosComplementaresEnderecoActions.setLoadingSelect(true, 'cidades')
    )
    const response = yield call(
      dadosComplementaresEnderecoController.getCidades,
      siglaEstado
    )
    yield put(
      dadosComplementaresEnderecoActions.setOptionsSelect(
        response?.data?.content,
        'cidades'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresEnderecoActions.setLoadingSelect(false, 'cidades')
    )
  }
}

function* getDadosEndereco(): SagaIterator {
  const { formulario }: DADOS_COMPLEMENTARES_ENDERECO = yield select(
    (state: ApplicationState) => state.dadosComplementaresEndereco
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cepFormatted = removeMaskCEP(formulario.cep)
    const response = yield call(
      dadosComplementaresEnderecoController.getDadosEndereco,
      cepFormatted
    )
    yield put(
      dadosComplementaresEnderecoActions.setFormularioField(
        response?.data?.content.uf,
        'siglaEstado'
      )
    )
    yield put(
      dadosComplementaresEnderecoActions.setFormularioField(
        response?.data?.content.logradouro,
        'logradouro'
      )
    )
    yield put(
      dadosComplementaresEnderecoActions.setFormularioField(
        response?.data?.content.bairro,
        'bairro'
      )
    )
    yield put(
      dadosComplementaresEnderecoActions.setFormularioField(
        response?.data?.content.localidade?.toUpperCase(),
        'cidade'
      )
    )
  } catch (error) {
    yield put(dadosComplementaresEnderecoActions.cleanFormulario())
    handlerError(error, 'Não foi possível buscar os dados de endereço!')
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getCpf(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(dadosComplementaresEnderecoController.getCpf)
    yield put(
      dadosComplementaresEnderecoActions.setCpf(response?.data?.content.cpf)
    )
    yield put(
      dadosComplementaresEnderecoActions.getDadosComplementaresEnderecoSaga()
    )
  } catch (error) {
    handlerError(
      error,
      'Não foi possível obter CPF. Tente novamente mais tarde'
    )
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetDadosComplementaresEndereco() {
  yield takeEvery(
    TYPES.GET_DADOS_COMPLEMENTARES_ENDERECO_SAGA,
    getDadosComplementaresEndereco
  )
}

export function* watchSalvarCadastro() {
  yield takeEvery(TYPES.SALVAR_CADASTRO_SAGA, salvarCadastro)
}

export function* watchGetEstados() {
  yield takeEvery(TYPES.GET_ESTADOS_SAGA, getEstados)
}

export function* watchGetCidades() {
  yield takeEvery(TYPES.GET_CIDADES_SAGA, getCidades)
}

export function* watchGetDadosEndereco() {
  yield takeEvery(TYPES.GET_DADOS_ENDERECO_SAGA, getDadosEndereco)
}

export function* watchGetCpfSaga() {
  yield takeEvery(TYPES.GET_CPF_SAGA, getCpf)
}

function* dadosComplementaresEndereco() {
  yield all([
    fork(watchGetDadosComplementaresEndereco),
    fork(watchSalvarCadastro),
    fork(watchGetEstados),
    fork(watchGetCidades),
    fork(watchGetDadosEndereco),
    fork(watchGetCpfSaga)
  ])
}

export default dadosComplementaresEndereco

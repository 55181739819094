import React, { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Collapse } from 'reactstrap'
import { CardFaq, FaqWrapper } from '../style'
import imageFaq from 'assets/images/apresentacao/creditoConsignado4.png'

const FaqCreditoConsignado = () => {
  const [cardsState, setCardsState] = useState({
    firstCard: false,
    secondCard: false,
    thirdCard: false,
    fourthCard: false,
    fifthCard: false,
    sixthCard: false,
    seventhCard: false,
    eighthCard: false,
    ninthCard: false,
    tenthCard: false
  })

  const toggleCardVisibility = (cardName: keyof typeof cardsState) => {
    setCardsState((prevCards) => ({
      ...prevCards,
      [cardName]: !prevCards[cardName]
    }))
  }

  return (
    <FaqWrapper backgroundImage={imageFaq}>
      <div className="d-none d-lg-flex">
        <img className="img-faq" src={imageFaq} />
      </div>
      <div className="faq">
        <h3>Perguntas Frequentes</h3>
        <CardFaq>
          <label>
            Quem pode solicitar um empréstimo consignado INSS
            {cardsState.firstCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('firstCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('firstCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.firstCard}>
            <p>
              Aposentados e pensionistas do INSS podem solicitar empréstimo
              consignado.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Qual é a margem consignável e como é calculada?
            {cardsState.secondCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('secondCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('secondCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.secondCard}>
            <p>
              A margem consignável é a porcentagem do benefício destinada ao
              pagamento das parcelas. Geralmente, 30% para empréstimos e 5% para
              cartão de crédito consignado.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Como é feito o desconto das parcelas?
            {cardsState.thirdCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('thirdCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('thirdCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.thirdCard}>
            <p>
              As parcelas são descontadas diretamente do benefício do INSS,
              antes do pagamento ao segurado.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            É possível fazer mais de um empréstimo consignado ao mesmo tempo?
            {cardsState.fourthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('fourthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('fourthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.fourthCard}>
            <p>
              Sim, desde que a soma dos descontos não ultrapasse a margem
              consignável disponível.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Quais documentos são necessários para solicitar o empréstimo?
            {cardsState.fifthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('fifthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('fifthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.fifthCard}>
            <p>
              Documento de identidade, CPF, comprovante de residência e o último
              extrato do benefício do INSS.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Qual é a taxa de juros praticada nos empréstimos consignados INSS?
            {cardsState.sixthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('sixthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('sixthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.sixthCard}>
            <p>
              As taxas de juros são definidas pelos bancos, mas geralmente são
              mais baixas em comparação a outros tipos de empréstimos.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Existe a possibilidade de antecipar o pagamento do empréstimo?
            {cardsState.seventhCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('seventhCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('seventhCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.seventhCard}>
            <p>
              Sim, muitas instituições permitem a antecipação do pagamento, e
              isso pode resultar em descontos nos juros.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            O que acontece em caso de atraso no pagamento?
            {cardsState.eighthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('eighthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('eighthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.eighthCard}>
            <p>
              O atraso pode gerar multas e juros, além de impactar a relação de
              crédito do solicitante.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            O empréstimo consignado INSS pode ser solicitado online?
            {cardsState.ninthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('ninthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('ninthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.ninthCard}>
            <p>
              Sim, você pode solicitar seu empréstimo online aqui no nosso site
              ou através do nosso 0800 941 1111
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Quais são os cuidados ao contratar um empréstimo consignado?
            {cardsState.tenthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('tenthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('tenthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.tenthCard}>
            <p>
              Verificar as taxas de juros, entender as condições contratuais e
              garantir que as parcelas cabem no orçamento são precauções
              importantes.
            </p>
          </Collapse>
        </CardFaq>
      </div>
    </FaqWrapper>
  )
}

export default FaqCreditoConsignado

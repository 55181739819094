import styled from 'styled-components'

export const NotFoundWrapper = styled.div`
  background-color: var(--branco);
  display: flex;
  flex-direction: column;
  height: 100vh;

  & .btn-logout {
    align-self: flex-end;
    border-radius: 5px;
    display: flex;
    justify-self: flex-end;
    margin: 3px;
    padding: 5px 10px;
    transition: background 0.3s;
    width: fit-content;

    &:hover {
      background: #cecece;
    }
  }

  & .image-not-found {
    align-self: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    row-gap: 15px;
    height: 100%;
  }

  & img {
    width: 100%;
    max-width: 600px;
  }

  & h2 {
    text-align: center;
    color: #adb5bd;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
  }
`

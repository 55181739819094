import { Reducer } from 'redux'
import { PROFISSIONAL, TYPES } from './types'

const INITIAL_STATE: PROFISSIONAL = {
  tipoPerfil: null,
  optionsSelect: {
    perfis: [],
    cidades: [],
    estados: [],
    convenios: []
  },
  loadingOptionsSelect: {
    perfis: false,
    cidades: false,
    estados: false,
    convenios: false
  },
  cadastroAposentado: {
    matricula: '',
    salario: '',
    siglaEstado: '',
    cidadeId: ''
  },
  cadastroAposentadoInvalids: {
    matricula: {
      invalid: false,
      message: ''
    },
    salario: {
      invalid: false,
      message: ''
    },
    siglaEstado: {
      invalid: false,
      message: ''
    },
    cidadeId: {
      invalid: false,
      message: ''
    }
  },
  cadastroServidor: {
    matricula: '',
    salario: '',
    siglaEstado: '',
    cidadeId: '',
    convenioId: '',
    margemEmprestimoConsignado: '',
    margemCartaoConsignado: '',
    margemCartaoBeneficio: '',
    servidoresPublicoEnum: null
  },
  cadastroServidorInvalids: {
    matricula: {
      invalid: false,
      message: ''
    },
    salario: {
      invalid: false,
      message: ''
    },
    siglaEstado: {
      invalid: false,
      message: ''
    },
    cidadeId: {
      invalid: false,
      message: ''
    },
    convenioId: {
      invalid: false,
      message: ''
    },
    margemEmprestimoConsignado: {
      invalid: false,
      message: ''
    },
    margemCartaoConsignado: {
      invalid: false,
      message: ''
    },
    margemCartaoBeneficio: {
      invalid: false,
      message: ''
    },
    servidoresPublicoEnum: {
      invalid: false,
      message: ''
    }
  },
  cadastroClt: {
    cidadeId: '',
    salario: '',
    siglaEstado: ''
  },
  cadastroCltInvalids: {
    cidadeId: {
      invalid: false,
      message: ''
    },
    salario: {
      invalid: false,
      message: ''
    },
    siglaEstado: {
      invalid: false,
      message: ''
    }
  }
}

const reducer: Reducer<PROFISSIONAL> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_TIPO_PERFIL:
      return { ...state, tipoPerfil: action.value }
    case TYPES.SET_OPTIONS_SELECT:
      return {
        ...state,
        optionsSelect: {
          ...state.optionsSelect,
          [action.field]: action.value
        }
      }
    case TYPES.SET_CADASTRO_APOSENTADO:
      return {
        ...state,
        cadastroAposentado: {
          ...state.cadastroAposentado,
          [action.field]: action.value
        }
      }
    case TYPES.SET_CADASTRO_APOSENTADO_INVALIDS:
      return {
        ...state,
        cadastroAposentadoInvalids: {
          ...state.cadastroAposentadoInvalids,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_CADASTRO_APOSENTADO_INVALIDS:
      return {
        ...state,
        cadastroAposentadoInvalids: INITIAL_STATE.cadastroAposentadoInvalids
      }
    case TYPES.CLEAN_CADASTRO_APOSENTADO:
      return {
        ...state,
        cadastroAposentado: INITIAL_STATE.cadastroAposentado
      }
    case TYPES.SET_CADASTRO_SERVIDOR:
      return {
        ...state,
        cadastroServidor: {
          ...state.cadastroServidor,
          [action.field]: action.value
        }
      }
    case TYPES.SET_CADASTRO_SERVIDOR_INVALIDS:
      return {
        ...state,
        cadastroServidorInvalids: {
          ...state.cadastroServidorInvalids,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_CADASTRO_SERVIDOR_INVALIDS:
      return {
        ...state,
        cadastroServidorInvalids: INITIAL_STATE.cadastroServidorInvalids
      }
    case TYPES.CLEAN_CADASTRO_SERVIDOR:
      return {
        ...state,
        cadastroServidor: INITIAL_STATE.cadastroServidor
      }
    case TYPES.SET_CADASTRO_CLT:
      return {
        ...state,
        cadastroClt: {
          ...state.cadastroClt,
          [action.field]: action.value
        }
      }
    case TYPES.SET_CADASTRO_CLT_INVALIDS:
      return {
        ...state,
        cadastroCltInvalids: {
          ...state.cadastroCltInvalids,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_CADASTRO_CLT_INVALIDS:
      return {
        ...state,
        cadastroCltInvalids: INITIAL_STATE.cadastroCltInvalids
      }
    case TYPES.CLEAN_CADASTRO_CLT:
      return {
        ...state,
        cadastroClt: INITIAL_STATE.cadastroClt
      }
    case TYPES.SET_LOADING_OPTIONS_SELECT:
      return {
        ...state,
        loadingOptionsSelect: {
          ...state.loadingOptionsSelect,
          [action.field]: action.value
        }
      }
    default:
      return state
  }
}

export default reducer

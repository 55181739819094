import React from 'react'
import loading from 'assets/svg/loading-icon.svg'
import { LoadingWrapper } from './style'

interface LoadingComponentProps {
  mainText?: string
  secondaryText?: string
  loadingIcon?: React.ReactElement
  isLoading: boolean
  children?: React.ReactNode
}

const LoadingComponent = ({
  mainText,
  secondaryText,
  loadingIcon,
  isLoading,
  children
}: LoadingComponentProps) => {
  return (
    <>
      {isLoading ? (
        <LoadingWrapper>
          <div>{mainText}</div>
          <div>{secondaryText}</div>
          {loadingIcon ? (
            loadingIcon
          ) : (
            <img
              className="my-4"
              src={loading}
              alt="Loading sistema"
              width="100"
            />
          )}
        </LoadingWrapper>
      ) : (
        children
      )}
    </>
  )
}

export default LoadingComponent

import { Reducer } from 'redux'
import { LOGIN, TYPES } from './types'

const INITIAL_STATE: LOGIN = {
  login: {
    email: '',
    senha: '',
    lembrar: false
  },
  recuperacao: {
    senha: '',
    confirmacaoSenha: ''
  },
  loading: false
}

const reducer: Reducer<LOGIN> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_LOGIN:
      return {
        ...state,
        login: { ...state.login, [action.field]: action.value }
      }
    case TYPES.CLEAN_LOGIN:
      return {
        ...state,
        login: INITIAL_STATE.login
      }
    case TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.value
      }
    case TYPES.SET_RECUPERACAO:
      return {
        ...state,
        recuperacao: { ...state.recuperacao, [action.field]: action.value }
      }
    default:
      return state
  }
}

export default reducer

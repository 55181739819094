import React, { Fragment } from 'react'
import SimpleModal from 'components/SimpleModal'
import { Perfil } from 'models/types'
import { SimpleTitleCard } from 'styles/global'
import { CgProfile } from 'react-icons/cg'
import LoadingComponent from 'components/Loading/LoadingComponent'
import { ContentPerfisSelecao } from '../style'

interface EscolhaPerfilProps {
  isOpen: boolean
  onToggle: () => void
  onAdicionar: (e: Perfil) => void
  perfis: Perfil[]
  isLoading?: boolean
}

const EscolhaPerfil = ({
  isOpen,
  onToggle,
  onAdicionar,
  perfis,
  isLoading
}: EscolhaPerfilProps) => {
  return (
    <SimpleModal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        onToggle()
      }}
      header={
        <SimpleTitleCard>
          <CgProfile className="icon" />
          Escolha de perfil
        </SimpleTitleCard>
      }
    >
      <ContentPerfisSelecao>
        <LoadingComponent isLoading={!!isLoading}>
          {perfis.map((perfil, i) => {
            return (
              <Fragment key={i}>
                <div
                  onClick={() => {
                    onAdicionar(perfil)
                    onToggle()
                  }}
                  className="itens-lista"
                >
                  <span>{perfil.descricao}</span>
                </div>
                <div className="linha-horizontal" />
              </Fragment>
            )
          })}
        </LoadingComponent>
      </ContentPerfisSelecao>
    </SimpleModal>
  )
}

export default EscolhaPerfil

import React from 'react'
import SimpleModal from 'components/SimpleModal'
import { MdOutlinePolicy } from 'react-icons/md'
import { SimpleTitleCard } from 'styles/global'
import { IframePdf } from '../style'
import { Pdf } from 'store/modules/landingPage/types'
import LoadingComponent from 'components/Loading/LoadingComponent'

interface ModalPdfProps {
  isOpen: boolean
  toggle: () => void
  pdf: Pdf
  loading: boolean
}

const ModalPdf = ({ isOpen, toggle, pdf, loading }: ModalPdfProps) => {
  return (
    <SimpleModal
      isOpen={isOpen}
      toggle={() => {
        toggle()
      }}
      dataBackdrop="static"
      size="xl"
      header={
        <SimpleTitleCard>
          <MdOutlinePolicy className="icon" />
          {pdf.nome}
        </SimpleTitleCard>
      }
    >
      <LoadingComponent
        isLoading={loading}
        mainText="Carregando..."
        secondaryText="Aguarde enquanto carregamos o PDF"
      >
        <IframePdf src={`data:application/pdf;base64,${pdf.base64}`} />
      </LoadingComponent>
    </SimpleModal>
  )
}

export default ModalPdf

import React, { useEffect, useState } from 'react'
import SimpleModal from 'components/SimpleModal'
import { Col, Row } from 'reactstrap'
import InputCustom from 'components/Inputs'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { CardInfoPurple } from 'views/simulacao/style'
import { maskPhoneWithDDD, removeMaskPhone } from 'util/masks'
import useRedux from 'hooks/useRedux'
import * as simulacaoActions from 'store/modules/simulacao/actions'
import { SIMULACAO } from 'store/modules/simulacao/types'
import { schemaCelular, schemaToken } from 'views/simulacao/constants/schemas'
import * as yup from 'yup'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import RadioMatricula from '../RadioMatricula'
import { GrValidate } from 'react-icons/gr'

interface ModalIN100 {
  isOpen: boolean
  toggle: () => void
}

const ModalIN100 = ({ isOpen, toggle }: ModalIN100) => {
  const { validacaoFields, validacaoFieldsInvalids, matriculasValidacao } =
    useSelector<ApplicationState, SIMULACAO>((state) => state.simulacao)
  const [beneficiosInss, setBeneficiosInss] = useState<string[]>([])

  const { dispatch } = useRedux()

  type ValidacaoFields = keyof SIMULACAO['validacaoFields']
  function onChange<T extends ValidacaoFields>(
    value: T extends keyof SIMULACAO['validacaoFields']
      ? SIMULACAO['validacaoFields'][T]
      : never,
    field: T
  ) {
    dispatch(simulacaoActions.setValidacaoFields(value, field))
  }

  useEffect(() => {
    if (matriculasValidacao.length) {
      setBeneficiosInss(matriculasValidacao)
      dispatch(
        simulacaoActions.setValidacaoFields(
          matriculasValidacao?.[0],
          'matricula'
        )
      )
    } else {
      setBeneficiosInss([])
      toggle()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matriculasValidacao])

  function reenviarSolicitacao() {
    dispatch(
      simulacaoActions.setValidacaoFieldsInvalids(
        {
          invalid: false,
          message: ''
        },
        'celular'
      )
    )
    schemaCelular
      .validate(
        {
          celular: removeMaskPhone(validacaoFields.celular)
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(simulacaoActions.inserirSolicitacoesIn100Saga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            simulacaoActions.setValidacaoFieldsInvalids(
              {
                invalid: true,
                message: e.message
              },
              'celular'
            )
          )
        })
      })
  }

  function validarToken() {
    dispatch(
      simulacaoActions.setValidacaoFieldsInvalids(
        {
          invalid: false,
          message: ''
        },
        'codigo'
      )
    )
    schemaToken
      .validate(
        {
          codigo: validacaoFields.codigo
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(simulacaoActions.validarTokenIn100Saga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            simulacaoActions.setValidacaoFieldsInvalids(
              {
                invalid: true,
                message: e.message
              },
              'codigo'
            )
          )
        })
      })
  }

  return (
    <SimpleModal
      toggle={() => toggle()}
      isOpen={isOpen}
      size="lg"
      header={
        <SimpleTitleCard>
          <GrValidate className="icon" />
          Validação INSS
        </SimpleTitleCard>
      }
      dataBackdrop="static"
    >
      <div className="container-fluid d-flex flex-column row-gap-20">
        <div>
          <div className="d-flex column-gap-15">
            {beneficiosInss.map((beneficio, i) => {
              return (
                <RadioMatricula
                  key={i}
                  matricula={beneficio}
                  index={i}
                  onChange={(e) => {
                    onChange(e.target.value, 'matricula')
                  }}
                  value={beneficio}
                  checked={beneficio === validacaoFields.matricula}
                />
              )
            })}
          </div>
          <hr />
        </div>
        <Row>
          <Col>
            <CardInfoPurple>
              Por favor, preencha o campo de número de celular e clique no botão
              &quot;Enviar código&quot; para receber o código de verificação. Se
              você não receber o código no seu aparelho celular, verifique se o
              número de telefone está correto. Se o problema persistir, você
              pode clicar novamente no botão &quot;Enviar código&quot;.
            </CardInfoPurple>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Celular*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskPhoneWithDDD(e.target.value), 'celular')
              }}
              value={maskPhoneWithDDD(validacaoFields.celular)}
              invalid={validacaoFieldsInvalids.celular.invalid}
              errorMessage={validacaoFieldsInvalids.celular.message}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <CustomButton
              background="transparent"
              color="var(--roxo-riber)"
              padding="20"
              height="50px"
              borderRadius={21}
              onClick={() => {
                reenviarSolicitacao()
              }}
            >
              Enviar código
            </CustomButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Insira o código aqui*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value.toUpperCase(), 'codigo')
              }}
              value={validacaoFields.codigo}
              invalid={validacaoFieldsInvalids.codigo.invalid}
              errorMessage={validacaoFieldsInvalids.codigo.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomButton
              background="var(--roxo-riber)"
              width="100%"
              padding="20"
              height="50px"
              borderRadius={21}
              onClick={() => {
                validarToken()
              }}
            >
              AVANÇAR
            </CustomButton>
          </Col>
        </Row>
      </div>
    </SimpleModal>
  )
}

export default ModalIN100

import React from 'react'
import classNames from 'classnames'
import { BannerWrapper, CardSimulacao } from '../style'
import { Col, Row } from 'reactstrap'
import InputCustom from 'components/Inputs'
import { CustomButton } from 'styles/global'
import * as landingPageActions from 'store/modules/landingPage/actions'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { LANDING_PAGE } from 'store/modules/landingPage/types'
import useRedux from 'hooks/useRedux'
import { schemaCpfProdutos } from 'views/landingPage/constants/schema'
import { maskCPF, removeMaskCPF } from 'util/masks'
import * as yup from 'yup'

interface BannerProps {
  title: string
  subtitle: string
  image: string
  imageCentered?: boolean
}

const Banner = ({ title, subtitle, image, imageCentered }: BannerProps) => {
  const { cpfProdutos, cpfProdutosInvalid } = useSelector<
    ApplicationState,
    LANDING_PAGE
  >((state) => state.landingPage)

  const { dispatch } = useRedux()

  function onChange(value: string) {
    dispatch(landingPageActions.setCpfProdutos(value))
  }

  function onIniciarProdutos() {
    dispatch(landingPageActions.cleanCpfProdutosInvalid())
    schemaCpfProdutos
      .validate(
        {
          cpf: removeMaskCPF(cpfProdutos)
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(landingPageActions.iniciarProdutosSaga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            landingPageActions.setCpfProdutosInvalid({
              invalid: true,
              message: e.message
            })
          )
        })
      })
  }
  return (
    <BannerWrapper image={image}>
      <div className="infos">
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
        <CardSimulacao>
          <div className="titulos">
            <label>Digite seu CPF</label>
            <span>e encontre a melhor oferta</span>
          </div>
          <div className="container-fluid">
            <Row className="w-100">
              <Col>
                <InputCustom
                  placeholder="000.000.000.00"
                  onChange={(e) => {
                    onChange(maskCPF(e.target.value))
                  }}
                  value={cpfProdutos}
                  invalid={cpfProdutosInvalid.invalid}
                  errorMessage={cpfProdutosInvalid.message}
                />
              </Col>
            </Row>
          </div>
          <CustomButton
            letterSpacing={2}
            background="var(--roxo-riber)"
            onClick={() => {
              onIniciarProdutos()
            }}
            width={300}
          >
            INICIAR SIMULAÇÃO
          </CustomButton>
        </CardSimulacao>
      </div>
      <div
        className={classNames(
          'img-banner',
          imageCentered && 'd-flex align-items-center justify-content-center'
        )}
      >
        <img className={classNames(!imageCentered && 'h-100')} src={image} />
      </div>
    </BannerWrapper>
  )
}

export default Banner

import React, { useEffect } from 'react'
import CustomSelect from 'components/Select'
import { Col, Row } from 'reactstrap'
import { RiberPertoWrapper } from '../style'
import Mapa from './Mapa'
import { ImMap2 } from 'react-icons/im'
import Locs from './Locs'
import * as landingPageActions from 'store/modules/landingPage/actions'
import useRedux from 'hooks/useRedux'
import { ApplicationState } from 'store'
import { LANDING_PAGE } from 'store/modules/landingPage/types'
import { useSelector } from 'react-redux'

const RiberPerto = () => {
  const {
    riberMaisPerto,
    invalidRiberMaisPerto,
    optionsSelects,
    loadingSelects,
    riberMaisPertoList
  } = useSelector<ApplicationState, LANDING_PAGE>((state) => state.landingPage)

  const { dispatch } = useRedux()

  type RiberMaisPertoFields = keyof LANDING_PAGE['riberMaisPerto']
  function onChange<T extends RiberMaisPertoFields>(
    value: T extends RiberMaisPertoFields
      ? LANDING_PAGE['riberMaisPerto'][T]
      : never,
    field: T
  ) {
    if (field === 'siglaEstado') {
      dispatch(landingPageActions.setRiberMaisPerto('', 'cidade'))
    }
    dispatch(landingPageActions.setRiberMaisPerto(value, field))
  }

  useEffect(() => {
    dispatch(landingPageActions.getEstadosSaga())
  }, [dispatch])

  useEffect(() => {
    if (riberMaisPerto.siglaEstado) {
      dispatch(landingPageActions.getCidadesSaga(riberMaisPerto.siglaEstado))
    }
  }, [dispatch, riberMaisPerto.siglaEstado])

  useEffect(() => {
    if (riberMaisPerto.siglaEstado && riberMaisPerto.cidade) {
      dispatch(landingPageActions.getRiberMaisPertoSaga())
    }
  }, [dispatch, riberMaisPerto.siglaEstado, riberMaisPerto.cidade])

  return (
    <RiberPertoWrapper className="container-fluid">
      <Row>
        <Col md={6}>
          <div>
            <Mapa
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAk33ngxpFcgxsijsw_rEPz9yyW_jaCTxs"
              containerElement={<div style={{ height: `600px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              loadingElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="d-flex flex-column row-gap-20 px-5">
            <ImMap2 className="icon" size={100} />
            <label className="title mb-5">
              Encontre uma <span>Riber</span> perto de você
            </label>
            <div>
              <label className="label-12">Escolha seu estado</label>
              <CustomSelect
                isLoading={loadingSelects.estados}
                options={optionsSelects.estados}
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'siglaEstado')
                }}
                value={riberMaisPerto.siglaEstado}
                accessorLabel="nome"
                accessorValue="sigla"
                invalid={invalidRiberMaisPerto.siglaEstado.invalid}
                errorMessage={invalidRiberMaisPerto.siglaEstado.message}
              />
              <label className="label-12">Escolha sua cidade</label>
              <CustomSelect
                isLoading={loadingSelects.cidades}
                options={optionsSelects.cidades}
                placeholder="Selecione"
                onChange={(e) => {
                  onChange(e, 'cidade')
                }}
                value={riberMaisPerto.cidade}
                accessorLabel="nome"
                accessorValue="nome"
                invalid={invalidRiberMaisPerto.cidade.invalid}
                errorMessage={invalidRiberMaisPerto.cidade.message}
              />
            </div>
            {riberMaisPertoList?.map((riber, i) => {
              return <Locs key={i} loc={riber.enderecoExtenso} />
            })}
          </div>
        </Col>
      </Row>
    </RiberPertoWrapper>
  )
}

export default RiberPerto

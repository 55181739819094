import { APICore } from 'helpers/api/apiCore'
import { DadosComplementaresInfoPessoalReq } from 'models/cadastros/dadosComplementaresInfoPessoal/types'

const api = new APICore()

export function getDadosComplementaresInfoPessoal(cpf: string) {
  return api.get(`DadosComplementares/dados-pessoais`, {
    cpf
  })
}

type Cadastro = DadosComplementaresInfoPessoalReq & {
  cpf: string
}
export function salvarCadastro(params: Cadastro) {
  return api.update(`DadosComplementares/dados-pessoais`, params)
}

export function getEstados() {
  return api.get(`Generic/estados`, null)
}

export function getCidades(siglaEstado: string) {
  return api.get(`Generic/cidades`, {
    siglaEstado
  })
}

export function getEstadosCivis() {
  return api.get(`Generic/estados-civis`, null)
}

export function getProfissoes() {
  return api.get(`Generic/profissoes`, null)
}

export function getEscolaridades() {
  return api.get(`Generic/escolaridades`, null)
}

export function getGeneros() {
  return api.get(`Generic/generos`, null)
}

export function getCpf() {
  return api.get(`Generic/informacoes-cliente-matricula`, null)
}

import { APICore } from 'helpers/api/apiCore'
import {
  RequestCadastroAposentadoDto,
  RequestCadastroCltDto,
  RequestCadastroServidorPublicoDto
} from 'models/cadastros/profissional/types'

const api = new APICore()

export function getPerfis() {
  return api.get(`Generic/perfis`, null)
}

export function getEstados() {
  return api.get(`Generic/estados`, null)
}

export function getCidades(siglaEstado: string) {
  return api.get(`Generic/cidades`, {
    siglaEstado
  })
}

export function getConvenios() {
  return api.get(`Generic/convenios`, null)
}

export function cadastroAposentado(params: RequestCadastroAposentadoDto) {
  return api.create(`CadastroCliente/aposentado`, params)
}

export function cadastroServidor(params: RequestCadastroServidorPublicoDto) {
  return api.create(`CadastroCliente/servidor`, params)
}

export function cadastroClt(params: RequestCadastroCltDto) {
  return api.create(`CadastroCliente/clt`, params)
}

import React from 'react'
import Breadcrumbs from 'components/Breadcrumbs'
import BlurLoading from 'components/Loading/BlurLoading'
import Navbar from 'components/Navbar'
import Sidebar from 'components/Sidebar'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { LOADING } from 'store/modules/loading/types'
import { CorpoConteudo } from './style'

type Props = {
  children?: React.ReactNode
  titleBreadcrumbs?: string
  subtitleBreadcrumbs?: string
}

function Main({ children, titleBreadcrumbs, subtitleBreadcrumbs }: Props) {
  const { isLoading } = useSelector<ApplicationState, LOADING>(
    (state) => state.loading
  )

  return (
    <>
      <BlurLoading isLoading={isLoading} />
      <Navbar />
      <Breadcrumbs title={titleBreadcrumbs} subtitle={subtitleBreadcrumbs} />
      <Sidebar />
      <CorpoConteudo>{children}</CorpoConteudo>
    </>
  )
}

export default Main

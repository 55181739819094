import { maskDate, maskMoney } from 'util/masks'
import { GridColDef } from '@mui/x-data-grid'
import {
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid/models'
import { EnumServicos } from 'models/enums'

const headerTheme = 'table-header--lines'

export const COLUMNS_RESUMO_PRODUTOS: GridColDef[] = [
  {
    field: 'servico',
    headerName: 'Produto',
    valueGetter: (params: GridValueGetterParams) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      `${EnumServicos[params.row.servico] || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'banco',
    headerName: 'Banco',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'matricula',
    headerName: 'Benefício',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'valorTotalFinanciado',
    headerName: 'Total',
    valueGetter: (params: GridValueGetterParams) =>
      `${
        params.row.servico === 5 ||
        params.row.servico === 6 ||
        params.row.servico === 7 ||
        params.row.servico === 8 ||
        params.row.servico === 12
          ? params.row.flagSaqueAutorizado || params.row.flagSaqueParcelado
            ? params.row.valorSaque
            : '-' || ''
          : maskMoney(params.row.valorTotalFinanciado?.toFixed(2)) || ''
      }`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'qtdeParcelas',
    headerName: 'Parcelas',
    headerClassName: headerTheme,
    valueGetter: (params: GridValueGetterParams) =>
      `${
        params.row.servico === 5 ||
        params.row.servico === 6 ||
        (params.row.servico === 7 && !params.row.flagSaqueParcelado) ||
        params.row.servico === 8 ||
        params.row.servico === 12
          ? '-'
          : params.row.qtdeParcelas
      }`,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'valorParcela',
    headerName: 'Valor Parcelas',
    valueGetter: (params: GridValueGetterParams) =>
      `${
        params.row.servico === 12
          ? maskMoney(
              params.row.produtosSINDNAP?.planos[0].valorPremioAux?.toFixed(2)
            ) || '-'
          : maskMoney(params.row.valorParcela?.toFixed(2)) || '-'
      }`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  }
]

export const COLUMNS_DETALHAMENTO_PARCELAS_FGTS: GridColDef[] = [
  {
    field: 'numeroParcela',
    headerName: 'Parcelas',
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'parcelaLiberada',
    headerName: 'Valor',
    valueGetter: (params: GridValueGetterParams) => {
      return `${
        maskMoney(Number(params.row.parcelaLiberada)?.toFixed(2)) || ''
      }`
    },
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'dataVencimento',
    headerName: 'Data desconto parcela',
    type: 'datetime',
    valueFormatter: (params: GridValueFormatterParams) =>
      `${maskDate(params.value, 'DD/MM/YYYY') || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'parcelaOriginal',
    headerName: 'Parcela original',
    valueGetter: (params: GridValueGetterParams) =>
      `${maskMoney(Number(params.row.parcelaOriginal)?.toFixed(2)) || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  },
  {
    field: 'iof',
    headerName: 'I.O.F',
    valueGetter: (params: GridValueGetterParams) =>
      `${maskMoney(Number(params.row.iof)?.toFixed(2)) || ''}`,
    headerClassName: headerTheme,
    align: 'left',
    headerAlign: 'left',
    flex: 1
  }
]

import { APICore } from 'helpers/api/apiCore'
import { DadosComplementaresEnderecoReq } from 'models/cadastros/dadosComplementaresEndereco/types'
import { removeMaskCEP } from 'util/masks'

const api = new APICore()

export function getDadosComplementaresEndereco(cpf: string) {
  return api.get(`DadosComplementares/dados-endereco`, {
    cpf
  })
}

type Cadastro = DadosComplementaresEnderecoReq & {
  cpf: string
}
export function salvarCadastro(params: Cadastro) {
  return api.update(`DadosComplementares/dados-endereco`, params)
}

export function getEstados() {
  return api.get(`Generic/estados`, null)
}

export function getCidades(siglaEstado: string) {
  return api.get(`Generic/cidades`, {
    siglaEstado
  })
}

export function getDadosEndereco(cep: string) {
  const cepFormatted = removeMaskCEP(cep)
  return api.get(`Generic/informacoes-endereco`, { cep: cepFormatted })
}

export function getCpf() {
  return api.get(`Generic/informacoes-cliente-matricula`, null)
}

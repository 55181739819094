import React, { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Collapse } from 'reactstrap'
import { CardFaq, FaqWrapper } from '../style'
import personaFaq from 'assets/images/personaFaq.png'

const Faq = () => {
  const [cardsState, setCardsState] = useState({
    firstCard: false,
    secondCard: false,
    thirdCard: false,
    fourthCard: false,
    fifthCard: false,
    sixthCard: false,
    seventhCard: false,
    eighthCard: false,
    ninthCard: false,
    tenthCard: false
  })

  const toggleCardVisibility = (cardName: keyof typeof cardsState) => {
    setCardsState((prevCards) => ({
      ...prevCards,
      [cardName]: !prevCards[cardName]
    }))
  }

  return (
    <FaqWrapper>
      <div className="d-none d-lg-flex">
        <img className="img-faq" src={personaFaq} />
      </div>
      <div className="faq">
        <h3>Perguntas Frequentes</h3>
        <CardFaq>
          <label>
            A Riber cobra depósitos ou taxas antecipadas?
            {cardsState.firstCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('firstCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('firstCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.firstCard}>
            <p>
              Não cobramos depósitos ou taxas antecipadas para você receber o
              seu empréstimo. Empresas e correspondentes bancários certificados
              pelo Banco Central não fazem esse tipo de cobrança. Acesse a nossa
              área de Peguntas Frequentes e veja mais informações sobre a gente.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Quais são as taxas de juros?
            {cardsState.secondCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('secondCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('secondCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.secondCard}>
            <p>
              Não cobramos depósitos ou taxas antecipadas para você receber o
              seu empréstimo. Empresas e correspondentes bancários certificados
              pelo Banco Central não fazem esse tipo de cobrança. Acesse a nossa
              área de Peguntas Frequentes e veja mais informações sobre a gente.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Quais são as formas de pagamento?
            {cardsState.thirdCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('thirdCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('thirdCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.thirdCard}>
            <p>
              Não cobramos depósitos ou taxas antecipadas para você receber o
              seu empréstimo. Empresas e correspondentes bancários certificados
              pelo Banco Central não fazem esse tipo de cobrança. Acesse a nossa
              área de Peguntas Frequentes e veja mais informações sobre a gente.
            </p>
          </Collapse>
        </CardFaq>
      </div>
    </FaqWrapper>
  )
}

export default Faq

import styled from 'styled-components'
import backgroundBanner from 'assets/images/apresentacao/background_verde_v2.png'
import introducaoProduto from 'assets/images/apresentacao/introducao-produto.png'

export const ApresentacaoLayoutWrapper = styled.section`
  display: flex;
  flex-direction: column;
`

interface BannerWrapperProps {
  image: string
}

export const BannerWrapper = styled.section<BannerWrapperProps>`
  background: url(${backgroundBanner}) 100% 100% no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  min-height: 450px;
  height: 60vh;

  & .infos {
    justify-content: center;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    padding: 0 50px;
    width: 100%;

    & h2,
    h3 {
      font-weight: 700;
      line-height: 54px;
    }

    & h2 {
      font-size: 48px;
      color: var(--roxo-riber);
    }

    & h3 {
      font-size: 32px;
      color: var(--branco);
    }
  }

  & .img-banner {
    margin-right: 100px;
  }

  @media screen and (max-width: 1200px) {
    justify-content: center;

    & .infos {
      align-items: center;

      & h2,
      h3 {
        font-weight: 700;
        line-height: 35px;
        text-align: center;
      }

      & h2 {
        font-size: 30px;
      }

      & h3 {
        font-size: 25px;
      }
    }

    & .img-banner {
      display: none !important;
    }
  }
`

export const ArticleWrapper = styled.article`
  & .grid {
    display: flex;
    max-width: 1200px;

    & > * {
      & h4 {
        color: var(--roxo-riber);
        font-size: 40px;
        font-weight: 700;
      }

      & p {
        color: var(--dark-gray);
        font-size: 16px;
      }

      & img {
        max-width: 400px;
        width: 100%;
      }
    }
  }
`
export const SimulacaoWrapper = styled.section`
  background-image: linear-gradient(
      to right top,
      #00ab4f44,
      #00b25344,
      #00ba5644,
      #00c15a44,
      #00c95e44
    ),
    url(${introducaoProduto});
  background-color: #c0bccc;
  background-position: 95% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 840px;
  justify-content: center;
  max-height: 840px;
  padding: 40px 80px;

  & h2 {
    color: var(--roxo-riber);
    font-size: 48px;
    font-weight: 700;
  }

  & h3 {
    color: var(--branco);
    font-size: 40px;
    font-weight: 700;
  }
`

export const CardSimulacao = styled.div`
  align-items: center;
  background: var(--branco);
  border-radius: 30px;
  box-shadow: 0px 0px 5.41032px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 600px;
  padding: 20px;
  row-gap: 15px;
  width: 100%;

  & .titulos {
    align-items: center;
    display: flex;
    flex-direction: column;

    & label {
      color: var(--verde-riber);
      font-weight: 700;
      font-size: 28px;
      line-height: 40px;
    }

    & span {
      color: var(--preto-riber);
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
    }
  }
`
interface FaqWrapperProps {
  backgroundImage: string
}

export const FaqWrapper = styled.aside<FaqWrapperProps>`
  background: url(${backgroundBanner}),
    url(${(props) => props.backgroundImage}) no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: space-around;
  padding: 80px;

  .img-faq {
    width: 600px;
    object-fit: contain;
  }

  & .faq {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 600px;

    & div {
      flex-direction: column;
      gap: 40px;
    }

    & h3 {
      color: var(--branco);
      font-weight: 700;
      font-size: 27px;
      text-align: center;
    }
  }

  @media screen and (max-width: 1200px) {
    .img-faq {
      width: 400px;
    }
  }

  @media screen and (max-width: 991px) {
    background-image: url(${(props) => props.backgroundImage}),
      url(${backgroundBanner});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 600px, cover;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    background-size: 400px, cover;
  }
`

export const CardFaq = styled.div`
  background: var(--light-gray);
  border-radius: 20px;
  display: flex;
  padding: 10px 20px;
  width: 100%;

  & label {
    align-items: center;
    color: var(--preto-riber);
    display: flex;
    font-weight: 400;
    font-size: 18px;
    gap: 10px;
    justify-content: space-between;
    line-height: 32px;

    & .icon {
      min-width: 40px;
    }
  }

  & p {
    color: var(--preto-riber);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`

import { AiOutlineHome, AiOutlineCalculator } from 'react-icons/ai'
import { BiUser } from 'react-icons/bi'
import { FiBriefcase } from 'react-icons/fi'
import { BsGrid, BsCalendar4Week } from 'react-icons/bs'
import Simulacao from 'views/simulacao'
import Propostas from 'views/propostas'
import MeusDados from 'views/meusDados'
import Utilitarios from 'views/utilitarios'
import Calendario from 'views/utilitarios/menus/Calendario'
import Calculadora from 'views/utilitarios/menus/Calculadora'
import Profissional from 'views/cadastros/Steps/Profissional'
import Validacao from 'views/cadastros/Steps/Validacao'
import DadosComplementaresEndereco from 'views/cadastros/Steps/DadosComplementaresEndereco'
import DadosComplementaresDadosBancarios from 'views/cadastros/Steps/DadosComplementaresDadosBancarios'
import DadosComplementaresDocumento from 'views/cadastros/Steps/DadosComplementaresDocumento'
import DadosComplementaresInfoPessoal from 'views/cadastros/Steps/DadosComplementaresInfoPessoal.tsx'

export interface Routes {
  title: string
  path: string
  component?: JSX.Element
  icon: JSX.Element
  subPaths?: SubPath[]
  sidebar: boolean
}

interface SubPath {
  index?: boolean
  title: string
  menuTitle?: string
  path?: string
  component?: JSX.Element
  icon?: JSX.Element
}

const routes: Routes[] = [
  {
    title: 'Simulação',
    path: '/simulacao',
    component: <Simulacao />,
    icon: <AiOutlineHome className="icon" />,
    sidebar: true
  },
  {
    title: 'Propostas',
    path: '/propostas',
    component: <Propostas />,
    icon: <FiBriefcase className="icon" />,
    sidebar: true
  },
  {
    title: 'Meus dados',
    path: '/meus-dados',
    component: <MeusDados />,
    icon: <BiUser className="icon" />,
    sidebar: true
  },
  {
    title: 'Utilitários',
    path: '/utilitarios',
    subPaths: [
      {
        title: 'Utilitários',
        index: true,
        component: <Utilitarios />,
        icon: <BsGrid className="icon" />
      },
      {
        title: 'Calendário',
        menuTitle: 'Calendário',
        path: 'calendario',
        component: <Calendario />,
        icon: <BsCalendar4Week className="icon" />
      },
      {
        title: 'Calculadora',
        menuTitle: 'Calculadora',
        path: 'calculadora',
        component: <Calculadora />,
        icon: <AiOutlineCalculator className="icon" />
      }
    ],
    icon: <BsGrid className="icon" />,
    sidebar: true
  },
  {
    title: 'Profissional',
    path: '/profissional',
    component: <Profissional />,
    icon: <></>,
    sidebar: false
  },
  {
    title: 'Validação',
    path: '/validacao',
    component: <Validacao />,
    icon: <></>,
    sidebar: false
  },
  {
    title: 'Endereço',
    path: '/dados-complementares-endereco',
    component: <DadosComplementaresEndereco />,
    icon: <></>,
    sidebar: false
  },
  {
    title: 'Dados bancários',
    path: '/dados-complementares-dados-bancarios',
    component: <DadosComplementaresDadosBancarios />,
    icon: <></>,
    sidebar: false
  },
  {
    title: 'Documento',
    path: '/dados-complementares-documento',
    component: <DadosComplementaresDocumento />,
    icon: <></>,
    sidebar: false
  },
  {
    title: 'Informações pessoais',
    path: '/dados-complementares-info-pessoal',
    component: <DadosComplementaresInfoPessoal />,
    icon: <></>,
    sidebar: false
  }
]

export default routes

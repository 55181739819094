import { ProdutosSimulacao } from 'models/types'
import { SIMULACAO, TYPES } from './types'

export const setNomeCompleto = (value: SIMULACAO['nomeCompleto']) => ({
  type: TYPES.SET_NOME_COMPLETO,
  value
})

export const setCpf = (value: SIMULACAO['cpf']) => ({
  type: TYPES.SET_CPF,
  value
})

export const setBeneficios = (value: SIMULACAO['beneficios']) => ({
  type: TYPES.SET_BENEFICIOS,
  value
})

export const toggleDetalhamento = () => ({
  type: TYPES.TOGGLE_DETALHAMENTO
})

export const toggleModalResumoContratacao = (value: boolean) => ({
  type: TYPES.TOGGLE_MODAL_RESUMO_CONTRATACAO,
  value
})

export const setInfosClienteResumo = (
  value: SIMULACAO['infosClienteResumo']
) => ({
  type: TYPES.SET_INFOS_CLIENTE_RESUMO,
  value
})

export const setLoadingSimulacao = (value: boolean) => ({
  type: TYPES.SET_LOADING_SIMULACAO,
  value
})

type FieldsProduto = keyof ProdutosSimulacao
export const setInfosCartaoConsig = (
  value: string | number | boolean,
  field: FieldsProduto,
  matricula: string
) => ({
  type: TYPES.SET_INFOS_CARTAO_CONSIG,
  value,
  field,
  matricula
})

export const setInfosCartaoBeneficio = (
  value: string | number | boolean,
  field: FieldsProduto,
  matricula: string
) => ({
  type: TYPES.SET_INFOS_CARTAO_BENEFICIO,
  value,
  field,
  matricula
})

export const setInfosSaqueComplementarConsignado = (
  value: string | number | boolean,
  field: FieldsProduto,
  matricula: string
) => ({
  type: TYPES.SET_INFOS_SAQUE_COMPLEMENTAR_CONSIGNADO,
  value,
  field,
  matricula
})

export const setInfosSaqueComplementarBeneficio = (
  value: string | number | boolean,
  field: FieldsProduto,
  matricula: string
) => ({
  type: TYPES.SET_INFOS_SAQUE_COMPLEMENTAR_BENEFICIO,
  value,
  field,
  matricula
})

export const setInvalidContratacao = (value: boolean) => ({
  type: TYPES.SET_INVALID_CONTRATACAO,
  value
})

export const selectProduto = (value: SIMULACAO['produtoSelected']) => ({
  type: TYPES.SELECT_PRODUTO,
  value
})

export const setProdutosDisponiveis = (
  value: SIMULACAO['produtosDisponiveis']
) => ({
  type: TYPES.SET_PRODUTOS_DISPONIVEIS,
  value
})

export const setProdutosSelecionados = (
  value: SIMULACAO['produtosSelecionados']
) => ({
  type: TYPES.SET_PRODUTOS_SELECIONADOS,
  value
})

export const setSomatorioTotalProdutos = (value: number) => ({
  type: TYPES.SET_SOMATORIO_TOTAL_PRODUTOS,
  value
})

export const setSomatorioResumo = (value: number) => ({
  type: TYPES.SET_SOMATORIO_RESUMO,
  value
})

export const getClienteMatriculaCpfSaga = () => ({
  type: TYPES.GET_CLIENTE_MATRICULA_CPF_SAGA
})

export const getPropostasSaga = () => ({
  type: TYPES.GET_PROPOSTAS_SAGA
})

export const solicitarSimulacaoFgtsSaga = () => ({
  type: TYPES.SOLICITAR_SIMULACAO_FGTS_SAGA
})

export const getParcelasFgtsSaga = () => ({
  type: TYPES.GET_PARCELAS_FGTS_SAGA
})

export const recalculoPropostaSaga = (
  parcelas: number,
  valorTotal: number,
  valorParcela: number,
  servico: number,
  tabela: number,
  numeroMatricula: string,
  banco: number
) => ({
  type: TYPES.RECALCULO_PROPOSTA_SAGA,
  parcelas,
  valorTotal,
  valorParcela,
  servico,
  tabela,
  numeroMatricula,
  banco
})

export const recalculoPropostaFgtsSaga = (valor: number, parcelas: number) => ({
  type: TYPES.RECALCULO_PROPOSTA_FGTS_SAGA,
  valor,
  parcelas
})

export const validacaoContratacaoSaga = () => ({
  type: TYPES.VALIDACAO_CONTRATACAO_SAGA
})

export const getResumoContratacaoSaga = () => ({
  type: TYPES.GET_RESUMO_CONTRATACAO_SAGA
})

export const finalizacaoContratacao = () => ({
  type: TYPES.FINALIZACAO_CONTRATACAO_SAGA
})

export const setInfosModalIndicacao = (
  value: SIMULACAO['modalIndicacaoFgts']
) => ({
  type: TYPES.SET_INFOS_MODAL_INDICACAO,
  value
})

export const cleanInfosModalIndicacao = () => ({
  type: TYPES.CLEAN_INFOS_MODAL_INDICACAO
})

type OptionsSelectFields = keyof SIMULACAO['optionsSelect']
export const setOptionsSelect = <T extends OptionsSelectFields>(
  value: T extends OptionsSelectFields ? SIMULACAO['optionsSelect'][T] : never,
  field: T
) => ({
  type: TYPES.SET_OPTIONS_SELECT,
  value,
  field
})

type LoadingSelectFields = keyof SIMULACAO['loadingSelect']
export const setLoadingSelect = <T extends OptionsSelectFields>(
  value: T extends LoadingSelectFields ? SIMULACAO['loadingSelect'][T] : never,
  field: T
) => ({
  type: TYPES.SET_LOADING_SELECTS,
  value,
  field
})

export const getPerfisResumoSaga = () => ({
  type: TYPES.GET_PERFIS_RESUMO_SAGA
})

export const getTiposContaResumoSaga = () => ({
  type: TYPES.GET_TIPOS_CONTA_RESUMO_SAGA
})

export const getBancosResumoSaga = () => ({
  type: TYPES.GET_BANCOS_RESUMO_SAGA
})

export const getConveniosResumoSaga = () => ({
  type: TYPES.GET_CONVENIOS_RESUMO_SAGA
})

/**
 * Função: validaStatusIN100Saga
 *
 * Descrição: Esta função é utilizada para verificar as matrículas INSS.
 *
 * Se houver matrículas que requerem validação, um modal é aberto.
 *
 * Caso contrário, a simulação é chamada diretamente.
 *
 * Se não houver matrículas INSS, a simulação é executada imediatamente.
 */
export const validaStatusIN100Saga = () => ({
  type: TYPES.VALIDA_STATUS_IN100_SAGA
})

type ValidacaoFields = keyof SIMULACAO['validacaoFields']
export const setValidacaoFields = <T extends ValidacaoFields>(
  value: T extends keyof SIMULACAO['validacaoFields']
    ? SIMULACAO['validacaoFields'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_VALIDACAO_FIELDS,
  value,
  field
})

export const cleanValidacaoFields = () => ({
  type: TYPES.CLEAN_VALIDACAO_FIELDS
})

type ValidacaoFieldsInvalids = keyof SIMULACAO['validacaoFieldsInvalids']
export const setValidacaoFieldsInvalids = <T extends ValidacaoFieldsInvalids>(
  value: T extends keyof SIMULACAO['validacaoFieldsInvalids']
    ? SIMULACAO['validacaoFieldsInvalids'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_VALIDACAO_FIELDS_INVALIDS,
  value,
  field
})

export const cleanValidacaoFieldsInvalids = () => ({
  type: TYPES.CLEAN_VALIDACAO_FIELDS_INVALIDS
})

export const inserirSolicitacoesIn100Saga = () => ({
  type: TYPES.INSERIR_SOLICITACOES_IN100_SAGA
})

export const validarTokenIn100Saga = () => ({
  type: TYPES.VALIDAR_TOKEN_IN100_SAGA
})

export const toggleModalIN100 = (value: boolean) => ({
  type: TYPES.TOGGLE_MODAL_IN100,
  value
})

export const setMatriculasValidacao = (value: string[]) => ({
  type: TYPES.SET_MATRICULAS_VALIDACAO,
  value
})

export const setJaSimulado = () => ({
  type: TYPES.SET_JA_SIMULADO
})

import { Reducer } from 'redux'
import { DADOS_COMPLEMENTARES_DOCUMENTO, TYPES } from './types'

const INITIAL_STATE: DADOS_COMPLEMENTARES_DOCUMENTO = {
  cpf: '',
  formulario: {
    numeroRg: '',
    orgaoEmissorId: '',
    ufOrgaoEmissor: '',
    dataEmissao: '',
    nomeMae: '',
    nomePai: ''
  },
  invalidFormulario: {
    numeroRg: {
      message: '',
      invalid: false
    },
    orgaoEmissorId: {
      message: '',
      invalid: false
    },
    ufOrgaoEmissor: {
      message: '',
      invalid: false
    },
    dataEmissao: {
      message: '',
      invalid: false
    },
    nomeMae: {
      message: '',
      invalid: false
    },
    nomePai: {
      message: '',
      invalid: false
    }
  },
  optionsSelects: {
    orgaosEmissor: [],
    ufsOrgaoEmissor: []
  },
  loadingSelects: {
    orgaosEmissor: false,
    ufsOrgaoEmissor: false
  }
}

const reducer: Reducer<DADOS_COMPLEMENTARES_DOCUMENTO> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TYPES.SET_CPF:
      return {
        ...state,
        cpf: action.value
      }
    case TYPES.SET_FORMULARIO:
      return {
        ...state,
        formulario: action.value
      }
    case TYPES.SET_FORMULARIO_FIELD:
      return {
        ...state,
        formulario: {
          ...state.formulario,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_FORMULARIO:
      return {
        ...state,
        formulario: INITIAL_STATE.formulario
      }
    case TYPES.SET_INVALID_FORMULARIO:
      return {
        ...state,
        invalidFormulario: {
          ...state.invalidFormulario,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_INVALIDS_FORMULARIO:
      return {
        ...state,
        invalidFormulario: INITIAL_STATE.invalidFormulario
      }
    case TYPES.SET_OPTIONS_SELECT:
      return {
        ...state,
        optionsSelects: {
          ...state.optionsSelects,
          [action.field]: action.value
        }
      }
    case TYPES.SET_LOADING_SELECTS:
      return {
        ...state,
        loadingSelects: {
          ...state.loadingSelects,
          [action.field]: action.value
        }
      }
    default:
      return state
  }
}

export default reducer

import styled from 'styled-components'

export const CorpoConteudo = styled.main`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  width: calc(80vw - 40px);
  top: 50px;
  left: calc(40px + 18vw);
  padding: 20px 0;

  @media (max-width: 992px) {
    width: calc(90vw);
    margin-inline: auto;
    left: 0;
  }
`

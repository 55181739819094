import { Reducer } from 'redux'
import { VALIDACAO, TYPES } from './types'

const INITIAL_STATE: VALIDACAO = {
  validacaoFields: {
    celular: '',
    codigo: '',
    matricula: ''
  },
  validacaoFieldsInvalids: {
    celular: {
      invalid: false,
      message: ''
    },
    codigo: {
      invalid: false,
      message: ''
    },
    matricula: {
      invalid: false,
      message: ''
    }
  }
}

const reducer: Reducer<VALIDACAO> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_VALIDACAO_FIELDS:
      return {
        ...state,
        validacaoFields: {
          ...state.validacaoFields,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_VALIDACAO_FIELDS:
      return {
        ...state,
        validacaoFields: INITIAL_STATE.validacaoFields
      }
    case TYPES.SET_VALIDACAO_FIELDS_INVALIDS:
      return {
        ...state,
        validacaoFieldsInvalids: {
          ...state.validacaoFieldsInvalids,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_VALIDACAO_FIELDS_INVALIDS:
      return {
        ...state,
        validacaoFieldsInvalids: INITIAL_STATE.validacaoFieldsInvalids
      }
    default:
      return state
  }
}

export default reducer

import { Reducer } from 'redux'
import { NAVBAR, TYPES } from './types'

interface NAVBAR_STATE extends NAVBAR {
  modalTrocarSenha: boolean
}

const INITIAL_STATE: NAVBAR_STATE = {
  nomeUsuario: '',
  modalTrocarSenha: false
}

const reducer: Reducer<NAVBAR_STATE> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_NOME_USUARIO:
      return { ...state, nomeUsuario: action.value }
    case TYPES.SET_MODAL_TROCAR_SENHA:
      return { ...state, modalTrocarSenha: action.value.isOpen }
    default:
      return state
  }
}

export default reducer

import React, { useState } from 'react'
import InputCustom from 'components/Inputs'
import { Col, Row } from 'reactstrap'
import useRedux from 'hooks/useRedux'
import CustomSelect from 'components/Select'
import { SimpleTitleCard } from 'styles/global'
import { FiGlobe, FiTrash } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { MEUS_DADOS } from 'store/modules/meusDados/types'
import * as meusDadosActions from 'store/modules/meusDados/actions'
import { maskMoney, maskNumber } from 'util/masks'
import ModalExclusao from 'components/ModalExclusao'
import { capitalizeWords } from 'util/textFormatters'

interface InssProps {
  index: number
}

const Inss = ({ index }: InssProps) => {
  const { meusDados, invalidMeusDados, optionsSelects, loadingSelects } =
    useSelector<ApplicationState, MEUS_DADOS>((state) => state.meusDados)

  const { dispatch } = useRedux()

  const [show, setShow] = useState(false)
  const [bancoDisable, setBancoDisable] = useState(false)

  type MatriculaField = keyof MEUS_DADOS['meusDados']['matriculas'][number]
  const onChange = <T extends MatriculaField>(
    value: T extends keyof MEUS_DADOS['meusDados']['matriculas'][number]
      ? MEUS_DADOS['meusDados']['matriculas'][number][T]
      : never,
    field: T,
    matriculaIndex: number
  ) => {
    const matriculas = [...(meusDados.matriculas || [])]
    if (field === 'tipoContaId') {
      const isOrdemPagamento = optionsSelects.tiposConta?.some(
        (x) => x.value === value && x.descricao === 'ORDEM DE PAGAMENTO'
      )
      const isContaBmg = optionsSelects.tiposConta?.some(
        (x) => x.value === value && x.descricao === 'CONTA BMG'
      )
      setBancoDisable(isOrdemPagamento || isContaBmg)
      if (isOrdemPagamento) {
        const banco = optionsSelects.bancos?.find(
          (x) => x.codigoBanco === '341'
        )
        matriculas[matriculaIndex] = {
          ...matriculas[matriculaIndex],
          bancoDadosBancariosId: banco?.id || ''
        }
      }
      if (isContaBmg) {
        const banco = optionsSelects.bancos?.find(
          (x) => x.codigoBanco === '318'
        )
        matriculas[matriculaIndex] = {
          ...matriculas[matriculaIndex],
          bancoDadosBancariosId: banco?.id || ''
        }
      }
    }
    matriculas[matriculaIndex] = {
      ...matriculas[matriculaIndex],
      [field]: value
    }
    dispatch(meusDadosActions.setMeusDadosField(matriculas, 'matriculas'))
  }

  return (
    <div className="d-flex flex-column row-gap-15">
      <div className="container-fluid">
        <Row>
          <Col md={4}>
            <label className="label-12">Perfil*</label>
            <CustomSelect
              options={optionsSelects.perfis.map((perfil) => {
                return {
                  ...perfil,
                  descricao: capitalizeWords(perfil.descricao)
                }
              })}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'tipoPerfilId', index)
              }}
              value={meusDados.matriculas[index]?.tipoPerfilId}
              accessorLabel="descricao"
              accessorValue="id"
              invalid={invalidMeusDados.matriculas[index]?.tipoPerfilId.invalid}
              errorMessage={
                invalidMeusDados.matriculas[index]?.tipoPerfilId.message
              }
              disabled
              isLoading={loadingSelects.perfis}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Matrícula/Benefício</label>
            <InputCustom
              name="matricula"
              type="text"
              onChange={(e) => {
                onChange(e.target.value, 'numeroMatricula', index)
              }}
              placeholder="Digite aqui"
              value={meusDados.matriculas[index]?.numeroMatricula || ''}
              invalid={
                invalidMeusDados.matriculas[index]?.numeroMatricula?.invalid
              }
              errorMessage={
                invalidMeusDados.matriculas[index]?.numeroMatricula?.message
              }
              maxLength={15}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Salário*</label>
            <InputCustom
              name="matricula"
              type="text"
              onChange={(e) => {
                onChange(
                  maskMoney(e.target.value),
                  'valorRendaIndividual',
                  index
                )
              }}
              placeholder="Digite aqui"
              value={meusDados.matriculas[index]?.valorRendaIndividual || ''}
              invalid={
                invalidMeusDados.matriculas[index]?.valorRendaIndividual
                  ?.invalid
              }
              errorMessage={
                invalidMeusDados.matriculas[index]?.valorRendaIndividual
                  ?.message
              }
              maxLength={15}
            />
          </Col>
        </Row>
      </div>
      <div className="container-fluid">
        <SimpleTitleCard>
          <FiGlobe className="icon" /> Dados bancários
        </SimpleTitleCard>
        <Row>
          <Col md={4}>
            <label className="label-12">Tipo conta*</label>
            <CustomSelect
              options={optionsSelects.tiposConta}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'tipoContaId', index)
              }}
              value={meusDados.matriculas[index]?.tipoContaId}
              accessorLabel="descricao"
              accessorValue="value"
              invalid={invalidMeusDados.matriculas[index]?.tipoContaId.invalid}
              errorMessage={
                invalidMeusDados.matriculas[index]?.tipoContaId.message
              }
              isLoading={loadingSelects.tiposConta}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Banco*</label>
            <CustomSelect
              options={optionsSelects.bancos}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'bancoDadosBancariosId', index)
              }}
              value={meusDados.matriculas[index]?.bancoDadosBancariosId}
              accessorLabel="descricao"
              accessorValue="id"
              invalid={
                invalidMeusDados.matriculas[index]?.bancoDadosBancariosId
                  .invalid
              }
              errorMessage={
                invalidMeusDados.matriculas[index]?.bancoDadosBancariosId
                  .message
              }
              disabled={bancoDisable}
              isLoading={loadingSelects.bancos}
            />
          </Col>
          <Col md={2}>
            <label className="label-12">Agência*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskNumber(e.target.value), 'numeroAgencia', index)
              }}
              value={meusDados.matriculas[index]?.numeroAgencia || ''}
              invalid={
                invalidMeusDados.matriculas[index]?.numeroAgencia.invalid
              }
              errorMessage={
                invalidMeusDados.matriculas[index]?.numeroAgencia.message
              }
              maxLength={10}
            />
          </Col>
          <Col md={2}>
            <label className="label-12">Dígito agência</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(
                  maskNumber(e.target.value),
                  'digitoVerificadorAgencia',
                  index
                )
              }}
              value={
                meusDados.matriculas[index]?.digitoVerificadorAgencia || ''
              }
              invalid={
                invalidMeusDados.matriculas[index]?.digitoVerificadorAgencia
                  ?.invalid
              }
              errorMessage={
                invalidMeusDados.matriculas[index]?.digitoVerificadorAgencia
                  ?.message
              }
              maxLength={1}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label className="label-12">Número conta*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskNumber(e.target.value), 'numeroConta', index)
              }}
              value={meusDados.matriculas[index]?.numeroConta || ''}
              invalid={invalidMeusDados.matriculas[index]?.numeroConta.invalid}
              errorMessage={
                invalidMeusDados.matriculas[index]?.numeroConta.message
              }
              maxLength={10}
            />
          </Col>
          <Col md={2}>
            <label className="label-12">Dígito conta*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(
                  maskNumber(e.target.value),
                  'digitoVerificadorConta',
                  index
                )
              }}
              value={meusDados.matriculas[index]?.digitoVerificadorConta || ''}
              invalid={
                invalidMeusDados.matriculas[index]?.digitoVerificadorConta
                  .invalid
              }
              errorMessage={
                invalidMeusDados.matriculas[index]?.digitoVerificadorConta
                  .message
              }
              maxLength={1}
            />
          </Col>
        </Row>
      </div>
      <div className="linha-horizontal" />
      <div className="container-fluid">
        <Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <FiTrash className="icon-trash" onClick={() => setShow(true)} />
            </Col>
          </Row>
        </Row>
      </div>
      <ModalExclusao
        isOpen={show}
        toggle={(e) => setShow(e)}
        onRemove={() => {
          if (meusDados.matriculas[index]?.id) {
            dispatch(
              meusDadosActions.removerMatriculaSaga(
                meusDados.matriculas[index]?.id ?? '',
                index
              )
            )
          } else {
            dispatch(meusDadosActions.removerPerfil(index))
          }
        }}
      />
    </div>
  )
}

export { Inss }

import styled, { css } from 'styled-components'
import checkCircle from 'assets/images/check-circle.png'
import uncheckCircle from 'assets/images/uncheck-circle.png'
import { AiFillCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai'

export const ApresentacaoValoresWrapper = styled.div`
  background: linear-gradient(
    43.84deg,
    var(--roxo-riber) -5.32%,
    #a64dff 97.27%
  );
  border-radius: 15px;
  color: var(--branco);
  padding: 20px;

  & label {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }

  & span {
    font-size: 16px;
    line-height: 26px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  & .icon {
    width: 50px;
    height: 50px;
  }

  & .valor-contratacao {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
`

export const ContentProduto = styled.div`
  align-self: stretch;
  background: var(--branco);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 20px;
  height: 100%;
  width: 100%;

  & .icon {
    color: var(--roxo-riber);
    width: 30px;
    height: 30px;
    min-width: 30px;
  }

  & .matricula {
    font-size: 15px;
    font-weight: 700;
  }

  & .nome-produto {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: var(--preto-riber);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  & .valor-produto {
    font-weight: 700;
    font-size: 25px;
    line-height: 28px;
    color: var(--verde-riber);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  & .valor-produto-secondary {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #838383;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  & .mensagem-erro {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #dc3545;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
`

export const CheckboxProduto = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background: url(${uncheckCircle}) var(--branco) no-repeat;
    width: 40px;
    height: 40px;
    top: -10px;
    left: -10px;
  }

  &:checked:before {
    content: '';
    display: block;
    position: absolute;
    background: url(${uncheckCircle}) var(--branco) no-repeat;
    width: 40px;
    height: 40px;
    top: -10px;
    left: -10px;
  }

  &:checked:after {
    content: '';
    display: block;
    position: absolute;
    background: url(${checkCircle}) var(--branco) no-repeat;
    width: 40px;
    height: 40px;
    top: -10px;
    left: -10px;
  }
`
export const ContentDetalhamentoProduto = styled.div`
  background: var(--branco);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  color: var(--dark-gray);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 40px 20px;
  row-gap: 40px;
  width: 100%;

  & h2 {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }

  & .btn-plus,
  .btn-less {
    align-items: center;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
    border-radius: 7px;
    color: var(--branco);
    cursor: pointer;
    display: flex;
    height: 38px;
    padding: 8px 16px;
  }

  & .btn-plus {
    background: var(--verde-riber);
  }

  & .btn-less {
    background: var(--roxo-riber);
  }

  & .detalhamento-fgts {
    cursor: pointer;
    color: var(--roxo-riber);

    &:hover {
      color: #4e2170;
      font-weight: 600;
    }

    & .icon {
      font-size: 20px;
    }
  }

  & .mensagem-erro {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #dc3545;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  cursor: pointer;
  transform: scale(0.8);

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -2px;
    left: -2px;
    background-color: var(--branco);
    border: solid 2px var(--roxo-riber);
    border-radius: 5px;
  }
  &:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -2px;
    left: -2px;
    border: solid 2px var(--roxo-riber);
  }
  &:checked:after {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 10px;
    border-width: 0 2px 2px 0;
    border-radius: 2px;
    background-color: var(--roxo-riber);
    position: absolute;
    top: 3px;
    left: 3px;
  }
`

export const InvisibleInput = styled.input`
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 25px;
  line-height: 28px;
  color: var(--verde-riber);
  width: 100%;
`

export const InfoIconGreen = styled(AiOutlineInfoCircle)`
  color: var(--verde-riber);
  background-clip: text;
  font-size: 60px;
`

export const CloseIconRed = styled(AiFillCloseCircle)`
  color: var(--vermelho-riber);
  background-clip: text;
  font-size: 60px;
`

export const ModalIndicacaoWrapper = styled.div`
  & .main-title {
    color: var(--dark-gray);
    text-align: center;
    font-family: 'Poppins' sans-serif;
    font-weight: 700;
    font-size: 20px !important;
    line-height: 30px;
  }

  & .common-text {
    color: var(--dark-gray);
    font-family: 'Poppins' sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;

    & .text-purple {
      color: var(--roxo-riber);
      font-size: 24px;
      line-height: 26px;
    }
  }
`

interface ButtonLinkProps {
  width?: number | string
  background?: string
  borderRadius?: number
  letterSpacing?: number
  justifyContent?: string
  color?: string
  padding?: string
  height?: string
}

export const CustomButtonLink = styled.a<ButtonLinkProps>`
  align-items: center;
  background: ${(props) =>
    props.background
      ? props.background
      : 'linear-gradient(264.61deg, #87df41 -9.34%, var(--verde-riber) 101.57%)'};
  border: none;
  border-radius: ${(props) =>
    props.borderRadius ? `${props.borderRadius}px` : '15px'};
  color: ${(props) => (props.color ? props.color : '#fff')};
  display: flex;
  padding: ${(props) => (props.padding ? `${props.padding}px` : '10px')};
  font-weight: 700;
  font-size: 14px;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'center'};
  letter-spacing: ${(props) =>
    props.letterSpacing ? `${props.letterSpacing}px` : '0px'};
  line-height: 18px;
  height: ${(props) => (props.height ? `${props.height}` : '40px')};
  width: ${(props) => (props.width ? `${props.width}` : '180px')};
  text-decoration: none;
`
export const CardInfoPurple = styled.div`
  background: #662d9133;
  border: solid 1px var(--roxo-riber);
  border-radius: 15px;
  color: var(--roxo-riber);
  font-weight: 500;
  padding: 18px;
`

export const RadioMatriculaWrapper = styled.div`
  & input[type='radio'] {
    display: none;
  }

  & input[type='radio'] + label {
    border-bottom: solid 4px var(--cinza-riber);
    cursor: pointer;
    font-weight: 600;

    &:hover {
      border-bottom: solid 4px var(--azul-riber);
    }
  }

  & input[type='radio']:checked + label {
    border-bottom: solid 4px var(--verde-riber);

    &:hover {
      border-bottom: solid 4px var(--marinho-riber);
    }
  }
`
export const CardProdutoContainer = styled.div<{ isDetalhamentoOpen: boolean }>`
  width: 100%;

  ${(props) =>
    props.isDetalhamentoOpen
      ? css`
          @media (min-width: 1159px) {
            flex: 0 0 auto;
            width: 50%;
          }
        `
      : css`
          @media (min-width: 1000px) {
            width: 50%;
          }

          @media (min-width: 1260px) {
            width: 33%;
          }

          @media (min-width: 1568px) {
            width: 25%;
          }

          @media (min-width: 2560px) {
            width: 20%;
          }
        `};
`

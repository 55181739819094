import React, { useEffect, useState } from 'react'
import CustomTable from 'components/CustomTable'
import { FiUser } from 'react-icons/fi'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import { CustomButton } from 'styles/global'
import { COLUMNS_RESUMO_PRODUTOS } from 'views/simulacao/constants/columns'
import { ApresentacaoValoresWrapper } from 'views/simulacao/style'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { SIMULACAO } from 'store/modules/simulacao/types'
import { maskMoney, removeMaskMoney } from 'util/masks'
import * as simulacaoActions from 'store/modules/simulacao/actions'
import useRedux from 'hooks/useRedux'
import BeneficiosTabs from '../BeneficiosTabsResumo'
import SimpleTab from 'components/SimpleTab/SimpleTab'
import history from 'util/history'

interface ResumoContratacaoProps {
  isOpen: boolean
  onToggle: () => void
}

const ResumoContratacao = ({ isOpen, onToggle }: ResumoContratacaoProps) => {
  const { produtosSelecionados, somatorioResumo, infosClienteResumo } =
    useSelector<ApplicationState, SIMULACAO>((state) => state.simulacao)

  const { dispatch } = useRedux()

  const [beneficios, setBeneficios] = useState<
    {
      id: number
      descricao: string
      component: JSX.Element
    }[]
  >([])

  useEffect(() => {
    dispatch(
      simulacaoActions.setSomatorioResumo(
        produtosSelecionados
          .map((produto) => {
            if (
              produto.servico === 5 ||
              produto.servico === 6 ||
              produto.servico === 7 ||
              produto.servico === 8 ||
              produto.servico === 12
            ) {
              if (!produto.flagSaqueAutorizado && !produto.flagSaqueParcelado) {
                return 0
              } else {
                return Number(removeMaskMoney(produto.valorSaque))
              }
            } else {
              return produto.valorTotalFinanciado
            }
          })
          .reduce((prev, curr) => prev + curr, 0)
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen])

  useEffect(() => {
    const matriculas: typeof beneficios = []
    infosClienteResumo.matriculas?.map((matricula, i) => {
      matriculas.push({
        id: i,
        descricao: matricula.descricaoPerfil || 'Descrição indisponível',
        component: (
          <BeneficiosTabs
            matricula={infosClienteResumo.matriculas?.[i]}
            indexPerfil={i}
          />
        )
      })
    })
    setBeneficios(matriculas)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infosClienteResumo.matriculas])

  function mudaMeusDados() {
    history.push('/meus-dados')
    onToggle()
  }

  useEffect(() => {
    if (isOpen) {
      dispatch(simulacaoActions.getResumoContratacaoSaga())
      dispatch(simulacaoActions.getPerfisResumoSaga())
      dispatch(simulacaoActions.getTiposContaResumoSaga())
      dispatch(simulacaoActions.getBancosResumoSaga())
      dispatch(simulacaoActions.getConveniosResumoSaga())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOpen])

  function concluirContratacao() {
    dispatch(simulacaoActions.finalizacaoContratacao())
  }

  return (
    <Modal size="xl" isOpen={isOpen} toggle={() => onToggle()}>
      <ModalBody>
        <div className="container-fluid d-flex flex-column row-gap-10">
          <Row className="d-flex justify-content-between">
            <Col md={4}>
              <div
                className="btn-outline-purple justify-content-center"
                onClick={() => {
                  mudaMeusDados()
                }}
              >
                Voltar e alterar
              </div>
            </Col>
          </Row>
          <ApresentacaoValoresWrapper>
            <Row>
              <Col className="d-flex flex-column" sm={6}>
                <Col>
                  <FiUser className="icon" />
                </Col>
                <Col className="d-flex flex-column row-gap-10">
                  <label>Resumo da contratação</label>
                  <span>{infosClienteResumo.nome}</span>
                </Col>
                <Col className="d-flex flex-column align-items-start">
                  <span className="valor-contratacao">
                    R$
                    {maskMoney(somatorioResumo.toFixed(2))}
                  </span>
                  <span>Total da contratação</span>
                </Col>
              </Col>
              <Col className="d-flex align-items-center" sm={6}>
                <CustomButton
                  onClick={() => concluirContratacao()}
                  width={'100%'}
                  height={'50px'}
                  letterSpacing={2}
                >
                  CONCLUIR CONTRATAÇÃO
                </CustomButton>
              </Col>
            </Row>
          </ApresentacaoValoresWrapper>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Nome</label>
              <span>{infosClienteResumo.nome}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Gênero</label>
              <span>{infosClienteResumo.sexo}</span>
            </Col>
          </Row>
          <div className="linha-horizontal"></div>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">RG</label>
              <span>{infosClienteResumo.numeroIdentidade}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Orgão emissor</label>
              <span>{infosClienteResumo.emissorIdentidade}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">UF emissor</label>
              <span>{infosClienteResumo.ufIdentidade}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Data de expedição</label>
              <span>{infosClienteResumo.dataEmissao}</span>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">CPF</label>
              <span>{infosClienteResumo.cpf}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Data de nascimento</label>
              <span>{infosClienteResumo.dataNascimento}</span>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Cidade de nascimento</label>
              <span>{infosClienteResumo.cidadeNascimento}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">UF nascimento</label>
              <span>{infosClienteResumo.ufNascimento}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Nacionalidade</label>
              <span>Brasileira</span>
            </Col>
          </Row>
          <div className="linha-horizontal"></div>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Escolaridade</label>
              <span>{infosClienteResumo.grauInstrucao}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Estado civil</label>
              <span>{infosClienteResumo.estadoCivil}</span>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Nome do Cônjuge</label>
              <span>{infosClienteResumo.nomeConjuge}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Nome da mãe</label>
              <span>{infosClienteResumo.nomeMae}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Nome do pai</label>
              <span>{infosClienteResumo.nomePai}</span>
            </Col>
          </Row>
          <div className="linha-horizontal"></div>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">CEP</label>
              <span>{infosClienteResumo.cep}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Número</label>
              <span>{infosClienteResumo.numeroEndereco}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Complemento</label>
              <span>{infosClienteResumo.complemento}</span>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Bairro</label>
              <span>{infosClienteResumo.bairro}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">Cidade</label>
              <span>{infosClienteResumo.cidade}</span>
            </Col>
            <Col className="d-flex flex-column" md={3}>
              <label className="label-12">UF</label>
              <span>{infosClienteResumo.uf}</span>
            </Col>
          </Row>
          <SimpleTab tabs={beneficios} />
          <CustomTable
            data={[...produtosSelecionados]}
            columns={COLUMNS_RESUMO_PRODUTOS}
            paginationMode="client"
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ResumoContratacao

import React from 'react'
import Banner from './components/Banner'
import Simple from 'layouts/simple'
import creditoConsignado1 from 'assets/images/apresentacao/creditoConsignado1.png'
import creditoConsignado2 from 'assets/images/apresentacao/creditoConsignado2.png'
import creditoConsignado3 from 'assets/images/apresentacao/creditoConsignado3.png'
import { ArticleWrapper } from './style'
import { Col, Row } from 'reactstrap'
import FaqCreditoConsignado from './components/FaqCreditoConsignado'

const CreditoConsignado = () => {
  return (
    <Simple>
      <div>
        <Banner
          title={'Crédito Consignado'}
          subtitle={'Contrate de mais fácil e segura'}
          image={creditoConsignado1}
        />
        <ArticleWrapper>
          <div className="container-fluid d-flex flex-column align-items-center">
            <Row className="grid">
              <Col md={6} className="align-items-center p-5 text-right">
                <h4 className="text-center text-md-end">
                  O que é o Crédito Consignado?
                </h4>
                <p className="text-center text-md-end">
                  O empréstimo consignado é uma solução de crédito especialmente
                  projetada para aposentados e pensionistas vinculados ao INSS.
                  Esta modalidade se destaca pela comodidade: as parcelas são
                  deduzidas diretamente do seu salário ou benefício do INSS,
                  simplificando o processo de pagamento. A aprovação está
                  condicionada à existência de margem consignável disponível, o
                  que significa que há um limite seguro estabelecido para
                  garantir que as deduções não comprometem sua renda mensal.
                </p>
              </Col>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <img src={creditoConsignado2} />
              </Col>
            </Row>
            <Row className="grid flex-column-reverse flex-md-row">
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <img src={creditoConsignado3} />
              </Col>
              <Col md={6} className="align-items-center p-5">
                <h4 className="text-center text-md-start">
                  Empréstimo Consignado da Riber é pra você?
                </h4>
                <p className="text-center text-md-start">Quem pode fazer?</p>
                <p className="text-center text-md-start">
                  É um aposentado ou pensionista do INSS – inclusive se estiver
                  com o nome negativado.
                </p>
                <p className="text-center text-md-start">
                  Quem não pode contratar:
                </p>
                <p className="text-center text-md-start">
                  É um servidor público estadual ou municipal – nossa modalidade
                  de consignado é exclusiva para clientes federais.
                </p>
                <p className="text-center text-md-start">
                  Está com pagamentos pendentes como aposentado ou pensionista
                  junto ao INSS – é necessário estar em dia com as obrigações
                  previdenciárias.
                </p>
                <p className="text-center text-md-start">
                  Recebe benefícios do INSS por meio de terceiros – o empréstimo
                  deve ser contratado diretamente pelo beneficiário.
                </p>
                <p className="text-center text-md-start">
                  Recebe benefícios por invalidez do INSS – atualmente, essa
                  categoria de benefício não se qualifica para o nosso serviço
                  de crédito consignado.
                </p>
              </Col>
            </Row>
          </div>
        </ArticleWrapper>
        <FaqCreditoConsignado />
      </div>
    </Simple>
  )
}

export default CreditoConsignado

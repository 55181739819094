import { all } from 'redux-saga/effects'
import landingPageSaga from './landingPage/saga'
import loginSaga from './login/saga'
import simulacaoSaga from './simulacao/saga'
import propostasSaga from './propostas/saga'
import meusDadosSaga from './meusDados/saga'
import calendarioSaga from './utilitarios/calendario/saga'
import calculadoraSaga from './utilitarios/calculadora/saga'
import cadastroInicialSaga from './cadastros/cadastroInicial/saga'
import profissionalSaga from './cadastros/profissional/saga'
import validacaoSaga from './cadastros/validacao/saga'
import dadosComplementaresDadosBancariosSaga from './cadastros/dadosComplementaresDadosBancarios/saga'
import dadosComplementaresDocumentoSaga from './cadastros/dadosComplementaresDocumento/saga'
import dadosComplementaresEnderecoSaga from './cadastros/dadosComplementaresEndereco/saga'
import dadosComplementaresInfoPessoalSaga from './cadastros/dadosComplementaresInfoPessoal/saga'

export default function* rootSaga() {
  yield all([
    landingPageSaga(),
    loginSaga(),
    simulacaoSaga(),
    propostasSaga(),
    meusDadosSaga(),
    calendarioSaga(),
    calculadoraSaga(),
    cadastroInicialSaga(),
    profissionalSaga(),
    validacaoSaga(),
    dadosComplementaresDadosBancariosSaga(),
    dadosComplementaresDocumentoSaga(),
    dadosComplementaresEnderecoSaga(),
    dadosComplementaresInfoPessoalSaga()
  ])
}

import styled from 'styled-components'
const roxoRiber = 'var(--roxo-riber)'

interface CustomTableWrapperProps {
  autoHeight?: boolean
}

export const CustomTableWrapper = styled.div<CustomTableWrapperProps>`
  height: ${(props) => (props.autoHeight ? `100%` : `400px`)};
  min-height: 400px;
  width: 100%;

  .MuiDataGrid-columnHeaders {
    background-color: ${roxoRiber};
  }
`

export const NoDataWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & img {
    height: 200px;
    width: fit-content;
  }

  & h2 {
    text-align: center;
    color: #adb5bd;
    font-size: 1.2rem;
    font-weight: $font-weight-bold;
  }
`

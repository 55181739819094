import { createStore, Store, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './modules/sagas'
import rootReducer from './modules/rootReducer'
import { LOGIN } from './modules/login/types'
import { NAVBAR } from './modules/navbar/types'
import { SIDEBAR } from './modules/sidebar/types'
import { LOADING } from './modules/loading/types'
import { SIMULACAO } from './modules/simulacao/types'
import { PROPOSTAS } from './modules/propostas/types'
import { MEUS_DADOS } from './modules/meusDados/types'
import { CALENDARIO } from './modules/utilitarios/calendario/types'
import { CALCULADORA } from './modules/utilitarios/calculadora/types'
import { CADASTRO_INICIAL } from './modules/cadastros/cadastroInicial/types'
import { PROFISSIONAL } from './modules/cadastros/profissional/types'
import { VALIDACAO } from './modules/cadastros/validacao/types'
import { LANDING_PAGE } from './modules/landingPage/types'
import { DADOS_COMPLEMENTARES_DADOS_BANCARIOS } from './modules/cadastros/dadosComplementaresDadosBancarios/types'
import { DADOS_COMPLEMENTARES_DOCUMENTO } from './modules/cadastros/dadosComplementaresDocumento/types'
import { DADOS_COMPLEMENTARES_ENDERECO } from './modules/cadastros/dadosComplementaresEndereco/types'
import { DADOS_COMPLEMENTARES_INFOS_PESSOAL } from './modules/cadastros/dadosComplementaresInfoPessoal/types'

export interface ApplicationState {
  login: LOGIN
  navbar: NAVBAR
  sidebar: SIDEBAR
  loading: LOADING
  simulacao: SIMULACAO
  propostas: PROPOSTAS
  meusDados: MEUS_DADOS
  calendario: CALENDARIO
  calculadora: CALCULADORA
  cadastroInicial: CADASTRO_INICIAL
  profissional: PROFISSIONAL
  validacao: VALIDACAO
  landingPage: LANDING_PAGE
  dadosComplementaresDadosBancarios: DADOS_COMPLEMENTARES_DADOS_BANCARIOS
  dadosComplementaresDocumento: DADOS_COMPLEMENTARES_DOCUMENTO
  dadosComplementaresEndereco: DADOS_COMPLEMENTARES_ENDERECO
  dadosComplementaresInfosPessoal: DADOS_COMPLEMENTARES_INFOS_PESSOAL
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]
let store: Store

function configureStore() {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const localstore: Store<ApplicationState> = createStore(
    rootReducer,
    {},
    composeEnhancers(applyMiddleware(...middlewares))
  )

  sagaMiddleware.run(rootSaga)
  store = localstore
  return store
}

export type AppDispatch = typeof store.dispatch

export default configureStore()

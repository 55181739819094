import { TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as loadingActions from 'store/modules/loading/actions'
import { LANDING_PAGE } from './types'
import { ApplicationState } from 'store'
import { handlerError } from 'util/handlerError'
import * as cadastroInicialActions from 'store/modules/cadastros/cadastroInicial/actions'
import * as landingPageActions from 'store/modules/landingPage/actions'
import * as landingPageController from 'controller/landingPageController'
import history from 'util/history'
import { removeMaskCPF, removeMaskPhone } from 'util/masks'
import { toast } from 'react-toastify'

interface GenericProps {
  type: string
}

function* iniciar(): SagaIterator {
  const { cpfLanding }: LANDING_PAGE = yield select(
    (state: ApplicationState) => state.landingPage
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpfLanding)
    const response = yield call(landingPageController.validaCpf, cpfFormatted)
    if (response?.data?.content.status === 1) {
      yield put(cadastroInicialActions.setFormularioField(cpfLanding, 'cpf'))
      history.push('/cadastro-inicial')
    } else {
      yield put(landingPageActions.openModalCpf(true))
    }
    yield put(landingPageActions.cleanCpfLanding())
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* sejaParceiro(): SagaIterator {
  const { sejaParceiro }: LANDING_PAGE = yield select(
    (state: ApplicationState) => state.landingPage
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(landingPageController.sejaParceiro, {
      nome: sejaParceiro.nome,
      email: sejaParceiro.email,
      telefone: removeMaskPhone(sejaParceiro.telefone)
    })
    yield put(landingPageActions.cleanSejaParceiro())
    toast.success('Os dados foram enviados com sucesso!')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getEstados(): SagaIterator {
  try {
    yield put(landingPageActions.setLoadingSelect(true, 'estados'))
    const response = yield call(landingPageController.getEstados)
    yield put(
      landingPageActions.setOptionsSelect(response?.data?.content, 'estados')
    )
  } catch (error) {
    //
  } finally {
    yield put(landingPageActions.setLoadingSelect(false, 'estados'))
  }
}

interface GetCidadesProps extends GenericProps {
  siglaEstado: string
}

function* getCidades({ siglaEstado }: GetCidadesProps): SagaIterator {
  try {
    yield put(landingPageActions.setLoadingSelect(true, 'cidades'))
    const response = yield call(landingPageController.getCidades, siglaEstado)
    yield put(
      landingPageActions.setOptionsSelect(response?.data?.content, 'cidades')
    )
  } catch (error) {
    //
  } finally {
    yield put(landingPageActions.setLoadingSelect(false, 'cidades'))
  }
}

function* getRiberMaisPerto(): SagaIterator {
  const { riberMaisPerto }: LANDING_PAGE = yield select(
    (state: ApplicationState) => state.landingPage
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield put(landingPageActions.setRiberMaisPertoList([]))
    const response = yield call(
      landingPageController.getRiberMaisPerto,
      riberMaisPerto.siglaEstado,
      riberMaisPerto.cidade
    )
    yield put(landingPageActions.setRiberMaisPertoList(response?.data?.content))
  } catch (error) {
    handlerError(
      error,
      'Não foi possível buscar as Riber mais próximas de você!'
    )
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getAvisoPrivacidade(): SagaIterator {
  try {
    yield put(landingPageActions.cleanDownloads())
    yield put(landingPageActions.setLoadingDownloads(true, 'pdf'))
    yield put(landingPageActions.setModalPdf(true))
    const response = yield call(landingPageController.getAvisoPrivacidade)
    yield put(
      landingPageActions.setDownloads(
        {
          base64: response?.data?.content,
          nome: 'AVISO DE PRIVACIDADE'
        },
        'pdf'
      )
    )
  } catch (error) {
    handlerError(error, 'Não foi possível obter o aviso de privacidade')
  } finally {
    yield put(landingPageActions.setLoadingDownloads(false, 'pdf'))
  }
}

function* getPoliticaPrivacidade(): SagaIterator {
  try {
    yield put(landingPageActions.cleanDownloads())
    yield put(landingPageActions.setLoadingDownloads(true, 'pdf'))
    yield put(landingPageActions.setModalPdf(true))
    const response = yield call(landingPageController.getPoliticaPrivacidade)
    yield put(
      landingPageActions.setDownloads(
        {
          base64: response?.data?.content,
          nome: 'POLÍTICA DE PRIVACIDADE'
        },
        'pdf'
      )
    )
  } catch (error) {
    handlerError(error, 'Não foi possível obter a política de privacidade')
  } finally {
    yield put(landingPageActions.setLoadingDownloads(false, 'pdf'))
  }
}

function* getTermoPolitica(): SagaIterator {
  try {
    yield put(landingPageActions.cleanDownloads())
    yield put(landingPageActions.setLoadingDownloads(true, 'pdf'))
    yield put(landingPageActions.setModalPdf(true))
    const response = yield call(landingPageController.getTermoPolitica)
    yield put(
      landingPageActions.setDownloads(
        {
          base64: response?.data?.content,
          nome: 'TERMO DE POLÍTICA E USO'
        },
        'pdf'
      )
    )
  } catch (error) {
    handlerError(error, 'Não foi possível obter o termo de política e uso')
  } finally {
    yield put(landingPageActions.setLoadingDownloads(false, 'pdf'))
  }
}

function* iniciarProdutos(): SagaIterator {
  const { cpfProdutos }: LANDING_PAGE = yield select(
    (state: ApplicationState) => state.landingPage
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpfProdutos)
    const response = yield call(landingPageController.validaCpf, cpfFormatted)
    if (response?.data?.content.status === 1) {
      yield put(cadastroInicialActions.setFormularioField(cpfFormatted, 'cpf'))
      history.push('/cadastro-inicial')
    } else {
      yield put(landingPageActions.openModalCpf(true))
    }
    yield put(landingPageActions.cleanCpfProdutos())
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchIniciar() {
  yield takeEvery(TYPES.INICIAR_SAGA, iniciar)
}

export function* watchSejaParceiro() {
  yield takeEvery(TYPES.SEJA_PARCEIRO_SAGA, sejaParceiro)
}

export function* watchGetEstados() {
  yield takeEvery(TYPES.GET_ESTADOS_SAGA, getEstados)
}

export function* watchGetCidades() {
  yield takeEvery(TYPES.GET_CIDADES_SAGA, getCidades)
}

export function* watchGetRiberMaisPerto() {
  yield takeEvery(TYPES.GET_RIBER_MAIS_PERTO_SAGA, getRiberMaisPerto)
}

export function* watchGetAvisoPrivacidade() {
  yield takeEvery(TYPES.GET_AVISO_PRIVACIDADE_SAGA, getAvisoPrivacidade)
}

export function* watchGetPoliticaPrivacidade() {
  yield takeEvery(TYPES.GET_POLITICA_PRIVACIDADE_SAGA, getPoliticaPrivacidade)
}

export function* watchGetTermoPolitica() {
  yield takeEvery(TYPES.GET_TERMO_POLITICA_SAGA, getTermoPolitica)
}

export function* watchIniciarProdutos() {
  yield takeEvery(TYPES.INICIAR_PRODUTOS_SAGA, iniciarProdutos)
}

function* landingPage() {
  yield all([
    fork(watchIniciar),
    fork(watchSejaParceiro),
    fork(watchGetEstados),
    fork(watchGetCidades),
    fork(watchGetRiberMaisPerto),
    fork(watchGetAvisoPrivacidade),
    fork(watchGetPoliticaPrivacidade),
    fork(watchGetTermoPolitica),
    fork(watchIniciarProdutos)
  ])
}

export default landingPage

import { Matricula } from 'models/meusDados/types'
import {
  Banco,
  Convenio,
  Invalid,
  InvalidArray,
  Perfil,
  TipoConta
} from 'models/types'

export enum TYPES {
  SET_FORMULARIO = '@@dadosComplementaresDadosBancarios/SET_FORMULARIO',
  SET_FORMULARIO_FIELD = '@@dadosComplementaresDadosBancarios/SET_FORMULARIO_FIELD',
  CLEAN_FORMULARIO = '@@dadosComplementaresDadosBancarios/CLEAN_FORMULARIO',
  SET_INVALID_FORMULARIO = '@@dadosComplementaresDadosBancarios/SET_INVALID_FORMULARIO',
  SET_INVALID_FORMULARIO_FIELD = '@@dadosComplementaresDadosBancarios/SET_INVALID_FORMULARIO_FIELD',
  SET_INVALID_FORMULARIO_FIELD_ARRAY = '@@dadosComplementaresDadosBancarios/SET_INVALID_FORMULARIO_FIELD_ARRAY',
  CLEAN_INVALIDS_FORMULARIO = '@@dadosComplementaresDadosBancarios/CLEAN_INVALIDS_FORMULARIO',
  SET_ACTIVE_TAB_BENEFICIO = '@@dadosComplementaresDadosBancarios/SET_ACTIVE_TAB_BENEFICIO',
  ADICIONAR_PERFIL = '@@dadosComplementaresDadosBancarios/ADICIONAR_PERFIL',
  REMOVER_PERFIL = '@@dadosComplementaresDadosBancarios/REMOVER_PERFIL',
  SET_OPTIONS_SELECT = '@@dadosComplementaresDadosBancarios/SET_OPTIONS_SELECT',
  SET_LOADING_SELECTS = '@@dadosComplementaresDadosBancarios/SET_LOADING_SELECTS',
  SET_CPF = '@@dadosComplementaresDadosBancarios/SET_CPF',

  GET_DADOS_COMPLEMENTARES_DADOS_BANCARIOS_SAGA = '@@dadosComplementaresDadosBancarios/GET_DADOS_COMPLEMENTARES_DADOS_BANCARIOS_SAGA',
  SALVAR_CADASTRO_SAGA = '@@dadosComplementaresDadosBancarios/SALVAR_CADASTRO_SAGA',
  GET_PERFIS_SAGA = '@@dadosComplementaresDadosBancarios/GET_PERFIS_SAGA',
  GET_TIPOS_CONTA_SAGA = '@@dadosComplementaresDadosBancarios/GET_TIPOS_CONTA_SAGA',
  GET_BANCOS_SAGA = '@@dadosComplementaresDadosBancarios/GET_BANCOS_SAGA',
  GET_CONVENIOS_SAGA = '@@dadosComplementaresDadosBancarios/GET_CONVENIOS_SAGA',
  GET_CPF_SAGA = '@@dadosComplementaresDadosBancarios/GET_CPF_SAGA'
}

export interface DADOS_COMPLEMENTARES_DADOS_BANCARIOS {
  cpf: string
  formulario: FormCadastro
  invalidFormulario: InvalidFormCadastro
  activeTabBeneficio: number
  optionsSelects: OptionsSelects
  loadingSelects: LoadingSelects
}

interface FormCadastro {
  dadosBancarios: MatriculaDadosComplementares[]
}

type InvalidFormCadastro = {
  [K in keyof FormCadastro]: K extends 'dadosBancarios'
    ? InvalidArray<FormCadastro['dadosBancarios'][number]>[]
    : Invalid
}

interface OptionsSelects {
  perfis: Perfil[]
  tiposConta: TipoConta[]
  bancos: Banco[]
  convenios: Convenio[]
}

type LoadingSelects = {
  [k in keyof OptionsSelects]: boolean
}

export type MatriculaDadosComplementares = Omit<
  Matricula,
  'numeroAgencia' | 'digitoVerificadorAgencia' | 'digitoVerificadorConta'
> & {
  agencia: Matricula['numeroAgencia']
  digitoConta: Matricula['digitoVerificadorConta']
  digitoAgencia: Matricula['digitoVerificadorAgencia']
}

import React, { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Collapse } from 'reactstrap'
import { CardFaq, FaqWrapper } from '../style'
import imageFaq from 'assets/images/apresentacao/cartaoConsignado4.png'

const FaqCartaoConsignado = () => {
  const [cardsState, setCardsState] = useState({
    firstCard: false,
    secondCard: false,
    thirdCard: false,
    fourthCard: false,
    fifthCard: false,
    sixthCard: false,
    seventhCard: false,
    eighthCard: false,
    ninthCard: false,
    tenthCard: false
  })

  const toggleCardVisibility = (cardName: keyof typeof cardsState) => {
    setCardsState((prevCards) => ({
      ...prevCards,
      [cardName]: !prevCards[cardName]
    }))
  }

  return (
    <FaqWrapper backgroundImage={imageFaq}>
      <div className="d-none d-lg-flex">
        <img className="img-faq" src={imageFaq} />
      </div>
      <div className="faq">
        <h3>Perguntas Frequentes</h3>
        <CardFaq>
          <label>
            Quem pode solicitar um cartão de crédito consignado?
            {cardsState.firstCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('firstCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('firstCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.firstCard}>
            <p>
              Aposentados e pensionistas do INSS, além de servidores públicos,
              podem geralmente solicitar cartões de crédito consignados.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Como funciona a margem consignável no cartão de crédito?
            {cardsState.secondCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('secondCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('secondCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.secondCard}>
            <p>
              A margem consignável no cartão de crédito é uma porcentagem do
              salário ou benefício destinada ao pagamento da fatura, geralmente
              essa margem é de 5%.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Quais são os documentos necessários para solicitar o cartão de
            crédito consignado?
            {cardsState.thirdCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('thirdCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('thirdCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.thirdCard}>
            <p>
              Documento de identidade, CPF, comprovante de residência e o último
              contracheque ou extrato do benefício.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            É possível ter mais de um cartão de crédito consignado ao mesmo
            tempo?
            {cardsState.fourthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('fourthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('fourthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.fourthCard}>
            <p>
              Sim, desde que a soma dos descontos não ultrapasse a margem
              consignável disponível.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Quais são as vantagens do cartão de crédito consignado em relação
            aos cartões tradicionais?
            {cardsState.fifthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('fifthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('fifthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.fifthCard}>
            <p>
              As taxas de juros costumam ser mais baixas, e o pagamento mínimo é
              descontado automaticamente, evitando o acúmulo de dívidas.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            O cartão de crédito consignado tem anuidade?
            {cardsState.sixthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('sixthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('sixthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.sixthCard}>
            <p>
              Alguns bancos isentam o cartão de crédito consignado de anuidade,
              mas isso pode variar.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Como é feito o pagamento da fatura do cartão de crédito consignado?
            {cardsState.seventhCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('seventhCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('seventhCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.seventhCard}>
            <p>
              O pagamento mínimo é descontado automaticamente do salário ou
              benefício do solicitante.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            É possível sacar dinheiro com o cartão de crédito consignado?
            {cardsState.eighthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('eighthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('eighthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.eighthCard}>
            <p>
              Alguns cartões permitem saques, mas é importante estar ciente das
              taxas e condições associadas.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            O que acontece em caso de atraso no pagamento da fatura?
            {cardsState.ninthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('ninthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('ninthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.ninthCard}>
            <p>
              O atraso pode resultar em multas, juros e bloqueio do valor
              correspondente na margem consignável.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Como solicitar um cartão de crédito consignado?
            {cardsState.tenthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('tenthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('tenthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.tenthCard}>
            <p>
              A solicitação pode ser feita no aqui pelo nosso site ou pelo nosso
              0800 941 1111 ou instituição financeira que oferece esse tipo de
              cartão, apresentando a documentação necessária.
            </p>
          </Collapse>
        </CardFaq>
      </div>
    </FaqWrapper>
  )
}

export default FaqCartaoConsignado

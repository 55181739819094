/* eslint-disable @typescript-eslint/no-explicit-any */
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import config from 'config'

const AUTH_SESSION_KEY = 'TOKEN_KEY'

const token = sessionStorage.getItem(AUTH_SESSION_KEY)

const instance = axios.create({
  baseURL: config.API_SIMULADOR,
  headers: {
    Authorization: 'Bearer ' + token,
    ContentType: 'application/json'
  }
})

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    let message
    if (error && error.response && error.response.status === 404) {
      // window.location.href = '/not-found';
    } else if (error && error.response && error.response.status === 403) {
      // window.location.href = '/access-denied'
    } else {
      switch (error.response.status) {
        case 400:
          message = error.response.data
          break
        case 401:
          message = 'Invalid credentials'
          break
        case 403:
          message = 'Access Forbidden'
          break
        case 404:
          message = 'Sorry! the data you are looking for could not be found'
          break
        case 500:
          // window.location.href = '../message/error-500'
          break
        default: {
          message =
            error.response && error.response.data
              ? error.response.data['message']
              : error.message || error
        }
      }
      return Promise.reject(message)
    }
  }
)

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) {
    sessionStorage.setItem(AUTH_SESSION_KEY, token)
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token
  } else {
    sessionStorage.removeItem(AUTH_SESSION_KEY)
    delete instance.defaults.headers.common['Authorization']
  }
}

class APICore {
  get = (url: string, params?: any) => {
    let response
    if (params) {
      const queryString = params
        ? Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')
        : ''
      response = instance.get(`${url}?${queryString}`, params)
    } else {
      response = instance.get(`${url}`, params)
    }
    return response
  }

  getWithoutUndefined = (url: string, params: any) => {
    let response
    if (params) {
      const queryString = params
        ? Object.keys(params)
            .map((key) =>
              params[key] || params[key] === false
                ? key + '=' + params[key]
                : ''
            )
            .filter(Boolean)
            .join('&')
        : ''
      response = instance.get(`${url}?${queryString}`, params)
    } else {
      response = instance.get(`${url}`, params)
    }
    return response
  }

  getFile = (url: string, params: any) => {
    let response
    if (params) {
      const queryString = params
        ? Object.keys(params)
            .map((key) => key + '=' + params[key])
            .join('&')
        : ''
      response = instance.get(`${url}?${queryString}`, { responseType: 'blob' })
    } else {
      response = instance.get(`${url}`, { responseType: 'blob' })
    }
    return response
  }

  create = (url: string, data: any) => {
    return instance.post(url, data)
  }

  updatePatch = (url: string, data: any) => {
    return instance.patch(url, data)
  }

  update = (url: string, data: any) => {
    return instance.put(url, data)
  }

  delete = (url: string) => {
    return instance.delete(url)
  }

  createWithFile = (url: string, data: any) => {
    const formData = new FormData()
    for (const k in data) {
      formData.append(k, data[k])
    }

    const config: any = {
      headers: {
        ...instance.defaults.headers,
        'content-type': 'multipart/form-data'
      }
    }
    return instance.post(url, formData, config)
  }

  updateWithFile = (url: string, data: any) => {
    const formData = new FormData()
    for (const k in data) {
      formData.append(k, data[k])
    }

    const config: any = {
      headers: {
        ...instance.defaults.headers,
        'content-type': 'multipart/form-data'
      }
    }
    return instance.patch(url, formData, config)
  }

  isAuthenticated = () => {
    const token = sessionStorage.getItem(AUTH_SESSION_KEY)

    if (!token) {
      return false
    }
    const decoded: any = jwtDecode(token)
    const currentTime = Date.now() / 1000
    if (decoded.exp < currentTime) {
      console.warn('access token expired')
      return false
    } else {
      return true
    }
  }
}

export { APICore, setAuthorization }

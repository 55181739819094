import { validaCpf } from 'util/validations'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)
export const schemaCpfLanding = yup.object().shape({
  cpf: yup
    .string()
    .test('test-invalid-cpf', 'Cpf inválido', (cpf) => validaCpf(cpf || ''))
    .required()
    .label('Cpf')
})

export const schemaSejaParceiro = yup.object().shape({
  nome: yup.string().required().min(3).max(100).label('Nome'),
  email: yup.string().email().required().label('Email'),
  telefone: yup.string().required().min(10).max(11).label('Telefone')
})

export const schemaCpfProdutos = yup.object().shape({
  cpf: yup
    .string()
    .test('test-invalid-cpf', 'Cpf inválido', (cpf) => validaCpf(cpf || ''))
    .required()
    .label('Cpf')
})

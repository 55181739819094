import React, { useEffect } from 'react'
import SimpleCadastro from 'components/SimpleCadastro/SimpleCadastro'
import primeiroCadastro from 'assets/images/cadastros/primeiro-cadastro.png'
import servidor from 'assets/images/cadastros/servidor.png'
import aposentado from 'assets/images/cadastros/aposentado.png'
import clt from 'assets/images/cadastros/clt.png'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { PROFISSIONAL } from 'store/modules/cadastros/profissional/types'
import Aposentado from './components/Steps/Aposentado'
import Servidor from './components/Steps/Servidor'
import * as profissionalActions from 'store/modules/cadastros/profissional/actions'
import useRedux from 'hooks/useRedux'
import SelecaoPerfil from './components/Steps/SelecaoPerfil'
import Clt from './components/Steps/Clt'

const Profissional = () => {
  const { tipoPerfil } = useSelector<ApplicationState, PROFISSIONAL>(
    (state) => state.profissional
  )

  const { dispatch } = useRedux()

  useEffect(() => {
    dispatch(profissionalActions.getPerfisSaga())
  }, [dispatch])

  switch (tipoPerfil) {
    case 1:
      return (
        <SimpleCadastro
          banner={aposentado}
          title="Aposentado e pensionista"
          subTitle="Preencha os campos ao lado para fazer sua 
simulação de  crédito!"
        >
          <Aposentado />
        </SimpleCadastro>
      )
    case 2:
      return (
        <SimpleCadastro
          banner={clt}
          title="CLT"
          subTitle="Preencha os campos ao lado para fazer sua 
simulação de  crédito!"
        >
          <Clt />
        </SimpleCadastro>
      )
    case 3:
      return (
        <SimpleCadastro
          banner={servidor}
          title="Servidor Público Municipal"
          subTitle="Preencha os campos ao lado para fazer sua 
simulação de  crédito!"
        >
          <Servidor />
        </SimpleCadastro>
      )
    case 4:
      return (
        <SimpleCadastro
          banner={servidor}
          title="Servidor Público Federal"
          subTitle="Preencha os campos ao lado para fazer sua 
simulação de  crédito!"
        >
          <Servidor />
        </SimpleCadastro>
      )
    case 5:
      return (
        <SimpleCadastro
          banner={servidor}
          title="Servidor Público Estadual"
          subTitle="Preencha os campos ao lado para fazer sua 
simulação de  crédito!"
        >
          <Servidor />
        </SimpleCadastro>
      )
    default:
      return (
        <SimpleCadastro
          banner={primeiroCadastro}
          title="Simulação de crédito"
          subTitle="Preencha os campos ao lado para fazer sua 
simulação de  crédito!"
        >
          <SelecaoPerfil />
        </SimpleCadastro>
      )
  }
}

export default Profissional

import { APICore } from 'helpers/api/apiCore'
import { DadosComplementaresDadosBancariosReq } from 'models/cadastros/dadosComplementaresDadosBancarios/types'

const api = new APICore()

export function getDadosComplementaresDadosBancarios(cpf: string) {
  return api.get(`DadosComplementares/dados-bancarios`, {
    cpf
  })
}

type Cadastro = DadosComplementaresDadosBancariosReq & {
  cpf: string
}
export function salvarCadastro(params: Cadastro) {
  return api.update(`DadosComplementares/dados-bancarios`, params)
}

export function getPerfis() {
  return api.get(`Generic/perfis`, null)
}

export function getTiposConta() {
  return api.get(`Generic/tipos-conta`, null)
}

export function getBancos() {
  return api.get(`Generic/bancos-dados-bancarios`, null)
}

export function getConvenios() {
  return api.get(`Generic/convenios`, null)
}

export function getCpf() {
  return api.get(`Generic/informacoes-cliente-matricula`, null)
}

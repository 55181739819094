import React, { useState, useMemo } from 'react'
import SeletorPerfil from 'components/SeletorPerfil'
import Steps from 'components/Steps'
import useRedux from 'hooks/useRedux'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { ApplicationState } from 'store'
import { PROFISSIONAL } from 'store/modules/cadastros/profissional/types'
import { CustomButton } from 'styles/global'
import { STEPS_CADASTRO } from 'views/cadastros/constants/constants'
import * as profissionalActions from 'store/modules/cadastros/profissional/actions'

const SelecaoPerfil = () => {
  const { optionsSelect } = useSelector<ApplicationState, PROFISSIONAL>(
    (state) => state.profissional
  )

  const [perfil, setPerfil] = useState<number | null>(null)

  const { dispatch } = useRedux()

  const ordemPersonalizada = useMemo(
    () => [
      'Aposentado e Pensionista',
      'SERVIDOR PUBLICO MUNICIPAL',
      'CLT',
      'SERVIDOR PUBLICO ESTADUAL',
      'AUTONOMO',
      'SERVIDOR PUBLICO FEDERAL'
    ],
    []
  )

  const perfisOrdenados = useMemo(() => {
    return optionsSelect.perfis.slice().sort((a, b) => {
      const indexA = ordemPersonalizada.indexOf(a.descricao)
      const indexB = ordemPersonalizada.indexOf(b.descricao)
      return indexA - indexB
    })
  }, [optionsSelect.perfis, ordemPersonalizada])

  return (
    <div className="container-fluid d-flex flex-column row-gap-20">
      <Steps steps={STEPS_CADASTRO} currentStep={2} />
      <Row>
        <Col>
          <SeletorPerfil
            perfis={perfisOrdenados}
            size={6}
            onChange={(e) => {
              setPerfil(e)
            }}
            value={perfil}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          <CustomButton
            background="var(--roxo-riber)"
            width="100%"
            padding="20"
            height="50px"
            borderRadius={21}
            onClick={() => {
              perfil && dispatch(profissionalActions.setTipoPerfil(perfil))
            }}
            disabled={!perfil}
          >
            AVANÇAR
          </CustomButton>
        </Col>
      </Row>
    </div>
  )
}

export default SelecaoPerfil

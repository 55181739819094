import React from 'react'
import InputCustom from 'components/Inputs'
import { Col, Row } from 'reactstrap'
import { CustomButton } from 'styles/global'
import { BannerWrapper, CardSimulacao } from '../style'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import { LANDING_PAGE } from 'store/modules/landingPage/types'
import useRedux from 'hooks/useRedux'
import * as landingPageActions from 'store/modules/landingPage/actions'
import { maskCPF, removeMaskCPF } from 'util/masks'
import { schemaCpfLanding } from '../constants/schema'
import * as yup from 'yup'
import CPFJaCadastradoModal from 'views/produtos/components/modalCPF'

const Banner = () => {
  const { cpfLanding, cpfLandingInvalid, modalCpf } = useSelector<
    ApplicationState,
    LANDING_PAGE
  >((state) => state.landingPage)

  const { dispatch } = useRedux()

  function onChange(value: string) {
    dispatch(landingPageActions.setCpfLanding(value))
  }

  function onIniciar() {
    dispatch(landingPageActions.cleanCpfLandingInvalid())
    schemaCpfLanding
      .validate(
        {
          cpf: removeMaskCPF(cpfLanding)
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(landingPageActions.iniciarSaga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            landingPageActions.setCpfLandingInvalid({
              invalid: true,
              message: e.message
            })
          )
        })
      })
  }
  function onRedirectToLogin() {
    window.location.href = '/login'
  }

  return (
    <BannerWrapper>
      <h2>Crédito seguro,</h2>
      <h3>Digital e ideal para o seu perfil</h3>
      <CardSimulacao>
        <div className="titulos">
          <label>Digite seu CPF</label>
          <span>e encontre a melhor oferta</span>
        </div>
        <div className="container-fluid">
          <Row className="w-100">
            <Col>
              <InputCustom
                placeholder="000.000.000.00"
                onChange={(e) => {
                  onChange(maskCPF(e.target.value))
                }}
                value={cpfLanding}
                invalid={cpfLandingInvalid.invalid}
                errorMessage={cpfLandingInvalid.message}
              />
            </Col>
          </Row>
        </div>
        <CustomButton
          letterSpacing={2}
          background="var(--roxo-riber)"
          onClick={() => {
            onIniciar()
          }}
          width={300}
        >
          INICIAR SIMULAÇÃO
        </CustomButton>
      </CardSimulacao>
      <CPFJaCadastradoModal
        isOpen={modalCpf}
        toggle={() => dispatch(landingPageActions.openModalCpf(false))}
        onRedirectToLogin={onRedirectToLogin}
      />
    </BannerWrapper>
  )
}

export default Banner

import { cpf } from 'cpf-cnpj-validator'

export function validaCpf(value: string) {
  return cpf.isValid(value || '') ? true : false
}

type Nullable<T> = T | null | undefined

export function isNullOrEmpty(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: Nullable<string> | Nullable<object> | Nullable<any[]>
): boolean {
  if (value === null || value === undefined) {
    return true
  }

  if (typeof value === 'string') {
    return value.trim() === ''
  }

  if (Array.isArray(value)) {
    return value.length === 0
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0
  }

  return false
}

import { TYPES } from './types'

export const setPropostasJaContratadas = (value: string) => ({
  type: TYPES.SET_PROPOSTAS_JA_CONTRATADAS,
  value
})

export const cleanPropostasJaContratadas = () => ({
  type: TYPES.CLEAN_PROPOSTAS_JA_CONTRATADAS
})

export const getPropostasJaContratadasSaga = () => ({
  type: TYPES.GET_PROPOSTAS_JA_CONTRATADAS_SAGA
})

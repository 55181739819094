import { APICore } from 'helpers/api/apiCore'
import {
  RequestInserirSolicitacaoDto,
  RequestPesquisaIn100Dto,
  RequestValidacaoTokenIn100Dto
} from 'models/cadastros/validacao/types'

const api = new APICore()

export function inserirSolicitacaoIn100(params: RequestInserirSolicitacaoDto) {
  return api.create(`In100/inserir-solicitacao`, params)
}

export function pesquisarSolicitacaoIn100(params: RequestPesquisaIn100Dto) {
  return api.create(`In100/pesquisar-solicitacao`, params)
}

export function validarTokenIn100(params: RequestValidacaoTokenIn100Dto) {
  return api.create(`In100/validar-token`, params)
}

export function getTelefoneMatricula() {
  return api.get(`Generic/telefones`, null)
}

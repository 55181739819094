import React from 'react'
import { Route, Routes } from 'react-router-dom'
import RouteGenerator from 'components/RouteGenerator'
import NovaSenha from 'views/login/NovaSenha'
import Login from 'views/login'
import NotFound from 'views/notFound'
import LandingPage from 'views/landingPage'
import CadastroInicial from 'views/cadastros/Steps/CadastroInicial'
import CreditoConsignado from 'views/produtos/CreditoConsignado'
import Fgts from 'views/produtos/Fgts'
import CartaoConsignado from 'views/produtos/CartaoConsignado'
import Produtos from 'views/produtos'
import CreditoPessoal from 'views/produtos/CreditoPessoal'

const AppRoutes = () => {
  return (
    <Routes>
      {RouteGenerator()}
      <Route path="/nova-senha/:id" element={<NovaSenha />} />
      <Route path="/Login" element={<Login />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/cadastro-inicial" element={<CadastroInicial />} />
      <Route path="/produtos">
        <Route index element={<Produtos />} />
        <Route path="credito-consignado" element={<CreditoConsignado />} />
        <Route path="cartao-consignado" element={<CartaoConsignado />} />
        <Route path="FGTS" element={<Fgts />} />
        <Route path="credito-pessoal" element={<CreditoPessoal />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes

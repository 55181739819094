import React from 'react'
import { EnumProdutos } from 'models/enums'
import { Col, Row } from 'reactstrap'
import { CardPropostaWrapper } from 'views/propostas/style'
import { PROPOSTAS } from 'store/modules/propostas/types'
import { maskDate, maskMoney } from 'util/masks'

interface CardPropostaProps {
  proposta: PROPOSTAS['propostas'][number]
}

const CardProposta = ({ proposta }: CardPropostaProps) => {
  switch (proposta.produto) {
    case 10:
      return (
        <CardPropostaWrapper>
          <Row className="d-flex row-gap-20">
            <Col md>
              <div className="d-flex flex-column">
                <label>Produto:</label>
                <span className="strong-black">
                  {EnumProdutos[proposta.produto]}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Quantidade de parcelas:</label>
                <span className="strong-green">
                  {proposta.quantidadeParcelas}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Valor da parcela:</label>
                <span className="strong-green">
                  R$ {maskMoney(proposta.valorParcela?.toFixed(2))}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Valor total:</label>
                <span className="strong-green">
                  R$ {maskMoney(proposta.valorTotal?.toFixed(2))}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Proposta contratada em:</label>
                <span className="strong-green">
                  {maskDate(proposta.dataCriacao, 'DD/MM/YYYY')}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Status:</label>
                <span className="strong-green">{proposta.statusProposta}</span>
              </div>
            </Col>
          </Row>
        </CardPropostaWrapper>
      )
    case 80:
      return (
        <CardPropostaWrapper>
          <Row className="d-flex row-gap-20">
            <Col md>
              <div className="d-flex flex-column">
                <label>Produto:</label>
                <span className="strong-black">
                  {EnumProdutos[proposta.produto]}
                </span>
              </div>
            </Col>
            <Col md></Col>
            <Col md></Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Valor total:</label>
                <span className="strong-green">
                  R$ {maskMoney(proposta.valorTotal?.toFixed(2))}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Proposta contratada em:</label>
                <span className="strong-green">
                  {maskDate(proposta.dataCriacao, 'DD/MM/YYYY')}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Status:</label>
                <span className="strong-green">{proposta.statusProposta}</span>
              </div>
            </Col>
          </Row>
        </CardPropostaWrapper>
      )
    case 60:
      return (
        <CardPropostaWrapper>
          <Row className="d-flex row-gap-20">
            <Col md>
              <div className="d-flex flex-column">
                <label>Produto:</label>
                <span className="strong-black">
                  {EnumProdutos[proposta.produto]}
                </span>
              </div>
            </Col>
            <Col md></Col>
            <Col md></Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Valor total:</label>
                <span className="strong-green">
                  R$ {maskMoney(proposta.valorTotal?.toFixed(2))}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Proposta contratada em:</label>
                <span className="strong-green">
                  {maskDate(proposta.dataCriacao, 'DD/MM/YYYY')}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Status:</label>
                <span className="strong-green">{proposta.statusProposta}</span>
              </div>
            </Col>
          </Row>
        </CardPropostaWrapper>
      )
    case 70:
      return (
        <CardPropostaWrapper>
          <Row className="d-flex row-gap-20">
            <Col md>
              <div className="d-flex flex-column">
                <label>Produto:</label>
                <span className="strong-black">
                  {EnumProdutos[proposta.produto]}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Quantidade de parcelas:</label>
                <span className="strong-green">
                  {proposta.quantidadeParcelas}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Valor da parcela:</label>
                <span className="strong-green">
                  R$ {maskMoney(proposta.valorParcela?.toFixed(2))}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Valor total:</label>
                <span className="strong-green">
                  R$ {maskMoney(proposta.valorTotal?.toFixed(2))}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Proposta contratada em:</label>
                <span className="strong-green">
                  {maskDate(proposta.dataCriacao, 'DD/MM/YYYY')}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Status:</label>
                <span className="strong-green">{proposta.statusProposta}</span>
              </div>
            </Col>
          </Row>
        </CardPropostaWrapper>
      )
    case 90:
      return (
        <CardPropostaWrapper>
          <Row className="d-flex row-gap-20">
            <Col md>
              <div className="d-flex flex-column">
                <label>Produto:</label>
                <span className="strong-black">
                  {EnumProdutos[proposta.produto]}
                </span>
              </div>
            </Col>
            <Col md></Col>
            <Col md></Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Valor total:</label>
                <span className="strong-green">
                  R$ {maskMoney(proposta.valorTotal?.toFixed(2))}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Proposta contratada em:</label>
                <span className="strong-green">
                  {maskDate(proposta.dataCriacao, 'DD/MM/YYYY')}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Status:</label>
                <span className="strong-green">{proposta.statusProposta}</span>
              </div>
            </Col>
          </Row>
        </CardPropostaWrapper>
      )
    default:
      return (
        <CardPropostaWrapper>
          <Row className="d-flex row-gap-20">
            <Col md>
              <div className="d-flex flex-column">
                <label>Produto:</label>
                <span className="strong-black">
                  {EnumProdutos[proposta.produto]}
                </span>
              </div>
            </Col>
            <Col md></Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Valor da parcela:</label>
                <span className="strong-green">
                  R$ {maskMoney(proposta.valorParcela?.toFixed(2))}
                </span>
              </div>
            </Col>
            <Col md></Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Proposta contratada em:</label>
                <span className="strong-green">
                  {maskDate(proposta.dataCriacao, 'DD/MM/YYYY')}
                </span>
              </div>
            </Col>
            <Col md>
              <div className="d-flex flex-column">
                <label>Status:</label>
                <span className="strong-green">{proposta.statusProposta}</span>
              </div>
            </Col>
          </Row>
        </CardPropostaWrapper>
      )
  }
}

export default CardProposta

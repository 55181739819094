import { TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as loadingActions from 'store/modules/loading/actions'
import { CALCULADORA } from './types'
import { ApplicationState } from 'store'
import { handlerError } from 'util/handlerError'
import { toast } from 'react-toastify'

function* sagaDefault(): SagaIterator {
  const { defaultValue }: CALCULADORA = yield select(
    (state: ApplicationState) => state.calculadora
  )
  try {
    yield put(loadingActions.setLoading(true))
    toast.success(defaultValue)
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchSagaDefault() {
  yield takeEvery(TYPES.DEFAULT_SAGA, sagaDefault)
}

function* calculadora() {
  yield all([fork(watchSagaDefault)])
}

export default calculadora

import SimpleTab from 'components/SimpleTab/SimpleTab'
import React, { useEffect, useState } from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { FiGlobe, FiDownload } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { matriculaSchema } from '../constants/schema'
import { SimpleTitleCard } from 'styles/global'
import BeneficiosTabs from './BeneficiosTabs'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { MEUS_DADOS } from 'store/modules/meusDados/types'
import * as meusDadosActions from 'store/modules/meusDados/actions'
import * as simulacaoActions from 'store/modules/simulacao/actions'
import useRedux from 'hooks/useRedux'
import EscolhaPerfil from './EscolhaPerfil'
import { removeMaskMoney } from 'util/masks'
import { toast } from 'react-toastify'
import ModalIN100 from './../../simulacao/components/Modals/ModalIN100'
import {
  MatriculaReq,
  MeusDados as MeusDadosType
} from 'models/meusDados/types'
import { isNullOrEmpty } from '../../../util/validations'
import * as yup from 'yup'

const Beneficios = () => {
  const {
    meusDados,
    activeTabBeneficio,
    optionsSelects,
    loadingSelects,
    modalIN100
  } = useSelector<ApplicationState, MEUS_DADOS>((state) => state.meusDados)

  const { dispatch } = useRedux()

  const [beneficios, setBeneficios] = useState<
    {
      id: number
      descricao: string
      component: React.ReactNode
    }[]
  >([])

  const [openModalBeneficio, setOpenModalBeneficio] = useState(false)

  useEffect(() => {
    const matriculas: typeof beneficios = []
    meusDados.matriculas?.map((matricula, i) => {
      matriculas.push({
        id: i,
        descricao: String(
          matricula.numeroMatricula ||
            matricula.descricaoPerfil ||
            'Descrição Indisponível'
        ),
        component: (
          <BeneficiosTabs matricula={meusDados.matriculas?.[i]} index={i} />
        )
      })
    })
    setBeneficios(matriculas)
  }, [meusDados.matriculas])

  useEffect(() => {
    dispatch(meusDadosActions.getInformacoesSaga())
  }, [dispatch])

  const onSaveMatricula = () => {
    const matriculaParaValidar: MatriculaReq[] = meusDados.matriculas.map(
      (matricula) => ({
        id: matricula.id,
        numeroMatricula: matricula.numeroMatricula,
        tipoPerfil: matricula.tipoPerfil,
        descricaoPerfil: matricula.descricaoPerfil,
        tipoPerfilId: matricula.tipoPerfilId,
        convenioId: matricula.convenioId,
        margemDisponivel: Number(removeMaskMoney(matricula.margemDisponivel)),
        margemCartaoDisponivel: Number(
          removeMaskMoney(matricula.margemCartaoDisponivel)
        ),
        margemCartaoBeneficio: Number(
          removeMaskMoney(matricula.margemCartaoBeneficio)
        ),
        valorRendaIndividual: Number(
          removeMaskMoney(matricula.valorRendaIndividual)
        ),
        contaCorrenteId: matricula.contaCorrenteId,
        tipoContaId: matricula.tipoContaId,
        bancoDadosBancariosId: matricula.bancoDadosBancariosId,
        numeroAgencia: matricula.numeroAgencia ?? '',
        digitoVerificadorAgencia: matricula.digitoVerificadorAgencia ?? '',
        numeroConta: matricula.numeroConta ?? '',
        digitoVerificadorConta: matricula.digitoVerificadorConta ?? ''
      })
    )

    matriculaSchema
      .validate(
        { matriculas: matriculaParaValidar },
        { abortEarly: false, recursive: true }
      )
      .then(() => {
        if (isNullOrEmpty(matriculaParaValidar)) {
          toast.warn(
            'Por favor, lembre-se de preencher todos os dados antes de salvar.'
          )
        } else {
          dispatch(meusDadosActions.salvarMatriculaSaga(matriculaParaValidar))
          dispatch(simulacaoActions.validaStatusIN100Saga())
        }
      })
      .catch((err) => {
        const errors = err.inner
        dispatch(meusDadosActions.cleanInvalidMeusDados())
        errors.forEach((e: yup.ValidationError) => {
          if (e.path?.includes('[')) {
            const field = e.path.split('[')
            const indexArray = e.path.match(/\[(.*?)\]/)?.[1]
            const nestedFieldName = e.path.split('.')
            dispatch(
              meusDadosActions.setInvalidMeusDadosFieldArray(
                { invalid: true, message: e.message },
                field[0] as keyof MeusDadosType,
                Number(indexArray),
                nestedFieldName[1] as never
              )
            )
            dispatch(meusDadosActions.setActiveTabBeneficio(Number(indexArray)))
          } else {
            dispatch(
              meusDadosActions.setInvalidMeusDadosField(
                { invalid: true, message: e.message },
                e.path as keyof MeusDadosType
              )
            )
          }
        })

        toast.warn('Preencha todos os campos obrigatórios para salvar!')
      })
  }

  return (
    <>
      <Row>
        <Col>
          <SimpleTitleCard>
            <FiGlobe className="icon" /> Matriculas
          </SimpleTitleCard>
        </Col>
      </Row>
      <SimpleTab
        tabs={beneficios}
        activeTab={activeTabBeneficio}
        actionSetTab={(e) => {
          dispatch(meusDadosActions.setActiveTabBeneficio(e))
        }}
      />
      <Row>
        <Col className="d-flex">
          <div
            onClick={() => {
              setOpenModalBeneficio(true)
            }}
            className="btn-outline-purple my-2"
          >
            <AiOutlinePlusCircle />
            Adicionar Matricula
          </div>
          <Col className="d-flex justify-content-end">
            <div
              onClick={() => {
                onSaveMatricula()
              }}
              className="btn-outline-purple my-2 justify-content-center"
            >
              <FiDownload /> Salvar Matricula
            </div>
          </Col>
        </Col>
      </Row>
      <EscolhaPerfil
        isOpen={openModalBeneficio}
        onToggle={() => {
          setOpenModalBeneficio(!openModalBeneficio)
        }}
        onAdicionar={(e) => {
          dispatch(meusDadosActions.adicionarPerfil(e))
        }}
        perfis={optionsSelects.perfis}
        isLoading={loadingSelects.perfis}
      />
      <ModalIN100
        isOpen={modalIN100}
        toggle={() => {
          dispatch(meusDadosActions.toggleModalIN100(false))
        }}
      />
    </>
  )
}

export default Beneficios

import { APICore } from 'helpers/api/apiCore'

const api = new APICore()

export function stepCadastroComplementarRedirect(status: number) {
  const routeMappings: Record<number, string> = {
    1: '/dados-complementares-documento',
    2: '/dados-complementares-info-pessoal',
    3: '/dados-complementares-endereco',
    4: '/dados-complementares-dados-bancarios'
  }

  let redirectTo = window.location.pathname.toLowerCase()

  if (
    (!routeMappings[status] &&
      Object.values(routeMappings).some(
        (route) => route === window.location.pathname.toLowerCase()
      )) ||
    api.isAuthenticated()
  ) {
    redirectTo = '/simulacao'
  }

  if (routeMappings[status]) {
    redirectTo = routeMappings[status]
  }

  if (window.location.pathname.toLowerCase() !== redirectTo) {
    window.location.replace(redirectTo)
  }
}

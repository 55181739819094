import { toast } from 'react-toastify'

interface Indicacao {
  mensagem: string
  indicacao: number
}

export function handlerErrorFgts(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  defaultMessage?: string,
  autoClose?: number | false | undefined
) {
  if (error?.messages && !error?.stack) {
    error?.messages.map((e: Indicacao) => {
      toast.error('FGTS: ' + e?.mensagem, {
        bodyClassName: 'text-break',
        autoClose: autoClose
      })
    })
  } else {
    defaultMessage
      ? toast.error(defaultMessage, {
          bodyClassName: 'text-break',
          autoClose: autoClose
        })
      : toast.error('FGTS: Não foi possível!', {
          bodyClassName: 'text-break',
          autoClose: autoClose
        })
  }
}

import styled from 'styled-components'

interface LayoutCadastroProps {
  banner: string
}

export const LayoutCadastro = styled.section<LayoutCadastroProps>`
  display: flex;
  height: calc(100vh - 60px);

  .banner {
    align-items: center;
    color: var(--branco);
    background: url(${(props) => props.banner}) no-repeat 100% 0%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 80px;
    position: fixed;
    width: 40vw;

    h1 {
      font-size: 40px;
      font-weight: 700;
      text-align: center;
    }

    h2 {
      font-size: 18px;
      font-weight: 400;
      text-align: center;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    height: 100%;
    left: calc(40vw - 20px);
    padding: 5vh 5vw;
    position: relative;
    width: 60vw;

    .titles-main {
      align-items: center;
      display: none;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      h1 {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
      }

      h2 {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
      }
    }
  }

  @media (max-width: 991px) {
    .banner {
      display: none;
    }

    .main {
      left: 0;
      width: 100%;

      .titles-main {
        display: flex;
      }
    }
  }
`

import React from 'react'
import { VantagensWrapper } from '../style'
import vantagens from 'assets/images/landing2.png'
import iconListVantagens from 'assets/svg/icon-list-vantagens.svg'

const Vantagens = () => {
  return (
    <VantagensWrapper id="emprestimos">
      <div>
        <label>
          Vantagens para contratar <br /> seu <span>empréstimo na Riber</span>
        </label>
        <ul>
          <li>
            <img src={iconListVantagens} />
            <span>
              <label>Processo Ágil e Digital </label>
              Solicitação de crédito rápida e totalmente online. Facilidade e
              comodidade para acessar os serviços de qualquer lugar, a qualquer
              hora.
            </span>
          </li>
          <li>
            <img src={iconListVantagens} />
            <span>
              <label>Produtos Personalizados</label>
              Soluções de crédito desenhadas especialmente para atender às
              necessidades do público brasileiro.
            </span>
          </li>
          <li>
            <img src={iconListVantagens} />
            <span>
              <label>Segurança Garantida</label>
              Proteção robusta de dados com tecnologia antifraude. Operações
              realizadas sob estritas normas de segurança financeira.
            </span>
          </li>
        </ul>
      </div>
      <div>
        <img src={vantagens} width={400} />
      </div>
    </VantagensWrapper>
  )
}

export default Vantagens

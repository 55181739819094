import InputCustom from 'components/Inputs'
import React from 'react'
import { CustomButton } from 'styles/global'
import { SejaParceiroWrapper } from '../style'
import logoRiber from 'assets/svg/logo-riber.svg'
import { FiUsers } from 'react-icons/fi'
import { Row } from 'reactstrap'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { LANDING_PAGE } from 'store/modules/landingPage/types'
import * as landingPageActions from 'store/modules/landingPage/actions'
import useRedux from 'hooks/useRedux'
import { maskPhoneWithDDD, removeMaskPhone } from 'util/masks'
import { schemaSejaParceiro } from '../constants/schema'
import * as yup from 'yup'
import { toast } from 'react-toastify'

const NewsLetter = () => {
  const { sejaParceiro, invalidSejaParceiro } = useSelector<
    ApplicationState,
    LANDING_PAGE
  >((state) => state.landingPage)

  const { dispatch } = useRedux()

  type SejaParceiroFields = keyof LANDING_PAGE['sejaParceiro']
  function onChange<T extends SejaParceiroFields>(
    value: T extends SejaParceiroFields
      ? LANDING_PAGE['sejaParceiro'][T]
      : never,
    field: T
  ) {
    dispatch(landingPageActions.setSejaParceiro(value, field))
  }

  function onSave() {
    dispatch(landingPageActions.cleanInvalidSejaParceiro())
    schemaSejaParceiro
      .validate(
        {
          nome: sejaParceiro.nome,
          email: sejaParceiro.email,
          telefone: removeMaskPhone(sejaParceiro.telefone)
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(landingPageActions.sejaParceiroSaga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            landingPageActions.setInvalidSejaParceiro(
              {
                invalid: true,
                message: e.message
              },
              e.path as keyof LANDING_PAGE['sejaParceiro']
            )
          )
        })
        toast.warn(
          'Por favor, lembre-se de preencher os campos corretamente antes de enviar!'
        )
      })
  }

  return (
    <SejaParceiroWrapper>
      <div className="logo">
        <img src={logoRiber} />
      </div>
      <div className="formulario">
        <FiUsers size={80} className="icon" />
        <h4>Seja um parceiro Riber</h4>
        <label>
          Deixe seus dados e nossa equipe entrará em contato com você.
        </label>
        <Row className="d-flex flex-column align-items-center row-gap-15">
          <InputCustom
            type="text"
            placeholder="Nome"
            onChange={(e) => {
              onChange(e.target.value, 'nome')
            }}
            value={sejaParceiro.nome}
            invalid={invalidSejaParceiro.nome.invalid}
            errorMessage={invalidSejaParceiro.nome.message}
            maxLength={100}
          />
          <InputCustom
            type="text"
            placeholder="E-mail"
            onChange={(e) => {
              onChange(e.target.value, 'email')
            }}
            value={sejaParceiro.email}
            invalid={invalidSejaParceiro.email.invalid}
            errorMessage={invalidSejaParceiro.email.message}
            maxLength={100}
          />
          <InputCustom
            type="text"
            placeholder="Telefone"
            onChange={(e) => {
              onChange(maskPhoneWithDDD(e.target.value), 'telefone')
            }}
            value={maskPhoneWithDDD(sejaParceiro.telefone)}
            invalid={invalidSejaParceiro.telefone.invalid}
            errorMessage={invalidSejaParceiro.telefone.message}
            maxLength={15}
          />
          <CustomButton
            background="var(--roxo-riber)"
            onClick={() => {
              onSave()
            }}
          >
            Enviar
          </CustomButton>
        </Row>
      </div>
    </SejaParceiroWrapper>
  )
}

export default NewsLetter

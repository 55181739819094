import React from 'react'
import Indicacao1 from './Indicacoes/Indicacao1'
import Indicacao10 from './Indicacoes/Indicacao10'
import Indicacao11 from './Indicacoes/Indicacao11'
import Indicacao2 from './Indicacoes/Indicacao2'
import Indicacao3 from './Indicacoes/Indicacao3'
import Indicacao4 from './Indicacoes/Indicacao4'
import Indicacao5 from './Indicacoes/Indicacao5'
import Indicacao6 from './Indicacoes/Indicacao6'
import Indicacao7 from './Indicacoes/Indicacao7'
import Indicacao8 from './Indicacoes/Indicacao8'
import { MetodosFgts } from 'store/modules/simulacao/types'

interface Indicacao {
  isOpen: boolean
  toggle: () => void
  indicacao: number | null
  metodo: MetodosFgts | null
}

const IndicacaoComponent = ({
  isOpen,
  toggle,
  indicacao,
  metodo
}: Indicacao) => {
  switch (indicacao) {
    case 1:
      return <Indicacao1 toggle={toggle} isOpen={isOpen} metodo={metodo} />
    case 2:
      return <Indicacao2 toggle={toggle} isOpen={isOpen} metodo={metodo} />
    case 3:
      return <Indicacao3 toggle={toggle} isOpen={isOpen} metodo={metodo} />
    case 4:
      return <Indicacao4 toggle={toggle} isOpen={isOpen} metodo={metodo} />
    case 5:
      return <Indicacao5 toggle={toggle} isOpen={isOpen} metodo={metodo} />
    case 6:
      return <Indicacao6 toggle={toggle} isOpen={isOpen} metodo={metodo} />
    case 7:
      return <Indicacao7 toggle={toggle} isOpen={isOpen} metodo={metodo} />
    case 8:
      return <Indicacao8 toggle={toggle} isOpen={isOpen} metodo={metodo} />
    case 10:
      return <Indicacao10 toggle={toggle} isOpen={isOpen} metodo={metodo} />
    case 11:
      return <Indicacao11 toggle={toggle} isOpen={isOpen} metodo={metodo} />
    default:
      return <Indicacao5 toggle={toggle} isOpen={isOpen} metodo={metodo} />
  }
}

export default IndicacaoComponent

import { DADOS_COMPLEMENTARES_INFOS_PESSOAL, TYPES } from './types'

export const setFormulario = (
  value: DADOS_COMPLEMENTARES_INFOS_PESSOAL['formulario']
) => ({
  type: TYPES.SET_FORMULARIO,
  value
})

type FormularioField = keyof DADOS_COMPLEMENTARES_INFOS_PESSOAL['formulario']
export const setFormularioField = <T extends FormularioField>(
  value: T extends keyof DADOS_COMPLEMENTARES_INFOS_PESSOAL['formulario']
    ? DADOS_COMPLEMENTARES_INFOS_PESSOAL['formulario'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_FORMULARIO_FIELD,
  value,
  field
})

export const cleanFormulario = () => ({
  type: TYPES.CLEAN_FORMULARIO
})

type InvalidFormularioField =
  keyof DADOS_COMPLEMENTARES_INFOS_PESSOAL['invalidFormulario']
export const setInvalidFormularioField = <T extends InvalidFormularioField>(
  value: T extends keyof DADOS_COMPLEMENTARES_INFOS_PESSOAL['invalidFormulario']
    ? DADOS_COMPLEMENTARES_INFOS_PESSOAL['invalidFormulario'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_INVALID_FORMULARIO,
  value,
  field
})

export const cleanInvalidsFormulario = () => ({
  type: TYPES.CLEAN_INVALIDS_FORMULARIO
})

export const salvarCadastroSaga = () => ({
  type: TYPES.SALVAR_CADASTRO_SAGA
})

type LoadingSelectFields =
  keyof DADOS_COMPLEMENTARES_INFOS_PESSOAL['loadingSelects']
export const setLoadingSelect = <T extends OptionsSelectFields>(
  value: T extends LoadingSelectFields
    ? DADOS_COMPLEMENTARES_INFOS_PESSOAL['loadingSelects'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_LOADING_SELECTS,
  value,
  field
})

type OptionsSelectFields =
  keyof DADOS_COMPLEMENTARES_INFOS_PESSOAL['optionsSelects']
export const setOptionsSelect = <T extends OptionsSelectFields>(
  value: T extends OptionsSelectFields
    ? DADOS_COMPLEMENTARES_INFOS_PESSOAL['optionsSelects'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_OPTIONS_SELECT,
  value,
  field
})

export const getGenerosSaga = () => ({
  type: TYPES.GET_GENEROS_SAGA
})

export const getEstadosCivisSaga = () => ({
  type: TYPES.GET_ESTADOS_CIVIS_SAGA
})

export const getProfissoesSaga = () => ({
  type: TYPES.GET_PROFISSOES_SAGA
})

export const getEscolaridadesSaga = () => ({
  type: TYPES.GET_ESCOLARIDADES_SAGA
})

export const getUfsNascimentoSaga = () => ({
  type: TYPES.GET_UFS_NASCIMENTO_SAGA
})

export const getCidadesNascimentoSaga = (siglaEstado: string) => ({
  type: TYPES.GET_CIDADES_NASCIMENTO_SAGA,
  siglaEstado
})

export const getDadosComplementaresInfoPessoalSaga = () => ({
  type: TYPES.GET_DADOS_COMPLEMENTARES_INFO_PESSOAL_SAGA
})

export const getCpfSaga = () => ({
  type: TYPES.GET_CPF_SAGA
})

export const setCpf = (value: string) => ({
  type: TYPES.SET_CPF,
  value
})

import React from 'react'
import Simple from 'layouts/simple'
import { Col, Row } from 'reactstrap'
import Banner from './components/Banner'
import { ArticleWrapper } from './style'
import creditoPessoal1 from 'assets/images/apresentacao/creditoPessoal1.png'
import creditoPessoal2 from 'assets/images/apresentacao/creditoPessoal2.png'
import creditoPessoal3 from 'assets/images/apresentacao/creditoConsignado3.png'
import FaqCreditoPessoal from './components/FaqCreditoPessoal'

const CreditoPessoal = () => {
  return (
    <Simple>
      <div>
        <Banner
          title={'Crédito Pessoal'}
          subtitle={'Solução Riber rápida, fácil e segura'}
          image={creditoPessoal1}
        />
        <ArticleWrapper>
          <div className="container-fluid d-flex flex-column align-items-center">
            <Row className="grid">
              <Col md={6} className="align-items-center p-5 text-right">
                <h4 className="text-center text-md-end">
                  O que é o crédito pessoal?
                </h4>
                <p className="text-center text-md-end">
                  É um empréstimo oferecido pelo banco baseado nas políticas de
                  crédito. O pagamento é realizado através do cartão de crédito
                  e as parcelas lançadas diretamente na sua fatura. O valor do
                  empréstimo pessoal contratado não utiliza seu limite
                  disponível, apenas o valor da parcela. O crédito é liberado na
                  conta Bmg ou em outra informada.
                </p>
              </Col>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <img src={creditoPessoal2} />
              </Col>
            </Row>
            <Row className="grid flex-column-reverse flex-md-row">
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <img src={creditoPessoal3} />
              </Col>
              <Col md={6} className="align-items-center p-5">
                <h4 className="text-center text-md-start">
                  Construindo Conquista
                </h4>
                <p className="text-center text-md-start">
                  No Crédito Pessoal, cada etapa é uma oportunidade para
                  construir conquistas. Com nosso suporte, você transforma
                  planos em realidade. Seja para uma viagem dos sonhos ou um
                  novo projeto, estamos aqui para ajudar a construir suas
                  maiores conquistas com confiança e simplicidade
                </p>
              </Col>
            </Row>
          </div>
        </ArticleWrapper>
        <FaqCreditoPessoal />
      </div>
    </Simple>
  )
}

export default CreditoPessoal

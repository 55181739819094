import { toast } from 'react-toastify'

export function handlerError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  defaultMessage?: string,
  autoClose?: number | false | undefined
) {
  if (
    (error?.message || (error?.messages?.length > 0 && error?.messages)) &&
    !error?.stack
  ) {
    if (error?.messages?.length > 0) {
      error?.messages.map((e: string) => {
        toast.error(e, {
          bodyClassName: 'text-break',
          autoClose: autoClose
        })
      })
    } else {
      toast.error(error?.message, {
        bodyClassName: 'text-break',
        autoClose: autoClose
      })
    }
  } else {
    defaultMessage
      ? toast.error(defaultMessage, {
          bodyClassName: 'text-break',
          autoClose: autoClose
        })
      : toast.error('Não foi possível!', {
          bodyClassName: 'text-break',
          autoClose: autoClose
        })
  }
}

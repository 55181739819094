import { TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as loadingActions from 'store/modules/loading/actions'
import * as dadosComplementaresInfoPessoalController from 'controller/dadosComplementaresInfoPessoalController'
import * as dadosComplementaresInfoPessoalActions from 'store/modules/cadastros/dadosComplementaresInfoPessoal/actions'
import { DADOS_COMPLEMENTARES_INFOS_PESSOAL } from './types'
import { ApplicationState } from 'store'
import { handlerError } from 'util/handlerError'
import { toast } from 'react-toastify'
import { removeMaskCPF } from 'util/masks'
import { stepCadastroComplementarRedirect } from 'util/stepCadastroComplementarRedirect'
import * as simulacaoActions from 'store/modules/simulacao/actions'

interface GenericProps {
  type: string
}

function* getDadosComplementaresInfoPessoal(): SagaIterator {
  const { cpf }: DADOS_COMPLEMENTARES_INFOS_PESSOAL = yield select(
    (state: ApplicationState) => state.dadosComplementaresInfosPessoal
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpf)
    const response = yield call(
      dadosComplementaresInfoPessoalController.getDadosComplementaresInfoPessoal,
      cpfFormatted
    )
    yield put(
      dadosComplementaresInfoPessoalActions.setFormulario({
        cidadeNascimento: response?.data?.content.cidadeNascimento,
        escolaridadeId: response?.data?.content.escolaridadeId,
        estadoCivil: response?.data?.content.estadoCivil,
        nacionalidade: 'Brasileira',
        nomeConjuge: response?.data?.content.nomeConjuge,
        profissaoId: response?.data?.content.profissaoId,
        sexo: response?.data?.content.sexo,
        ufNascimento: response?.data?.content.ufNascimento
      })
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* salvarCadastro(): SagaIterator {
  const { formulario, cpf }: DADOS_COMPLEMENTARES_INFOS_PESSOAL = yield select(
    (state: ApplicationState) => state.dadosComplementaresInfosPessoal
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpf)
    const response = yield call(
      dadosComplementaresInfoPessoalController.salvarCadastro,
      {
        cpf: cpfFormatted,
        cidadeNascimento: formulario.cidadeNascimento,
        escolaridadeId: formulario.escolaridadeId,
        estadoCivil: formulario.estadoCivil,
        nacionalidade: formulario.nacionalidade,
        nomeConjuge: formulario.nomeConjuge,
        profissaoId: formulario.profissaoId,
        sexo: formulario.sexo,
        ufNascimento: formulario.ufNascimento
      }
    )
    yield put(dadosComplementaresInfoPessoalActions.cleanFormulario())
    toast.success('Informações salvas com sucesso!')
    yield put(simulacaoActions.validaStatusIN100Saga())
    stepCadastroComplementarRedirect(
      response?.data?.content.dadosComplementaresEnum
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getGeneros(): SagaIterator {
  try {
    yield put(
      dadosComplementaresInfoPessoalActions.setLoadingSelect(true, 'generos')
    )
    const response = yield call(
      dadosComplementaresInfoPessoalController.getGeneros
    )
    yield put(
      dadosComplementaresInfoPessoalActions.setOptionsSelect(
        response?.data?.content,
        'generos'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresInfoPessoalActions.setLoadingSelect(false, 'generos')
    )
  }
}

function* getEstadosCivis(): SagaIterator {
  try {
    yield put(
      dadosComplementaresInfoPessoalActions.setLoadingSelect(
        true,
        'estadosCivis'
      )
    )
    const response = yield call(
      dadosComplementaresInfoPessoalController.getEstadosCivis
    )
    yield put(
      dadosComplementaresInfoPessoalActions.setOptionsSelect(
        response?.data?.content,
        'estadosCivis'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresInfoPessoalActions.setLoadingSelect(
        false,
        'estadosCivis'
      )
    )
  }
}

function* getProfissoes(): SagaIterator {
  try {
    yield put(
      dadosComplementaresInfoPessoalActions.setLoadingSelect(true, 'profissoes')
    )
    const response = yield call(
      dadosComplementaresInfoPessoalController.getProfissoes
    )
    yield put(
      dadosComplementaresInfoPessoalActions.setOptionsSelect(
        response?.data?.content,
        'profissoes'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresInfoPessoalActions.setLoadingSelect(
        false,
        'profissoes'
      )
    )
  }
}

function* getEscolaridades(): SagaIterator {
  try {
    yield put(
      dadosComplementaresInfoPessoalActions.setLoadingSelect(
        true,
        'escolaridades'
      )
    )
    const response = yield call(
      dadosComplementaresInfoPessoalController.getEscolaridades
    )
    yield put(
      dadosComplementaresInfoPessoalActions.setOptionsSelect(
        response?.data?.content,
        'escolaridades'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresInfoPessoalActions.setLoadingSelect(
        false,
        'escolaridades'
      )
    )
  }
}

function* getUfsNascimento(): SagaIterator {
  try {
    yield put(
      dadosComplementaresInfoPessoalActions.setLoadingSelect(
        true,
        'ufsNascimento'
      )
    )
    const response = yield call(
      dadosComplementaresInfoPessoalController.getEstados
    )
    yield put(
      dadosComplementaresInfoPessoalActions.setOptionsSelect(
        response?.data?.content,
        'ufsNascimento'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresInfoPessoalActions.setLoadingSelect(
        false,
        'ufsNascimento'
      )
    )
  }
}

interface GetCidadesNascimentoProps extends GenericProps {
  siglaEstado: string
}

function* getCidadesNascimento({
  siglaEstado
}: GetCidadesNascimentoProps): SagaIterator {
  try {
    yield put(
      dadosComplementaresInfoPessoalActions.setLoadingSelect(
        true,
        'cidadesNascimento'
      )
    )
    const response = yield call(
      dadosComplementaresInfoPessoalController.getCidades,
      siglaEstado
    )
    yield put(
      dadosComplementaresInfoPessoalActions.setOptionsSelect(
        response?.data?.content,
        'cidadesNascimento'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresInfoPessoalActions.setLoadingSelect(
        false,
        'cidadesNascimento'
      )
    )
  }
}

function* getCpf(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(dadosComplementaresInfoPessoalController.getCpf)
    yield put(
      dadosComplementaresInfoPessoalActions.setCpf(response?.data?.content.cpf)
    )
    yield put(
      dadosComplementaresInfoPessoalActions.getDadosComplementaresInfoPessoalSaga()
    )
  } catch (error) {
    handlerError(
      error,
      'Não foi possível obter CPF. Tente novamente mais tarde'
    )
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetDadosComplementaresInfoPessoal() {
  yield takeEvery(
    TYPES.GET_DADOS_COMPLEMENTARES_INFO_PESSOAL_SAGA,
    getDadosComplementaresInfoPessoal
  )
}

export function* watchSalvarCadastro() {
  yield takeEvery(TYPES.SALVAR_CADASTRO_SAGA, salvarCadastro)
}

export function* watchGetGeneros() {
  yield takeEvery(TYPES.GET_GENEROS_SAGA, getGeneros)
}

export function* watchGetEstadosCivis() {
  yield takeEvery(TYPES.GET_ESTADOS_CIVIS_SAGA, getEstadosCivis)
}

export function* watchGetProfissoes() {
  yield takeEvery(TYPES.GET_PROFISSOES_SAGA, getProfissoes)
}

export function* watchGetEscolaridades() {
  yield takeEvery(TYPES.GET_ESCOLARIDADES_SAGA, getEscolaridades)
}

export function* watchGetUfsNascimento() {
  yield takeEvery(TYPES.GET_UFS_NASCIMENTO_SAGA, getUfsNascimento)
}

export function* watchGetCidadesNascimento() {
  yield takeEvery(TYPES.GET_CIDADES_NASCIMENTO_SAGA, getCidadesNascimento)
}

export function* watchGetCpfSaga() {
  yield takeEvery(TYPES.GET_CPF_SAGA, getCpf)
}

function* dadosComplementaresInfoPessoal() {
  yield all([
    fork(watchGetDadosComplementaresInfoPessoal),
    fork(watchSalvarCadastro),
    fork(watchGetGeneros),
    fork(watchGetEstadosCivis),
    fork(watchGetProfissoes),
    fork(watchGetEscolaridades),
    fork(watchGetUfsNascimento),
    fork(watchGetCidadesNascimento),
    fork(watchGetCpfSaga)
  ])
}

export default dadosComplementaresInfoPessoal

import { TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as loadingActions from 'store/modules/loading/actions'
import * as propostasActions from 'store/modules/propostas/actions'
import * as propostasController from 'controller/propostasController'
import { handlerError } from 'util/handlerError'

function* getPropostasJaContratadas(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(propostasController.getPropostasJaContratadas)
    yield put(
      propostasActions.setPropostasJaContratadas(response?.data?.content)
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetPropostasJaContratadas() {
  yield takeEvery(
    TYPES.GET_PROPOSTAS_JA_CONTRATADAS_SAGA,
    getPropostasJaContratadas
  )
}

function* propostas() {
  yield all([fork(watchGetPropostasJaContratadas)])
}

export default propostas

import React, { useEffect, useState } from 'react'
import { ContentSidebar } from './style'
import { FiLogOut } from 'react-icons/fi'
import { IoIosArrowBack } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { SIDEBAR } from 'store/modules/sidebar/types'
import { toggleSidebar } from 'store/modules/sidebar/actions'
import { useLocation } from 'react-router-dom'
import * as loginActions from 'store/modules/login/actions'
import { Link } from 'react-router-dom'
import useRedux from 'hooks/useRedux'
import routes from 'routes'

function Sidebar() {
  const { isOpen } = useSelector<ApplicationState, SIDEBAR>(
    (state) => state.sidebar
  )

  const [rota, setRota] = useState<{ path: string } | undefined>({
    path: ''
  })
  const location = useLocation()

  const getLocation = () => {
    const simpleRoutes: { path: string }[] = []
    routes.forEach((route) =>
      simpleRoutes.push({
        path: route.path
      })
    )
    const pathNameSplited = location.pathname.split('/')
    const newRota = simpleRoutes.find(
      (simple) =>
        simple.path.toUpperCase() === '/' + pathNameSplited[1].toUpperCase()
    )
    setRota(newRota)
  }

  const { dispatch } = useRedux()

  useEffect(() => {
    getLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  function logout() {
    dispatch(loginActions.logout())
  }

  return (
    <ContentSidebar toggle={isOpen}>
      <div className="d-flex flex-column">
        <a className="onlyMobile">
          <IoIosArrowBack
            className="icon"
            onClick={() => {
              dispatch(toggleSidebar())
            }}
          />
        </a>
        {routes.map((route, i) => {
          if (route.sidebar) {
            return (
              <Link
                className={rota?.path === route.path ? 'active' : ''}
                to={route.path}
                key={i}
              >
                {route.icon} {route.title}
              </Link>
            )
          }
        })}
      </div>
      <div className="d-flex flex-column">
        <a
          onClick={() => {
            logout()
          }}
        >
          <FiLogOut className="icon" /> Sair
        </a>
      </div>
    </ContentSidebar>
  )
}

export default Sidebar

import { Invalid } from 'models/types'
import { LANDING_PAGE, TYPES } from './types'

export const setCpfLanding = (value: string) => ({
  type: TYPES.SET_CPF_LANDING,
  value
})

export const cleanCpfLanding = () => ({
  type: TYPES.CLEAN_CPF_LANDING
})

export const setCpfLandingInvalid = (value: Invalid) => ({
  type: TYPES.SET_CPF_LANDING_INVALID,
  value
})

export const cleanCpfLandingInvalid = () => ({
  type: TYPES.CLEAN_CPF_LANDING_INVALID
})

export const iniciarSaga = () => ({
  type: TYPES.INICIAR_SAGA
})

type SejaParceiroFields = keyof LANDING_PAGE['sejaParceiro']
export const setSejaParceiro = <T extends SejaParceiroFields>(
  value: T extends SejaParceiroFields ? LANDING_PAGE['sejaParceiro'][T] : never,
  field: T
) => ({
  type: TYPES.SET_SEJA_PARCEIRO,
  value,
  field
})

export const cleanSejaParceiro = () => ({
  type: TYPES.CLEAN_SEJA_PARCEIRO
})

type InvalidSejaParceiroFields = keyof LANDING_PAGE['invalidSejaParceiro']
export const setInvalidSejaParceiro = <T extends InvalidSejaParceiroFields>(
  value: T extends InvalidSejaParceiroFields
    ? LANDING_PAGE['invalidSejaParceiro'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_INVALID_SEJA_PARCEIRO,
  value,
  field
})

export const cleanInvalidSejaParceiro = () => ({
  type: TYPES.CLEAN_INVALID_SEJA_PARCEIRO
})

export const sejaParceiroSaga = () => ({
  type: TYPES.SEJA_PARCEIRO_SAGA
})

export const getEstadosSaga = () => ({
  type: TYPES.GET_ESTADOS_SAGA
})

export const getCidadesSaga = (siglaEstado: string) => ({
  type: TYPES.GET_CIDADES_SAGA,
  siglaEstado
})

export const getRiberMaisPertoSaga = () => ({
  type: TYPES.GET_RIBER_MAIS_PERTO_SAGA
})

type RiberMaisPertoFields = keyof LANDING_PAGE['riberMaisPerto']
export const setRiberMaisPerto = <T extends RiberMaisPertoFields>(
  value: T extends RiberMaisPertoFields
    ? LANDING_PAGE['riberMaisPerto'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_RIBER_MAIS_PERTO,
  value,
  field
})

export const cleanRiberMaisPerto = () => ({
  type: TYPES.CLEAN_RIBER_MAIS_PERTO
})

type InvalidRiberMaisPertoFields = keyof LANDING_PAGE['invalidRiberMaisPerto']
export const setInvalidRiberMaisPerto = <T extends InvalidRiberMaisPertoFields>(
  value: T extends InvalidRiberMaisPertoFields
    ? LANDING_PAGE['invalidRiberMaisPerto'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_INVALID_RIBER_MAIS_PERTO,
  value,
  field
})

export const cleanInvalidRiberMaisPerto = () => ({
  type: TYPES.CLEAN_INVALID_RIBER_MAIS_PERTO
})

type LoadingSelectFields = keyof LANDING_PAGE['loadingSelects']
export const setLoadingSelect = <T extends OptionsSelectFields>(
  value: T extends LoadingSelectFields
    ? LANDING_PAGE['loadingSelects'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_LOADING_SELECTS,
  value,
  field
})

type OptionsSelectFields = keyof LANDING_PAGE['optionsSelects']
export const setOptionsSelect = <T extends OptionsSelectFields>(
  value: T extends OptionsSelectFields
    ? LANDING_PAGE['optionsSelects'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_OPTIONS_SELECT,
  value,
  field
})

export const setRiberMaisPertoList = (
  value: LANDING_PAGE['riberMaisPertoList']
) => ({
  type: TYPES.SET_RIBER_MAIS_PERTO_LIST,
  value
})

export const getAvisoPrivacidadeSaga = () => ({
  type: TYPES.GET_AVISO_PRIVACIDADE_SAGA
})

export const getPoliticaPrivacidadeSaga = () => ({
  type: TYPES.GET_POLITICA_PRIVACIDADE_SAGA
})

export const getTermoPoliticaSaga = () => ({
  type: TYPES.GET_TERMO_POLITICA_SAGA
})

export const cleanDownloads = () => ({
  type: TYPES.CLEAN_DOWNLOADS
})

type DownloadsFields = keyof LANDING_PAGE['downloads']
export const setDownloads = <T extends DownloadsFields>(
  value: T extends DownloadsFields ? LANDING_PAGE['downloads'][T] : never,
  field: T
) => ({
  type: TYPES.SET_DOWNLOADS,
  value,
  field
})

type LoadingDownloadsFields = keyof LANDING_PAGE['loadingDownloads']
export const setLoadingDownloads = <T extends LoadingDownloadsFields>(
  value: T extends LoadingDownloadsFields
    ? LANDING_PAGE['loadingDownloads'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_LOADING_DOWNLOADS,
  value,
  field
})

export const setCpfProdutos = (value: string) => ({
  type: TYPES.SET_CPF_PRODUTOS,
  value
})

export const cleanCpfProdutos = () => ({
  type: TYPES.CLEAN_CPF_PRODUTOS
})

export const setCpfProdutosInvalid = (value: Invalid) => ({
  type: TYPES.SET_CPF_PRODUTOS_INVALID,
  value
})

export const cleanCpfProdutosInvalid = () => ({
  type: TYPES.CLEAN_CPF_PRODUTOS_INVALID
})

export const iniciarProdutosSaga = () => ({
  type: TYPES.INICIAR_PRODUTOS_SAGA
})

export const setModalPdf = (value: boolean) => ({
  type: TYPES.SET_MODAL_PDF,
  value
})

export const openModalCpf = (value: boolean) => ({
  type: TYPES.OPEN_MODAL_CPF,
  value
})

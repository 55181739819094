import styled from 'styled-components'

export const ContentPerfisSelecao = styled.div`
  height: 350px;
  overflow: auto;
  border: 1px solid var(--cinzaClaro-riber);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-weight: 700;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    margin: 2px 10px;
  }

  & .itens-lista {
    color: #5e5e5e;
    cursor: pointer;
    padding: 10px 5px;

    &:hover {
      background-color: var(--cinzaClaro-riber);
    }
  }

  & li {
    list-style-type: none;
  }
`

import React from 'react'
import { SeletorPerfilWrapper } from './style'
import { Col, Row } from 'reactstrap'

interface SeletorPerfilProps {
  perfis: {
    id: string
    descricao: string
    tipoPerfil: number
  }[]
  onChange?: (e: number) => void
  value?: number | null
  size?: number
}

const SeletorPerfil = ({
  perfis,
  onChange,
  value,
  size = 4
}: SeletorPerfilProps) => {
  return (
    <SeletorPerfilWrapper>
      <div className="container-fluid">
        <Row className="d-flex row-gap-20 align-items-center justify-content-center">
          {perfis?.map((perfil, i) => {
            return (
              <Col key={i} md={size}>
                <input
                  onChange={(e) => {
                    onChange?.(Number(e.currentTarget.value))
                  }}
                  name="perfil"
                  type="radio"
                  id={`perfil-${i}`}
                  value={perfil.tipoPerfil}
                  checked={perfil.tipoPerfil === value}
                />
                <label htmlFor={`perfil-${i}`}>{perfil.descricao}</label>
              </Col>
            )
          })}
        </Row>
      </div>
    </SeletorPerfilWrapper>
  )
}

export default SeletorPerfil

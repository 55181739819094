import React, { ReactNode } from 'react'
import Navbar from 'components/Navbar'
import BlurLoading from 'components/Loading/BlurLoading'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { LOADING } from 'store/modules/loading/types'
import { CorpoConteudo } from './style'

interface LandingPageProps {
  children?: ReactNode
}

const JustNavbar = ({ children }: LandingPageProps) => {
  const { isLoading } = useSelector<ApplicationState, LOADING>(
    (state) => state.loading
  )

  return (
    <>
      <BlurLoading isLoading={isLoading} />
      <Navbar />
      <CorpoConteudo>{children}</CorpoConteudo>
    </>
  )
}

export default JustNavbar

import React from 'react'
import { LocsWrapper } from '../style'
import { FiMapPin } from 'react-icons/fi'

interface LocsProps {
  loc: string
}

const Locs = ({ loc }: LocsProps) => {
  return (
    <LocsWrapper>
      <FiMapPin className="icon" size={20} />
      <span>{loc}</span>
    </LocsWrapper>
  )
}

export default Locs

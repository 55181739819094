import { DADOS_COMPLEMENTARES_ENDERECO, TYPES } from './types'

export const setFormulario = (
  value: DADOS_COMPLEMENTARES_ENDERECO['formulario']
) => ({
  type: TYPES.SET_FORMULARIO,
  value
})

type FormularioField = keyof DADOS_COMPLEMENTARES_ENDERECO['formulario']
export const setFormularioField = <T extends FormularioField>(
  value: T extends keyof DADOS_COMPLEMENTARES_ENDERECO['formulario']
    ? DADOS_COMPLEMENTARES_ENDERECO['formulario'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_FORMULARIO_FIELD,
  value,
  field
})

export const cleanFormulario = () => ({
  type: TYPES.CLEAN_FORMULARIO
})

type InvalidFormularioField =
  keyof DADOS_COMPLEMENTARES_ENDERECO['invalidFormulario']
export const setInvalidFormularioField = <T extends InvalidFormularioField>(
  value: T extends keyof DADOS_COMPLEMENTARES_ENDERECO['invalidFormulario']
    ? DADOS_COMPLEMENTARES_ENDERECO['invalidFormulario'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_INVALID_FORMULARIO,
  value,
  field
})

export const cleanInvalidsFormulario = () => ({
  type: TYPES.CLEAN_INVALIDS_FORMULARIO
})

export const salvarCadastroSaga = () => ({
  type: TYPES.SALVAR_CADASTRO_SAGA
})

export const getEstadosSaga = () => ({
  type: TYPES.GET_ESTADOS_SAGA
})

export const getCidadesSaga = (siglaEstado: string) => ({
  type: TYPES.GET_CIDADES_SAGA,
  siglaEstado
})

export const getDadosEnderecoSaga = () => ({
  type: TYPES.GET_DADOS_ENDERECO_SAGA
})

type LoadingSelectFields = keyof DADOS_COMPLEMENTARES_ENDERECO['loadingSelects']
export const setLoadingSelect = <T extends OptionsSelectFields>(
  value: T extends LoadingSelectFields
    ? DADOS_COMPLEMENTARES_ENDERECO['loadingSelects'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_LOADING_SELECTS,
  value,
  field
})

type OptionsSelectFields = keyof DADOS_COMPLEMENTARES_ENDERECO['optionsSelects']
export const setOptionsSelect = <T extends OptionsSelectFields>(
  value: T extends OptionsSelectFields
    ? DADOS_COMPLEMENTARES_ENDERECO['optionsSelects'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_OPTIONS_SELECT,
  value,
  field
})

export const getDadosComplementaresEnderecoSaga = () => ({
  type: TYPES.GET_DADOS_COMPLEMENTARES_ENDERECO_SAGA
})

export const getCpfSaga = () => ({
  type: TYPES.GET_CPF_SAGA
})

export const setCpf = (value: string) => ({
  type: TYPES.SET_CPF,
  value
})

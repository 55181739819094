import React, { useEffect, useState } from 'react'
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { LANDING_PAGE } from 'store/modules/landingPage/types'

const Mapa = withScriptjs(
  withGoogleMap(() => {
    const { riberMaisPertoList } = useSelector<ApplicationState, LANDING_PAGE>(
      (state) => state.landingPage
    )

    const [nextPin, setNextPin] = useState({
      lat: -21.1830474,
      lng: -47.8143891
    })

    useEffect(() => {
      const riber = riberMaisPertoList.find(
        (riber) => riber.latitude && riber.longitude
      )
      if (riber) {
        setNextPin({
          lat: Number(riber.latitude),
          lng: Number(riber.longitude)
        })
      }
    }, [riberMaisPertoList])

    return (
      <>
        <GoogleMap
          defaultZoom={12}
          defaultCenter={{
            lat: -21.1830474,
            lng: -47.8143891
          }}
          center={nextPin}
        />
        {riberMaisPertoList.map((riber, i) => {
          if (riber.latitude && riber.longitude) {
            return (
              <Marker
                key={i}
                position={{
                  lat: Number(riber.latitude),
                  lng: Number(riber.longitude)
                }}
              />
            )
          }
        })}
      </>
    )
  })
)

export default Mapa

import React from 'react'
import Simple from 'layouts/simple'
import { Col, Row } from 'reactstrap'
import { ArticleWrapper } from './style'
import Banner from './components/Banner'
import cartaoConsignado1 from 'assets/images/apresentacao/cartaoConsignado1.png'
import cartaoConsignado2 from 'assets/images/apresentacao/cartaoConsignado2.png'
import cartaoConsignado3 from 'assets/images/apresentacao/cartaoConsignado3.png'
import FaqCartaoConsignado from './components/FaqCartaoConsignado'

const CartaoConsignado = () => {
  return (
    <Simple>
      <div>
        <Banner
          title={'Cartão de Crédito Consignado'}
          subtitle={'Solicite agora mesmo'}
          image={cartaoConsignado1}
        />
        <ArticleWrapper>
          <div className="container-fluid d-flex flex-column align-items-center">
            <Row className="grid">
              <Col md={6} className="align-items-center p-5 text-right">
                <h4 className="text-center text-md-end">
                  Solicite seu cartão de forma rápida e 100% digital.
                </h4>
                <p className="text-center text-md-end">
                  Explore as vantagens do cartão de crédito consignado Riber:
                  uma alternativa inteligente ao cartão tradicional. Com ele, o
                  pagamento mínimo da fatura é deduzido diretamente do seu
                  benefício ou salário, minimizando o risco de dívidas.
                  Exclusivo para aposentados, pensionistas do INSS e servidores
                  de entidades conveniadas, o cartão está disponível para quem
                  tem margem consignável livre.
                </p>
              </Col>
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <img src={cartaoConsignado2} />
              </Col>
            </Row>
            <Row className="grid flex-column-reverse flex-md-row">
              <Col
                md={6}
                className="d-flex align-items-center justify-content-center"
              >
                <img src={cartaoConsignado3} />
              </Col>
              <Col md={6} className="align-items-center p-5">
                <h4 className="text-center text-md-start">
                  Benefícios Exclusivos do Cartão de Crédito Consignado
                </h4>
                <p className="text-center text-md-start">
                  Descubra um mundo de vantagens com o cartão de crédito
                  consignado Riber, especialmente desenvolvido para aposentados,
                  pensionistas do INSS e servidores públicos. Desfrute de juros
                  significativamente inferiores aos dos cartões comuns e muito
                  mais:
                </p>
                <p className="text-center text-md-start">
                  Limite Ampliado: Até 1.6 vezes o valor do seu benefício ou
                  salário. As condições variam conforme o seu convênio, então
                  vale a pena conferir.
                </p>
                <p className="text-center text-md-start">
                  Flexibilidade de Saques: Até 70% do seu limite disponível para
                  saques, que podem ser feitos diretamente na sua conta ou em
                  espécie. Os valores sacados serão incluídos na fatura
                  seguinte.
                </p>
                <p className="text-center text-md-start">
                  Conveniência no Pagamento: A parcela mínima de sua fatura é
                  automaticamente descontada do seu benefício ou contracheque,
                  facilitando o controle financeiro.
                </p>
                <p className="text-center text-md-start">
                  Economia em Medicamentos: Obtenha descontos especiais em
                  estabelecimentos parceiros como Drogaria São Paulo, Drogaria
                  Pacheco e compras online na Hypera Pharma.
                </p>
                <p className="text-center text-md-start">
                  Zero Anuidade: O cartão de crédito consignado Riber é isento
                  de anuidade, garantindo mais economia para você.
                </p>
              </Col>
            </Row>
          </div>
        </ArticleWrapper>
        <FaqCartaoConsignado />
      </div>
    </Simple>
  )
}

export default CartaoConsignado

import React, { useEffect, useState } from 'react'
import { Login as LoginLayout } from 'layouts/login'
import { Button, CardLogin } from './style'
import logoRiber from 'assets/svg/logo-riber.svg'
import { FormGroup } from 'reactstrap'
import InputCustom from 'components/Inputs'
import TopLoading from 'components/Loading/TopLoading'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { FormRecuperacao, LOGIN } from 'store/modules/login/types'
import useRedux from 'hooks/useRedux'
import * as loginActions from 'store/modules/login/actions'
import { useParams } from 'react-router-dom'

const NovaSenha = () => {
  const { recuperacao, loading } = useSelector<ApplicationState, LOGIN>(
    (state) => state.login
  )

  const { dispatch } = useRedux()

  type RecuperacaoFields = keyof FormRecuperacao
  function onChange(value: string, field: RecuperacaoFields) {
    dispatch(loginActions.setRecuperacao(value, field))
  }

  const { id } = useParams()

  const [idState, setIdState] = useState(id)

  useEffect(() => {
    setIdState(id)
    dispatch(loginActions.validaGuidRecuperacaoSaga(idState || ''))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id])

  return (
    <LoginLayout>
      <CardLogin>
        <div className="d-flex justify-content-center">
          <img src={logoRiber} width="80" alt="Logo verde Riber" />
        </div>
        <FormGroup className="d-flex flex-column gap-3">
          <div className="d-flex flex-column">
            <label className="label-12">Nova senha</label>
            <InputCustom
              type="password"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'senha')
              }}
              value={recuperacao.senha}
            />
          </div>
          <div className="d-flex flex-column">
            <label className="label-12">Confirme a senha</label>
            <InputCustom
              type="password"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'confirmacaoSenha')
              }}
              value={recuperacao.confirmacaoSenha}
            />
          </div>
          <Button
            onClick={() => {
              dispatch(loginActions.redefinirSenhaSaga(idState || ''))
            }}
          >
            Enviar
          </Button>
        </FormGroup>
      </CardLogin>
      {loading ? <TopLoading /> : <></>}
    </LoginLayout>
  )
}

export default NovaSenha

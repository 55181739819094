import React from 'react'
import SimpleModal from 'components/SimpleModal'
import { Col, Row } from 'reactstrap'
import InputCustom from 'components/Inputs'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { CADASTRO_INICIAL } from 'store/modules/cadastros/cadastroInicial/types'
import useRedux from 'hooks/useRedux'
import * as cadastroInicialActions from 'store/modules/cadastros/cadastroInicial/actions'
import { CustomButton, SimpleTitleCard } from 'styles/global'
import { MdPassword } from 'react-icons/md'
import { schemaModalSenha } from '../constants/schema'
import * as yup from 'yup'

const ModalSenha = () => {
  const { modalSenha, showModalSenha, invalidModalSenha } = useSelector<
    ApplicationState,
    CADASTRO_INICIAL
  >((state) => state.cadastroInicial)

  const { dispatch } = useRedux()

  type ModalSenhaField = keyof CADASTRO_INICIAL['modalSenha']
  const onChange = <T extends ModalSenhaField>(
    value: T extends keyof CADASTRO_INICIAL['modalSenha']
      ? CADASTRO_INICIAL['modalSenha'][T]
      : never,
    field: T
  ) => {
    dispatch(cadastroInicialActions.setModalSenhaField(value, field))
  }

  const onSave = () => {
    dispatch(cadastroInicialActions.cleanInvalidsModalSenha())
    schemaModalSenha
      .validate(
        {
          senha: modalSenha.senha,
          confirmacaoSenha: modalSenha.confirmacaoSenha
        },
        { abortEarly: false }
      )
      .then(() => {
        dispatch(cadastroInicialActions.salvarCadastroInicialSaga())
      })
      .catch((err) => {
        err.inner.forEach((e: yup.ValidationError) => {
          dispatch(
            cadastroInicialActions.setInvalidModalSenhaField(
              {
                invalid: true,
                message: e.message
              },
              e.path as keyof CADASTRO_INICIAL['invalidModalSenha']
            )
          )
        })
      })
  }

  return (
    <SimpleModal
      isOpen={showModalSenha}
      toggle={() => {
        dispatch(cadastroInicialActions.setShowModalSenha(false))
      }}
      dataBackdrop="static"
      header={
        <SimpleTitleCard>
          <MdPassword className="icon" />
          Crie uma senha para o Sistema Riber
        </SimpleTitleCard>
      }
    >
      <div className="d-flex flex-column gap-4">
        <Row>
          <Col>
            <label className="label-12">Senha*</label>
            <InputCustom
              type="password"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'senha')
              }}
              value={modalSenha.senha}
              invalid={invalidModalSenha.senha.invalid}
              errorMessage={invalidModalSenha.senha.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="label-12">Confirmação senha*</label>
            <InputCustom
              type="password"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'confirmacaoSenha')
              }}
              value={modalSenha.confirmacaoSenha}
              invalid={invalidModalSenha.confirmacaoSenha.invalid}
              errorMessage={invalidModalSenha.confirmacaoSenha.message}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomButton
              background="var(--roxo-riber)"
              width="100%"
              padding="20"
              height="50px"
              borderRadius={21}
              onClick={() => {
                onSave()
              }}
            >
              AVANÇAR
            </CustomButton>
          </Col>
        </Row>
      </div>
    </SimpleModal>
  )
}

export default ModalSenha

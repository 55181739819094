import React from 'react'
import Main from 'layouts/main'
import SimpleCard from 'components/SimpleCard'
import { Calculator } from 'react-mac-calculator'
import { SimpleTitleCard } from 'styles/global'
import { AiOutlineCalculator } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { NAVBAR } from 'store/modules/navbar/types'

const Calculadora = () => {
  const { nomeUsuario } = useSelector<ApplicationState, NAVBAR>(
    (state) => state.navbar
  )

  return (
    <Main
      titleBreadcrumbs={`Olá, ${nomeUsuario}!`}
      subtitleBreadcrumbs="Confira sua calculadora."
    >
      <SimpleCard>
        <SimpleTitleCard>
          <AiOutlineCalculator className="icon" />
          Calculadora
        </SimpleTitleCard>
        <div className="d-flex justify-content-center">
          <Calculator />
        </div>
      </SimpleCard>
    </Main>
  )
}

export default Calculadora

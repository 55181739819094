import styled from 'styled-components'

export const Background = styled.div`
  background: var(--verdeGradiente-riber);
  box-shadow: 0px 1px 0px #e5e9f2;
  border-radius: 0 0 30px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  min-height: 90px;
  padding: 35px 0;
  position: relative;
  top: 62px;
  width: 100%;

  & span.breadcrumb {
    align-items: center;
    color: var(--branco);
    display: flex;
    font-weight: 700;
    font-size: 14px;
    gap: 10px;
    left: calc(18vw + 30px);
    line-height: 20px;
    margin: 0;
    position: relative;
    width: calc(100% - (18vw + 30px));
    word-break: break-word;
  }

  .conteudo {
    color: var(--branco);
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 14px;
    left: calc(18vw + 30px);
    line-height: 20px;
    position: relative;
    width: calc(100% - (18vw + 30px));
    word-break: break-word;
    & label {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }

    & span {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
  }

  & .icon {
    height: 20px;
    width: 20px;
  }

  @media (max-width: 992px) {
    span.breadcrumb {
      left: 30px;
    }

    .conteudo {
      left: 30px;
    }
  }
`

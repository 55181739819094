import { Reducer } from 'redux'
import { SIDEBAR, TYPES } from './types'

const INITIAL_STATE: SIDEBAR = {
  isOpen: false
}

const reducer: Reducer<SIDEBAR> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.TOGGLE_SIDEBAR:
      return { ...state, isOpen: !state.isOpen }
    default:
      return state
  }
}

export default reducer

import {
  Cidade,
  Convenio,
  Estado,
  Invalid,
  Perfil,
  ServidoresPublicoEnum
} from 'models/types'

export enum TYPES {
  SET_TIPO_PERFIL = '@@profissional/SET_TIPO_PERFIL',
  SET_OPTIONS_SELECT = '@@profissional/SET_OPTIONS_SELECT',
  SET_CADASTRO_APOSENTADO = '@@profissional/SET_CADASTRO_APOSENTADO',
  SET_CADASTRO_APOSENTADO_INVALIDS = '@@profissional/SET_CADASTRO_APOSENTADO_INVALIDS',
  CLEAN_CADASTRO_APOSENTADO_INVALIDS = '@@profissional/CLEAN_CADASTRO_APOSENTADO_INVALIDS',
  CLEAN_CADASTRO_APOSENTADO = '@@profissional/CLEAN_CADASTRO_APOSENTADO',
  SET_CADASTRO_SERVIDOR = '@@profissional/SET_CADASTRO_SERVIDOR',
  SET_CADASTRO_SERVIDOR_INVALIDS = '@@profissional/SET_CADASTRO_SERVIDOR_INVALIDS',
  CLEAN_CADASTRO_SERVIDOR_INVALIDS = '@@profissional/CLEAN_CADASTRO_SERVIDOR_INVALIDS',
  CLEAN_CADASTRO_SERVIDOR = '@@profissional/CLEAN_CADASTRO_SERVIDOR',
  SET_CADASTRO_CLT = '@@profissional/SET_CADASTRO_CLT',
  SET_CADASTRO_CLT_INVALIDS = '@@profissional/SET_CADASTRO_CLT_INVALIDS',
  CLEAN_CADASTRO_CLT_INVALIDS = '@@profissional/CLEAN_CADASTRO_CLT_INVALIDS',
  CLEAN_CADASTRO_CLT = '@@profissional/CLEAN_CADASTRO_CLT',
  SET_LOADING_OPTIONS_SELECT = '@@profissional/SET_LOADING_OPTIONS_SELECT',

  GET_PERFIS_SAGA = '@@profissional/GET_PERFIS_SAGA',
  GET_ESTADOS_SAGA = '@@profissional/GET_ESTADOS_SAGA',
  GET_CIDADES_SAGA = '@@profissional/GET_CIDADES_SAGA',
  GET_CONVENIOS_SAGA = '@@profissional/GET_CONVENIOS_SAGA',
  CADASTRAR_APOSENTADO_SAGA = '@@profissional/CADASTRAR_APOSENTADO_SAGA',
  CADASTRAR_SERVIDOR_SAGA = '@@profissional/CADASTRAR_SERVIDOR_SAGA',
  CADASTRAR_CLT_SAGA = '@@profissional/CADASTRAR_CLT_SAGA'
}

export interface PROFISSIONAL {
  tipoPerfil: TiposPerfilSite | null
  optionsSelect: OptionsSelect
  loadingOptionsSelect: LoadingOptionsSelect
  cadastroAposentado: CadastroAposentado
  cadastroAposentadoInvalids: CadastroAposentadoInvalids
  cadastroServidor: CadastroServidor
  cadastroServidorInvalids: CadastroServidorInvalids
  cadastroClt: CadastroClt
  cadastroCltInvalids: CadastroCltInvalids
}

export enum TiposPerfilSite {
  APOSENTADO = 1,
  CLT = 2,
  SERVIDOR_MUNICIPAL = 3,
  SERVIDOR_FEDERAL = 4,
  SERVIDOR_ESTADUAL = 5,
  AUTONOMO = 6,
  EMPRESARIO = 7,
  PJ = 8
}

interface OptionsSelect {
  perfis: Perfil[]
  cidades: Cidade[]
  estados: Estado[]
  convenios: Convenio[]
}

type LoadingOptionsSelect = {
  [K in keyof OptionsSelect]: boolean
}

interface CadastroAposentado {
  matricula: string
  salario: string
  siglaEstado: string
  cidadeId: string
}

type CadastroAposentadoInvalids = {
  [K in keyof CadastroAposentado]: Invalid
}

interface CadastroServidor {
  matricula: string
  salario: string
  siglaEstado: string
  cidadeId: string
  convenioId: string
  margemEmprestimoConsignado: string
  margemCartaoConsignado: string
  margemCartaoBeneficio: string
  servidoresPublicoEnum: ServidoresPublicoEnum | null
}

type CadastroServidorInvalids = {
  [K in keyof CadastroServidor]: Invalid
}

interface CadastroClt {
  salario: string
  siglaEstado: string
  cidadeId: string
}

type CadastroCltInvalids = {
  [K in keyof CadastroClt]: Invalid
}

import React from 'react'
import SimpleModal from 'components/SimpleModal'
import { FiSmartphone } from 'react-icons/fi'
import { RiLoader2Line, RiFileListLine, RiHandCoinLine } from 'react-icons/ri'
import { Col, Row } from 'reactstrap'
import { InfoIconGreen, ModalIndicacaoWrapper } from 'views/simulacao/style'
import { CustomButton } from 'styles/global'
import { MetodosFgts } from 'store/modules/simulacao/types'
import useRedux from 'hooks/useRedux'
import * as simulacaoActions from 'store/modules/simulacao/actions'

interface Indicacao {
  isOpen: boolean
  toggle: () => void
  metodo: MetodosFgts | null
}

const Indicacao1 = ({ isOpen, toggle, metodo }: Indicacao) => {
  const { dispatch } = useRedux()
  function onToggle() {
    if (metodo === MetodosFgts.SolicitarSimulacao) {
      dispatch(simulacaoActions.getParcelasFgtsSaga())
    }
    toggle()
  }
  return (
    <SimpleModal toggle={onToggle} isOpen={isOpen} size="lg">
      <ModalIndicacaoWrapper>
        <div className="container-fluid d-flex flex-column row-gap-15 position-relative">
          {/* Botão de fechar estilizado */}
          <button
            onClick={onToggle}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'none',
              border: 'none',
              fontSize: '20px',
              cursor: 'pointer',
              color: 'gray'
            }}
            aria-label="Close"
          >
            &times;
          </button>
        </div>
        <div className="container-fluid d-flex flex-column row-gap-15">
          <div className="d-flex justify-content-center">
            <InfoIconGreen />
          </div>
          <h3 className="main-title">
            Você precisa aderir à modalidade de Saque Aniversário, no aplicativo
            FGTS e autorizar a realização da consulta do saldo do seu FGTS.
          </h3>
          <p className="text-center common-text">
            É bem simples, siga os passos abaixo:
          </p>
          <Row className="d-flex row-gap-20">
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <FiSmartphone color="var(--roxo-riber)" size={40} />
              <label className="text-center common-text">
                <span className="text-purple">1.</span> Acesse o aplicativo
                &quot;FGTS&quot;;
              </label>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <RiLoader2Line color="var(--roxo-riber)" size={40} />
              <label className="text-center common-text">
                <span className="text-purple">2.</span> Toque na opção
                &quot;Saque-Aniversário do FGTS&quot;;
              </label>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <RiFileListLine color="var(--roxo-riber)" size={40} />
              <label className="text-center common-text">
                <span className="text-purple">3.</span> Leia atentamente e
                marque a opção de &quot;termos e condições&quot;;
              </label>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <RiHandCoinLine color="var(--roxo-riber)" size={40} />
              <label className="text-center common-text">
                <span className="text-purple">4.</span> Toque no botão
                &quot;Optar pelo Saque aniversário&quot;;
              </label>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex align-items-center justify-content-center">
              <CustomButton
                background="var(--roxo-riber)"
                onClick={() => {
                  dispatch(simulacaoActions.getParcelasFgtsSaga())
                  toggle()
                }}
              >
                AVANÇAR
              </CustomButton>
            </Col>
          </Row>
        </div>
      </ModalIndicacaoWrapper>
    </SimpleModal>
  )
}

export default Indicacao1

import { Cidade, Estado, Invalid } from 'models/types'

export enum TYPES {
  SET_CPF_LANDING = '@@landingPage/SET_CPF_LANDING',
  CLEAN_CPF_LANDING = '@@landingPage/CLEAN_CPF_LANDING',
  SET_CPF_LANDING_INVALID = '@@landingPage/SET_CPF_LANDING_INVALID',
  CLEAN_CPF_LANDING_INVALID = '@@landingPage/CLEAN_CPF_LANDING_INVALID',
  SET_SEJA_PARCEIRO = '@@landingPage/SET_SEJA_PARCEIRO',
  CLEAN_SEJA_PARCEIRO = '@@landingPage/CLEAN_SEJA_PARCEIRO',
  SET_INVALID_SEJA_PARCEIRO = '@@landingPage/SET_INVALID_SEJA_PARCEIRO',
  CLEAN_INVALID_SEJA_PARCEIRO = '@@landingPage/CLEAN_INVALID_SEJA_PARCEIRO',
  SET_RIBER_MAIS_PERTO = '@@landingPage/SET_RIBER_MAIS_PERTO',
  CLEAN_RIBER_MAIS_PERTO = '@@landingPage/CLEAN_RIBER_MAIS_PERTO',
  SET_INVALID_RIBER_MAIS_PERTO = '@@landingPage/SET_INVALID_RIBER_MAIS_PERTO',
  CLEAN_INVALID_RIBER_MAIS_PERTO = '@@landingPage/CLEAN_INVALID_SEJA_PARCEIRO',
  SET_OPTIONS_SELECT = '@@landingPage/SET_OPTIONS_SELECT',
  SET_LOADING_SELECTS = '@@landingPage/SET_LOADING_SELECTS',
  SET_RIBER_MAIS_PERTO_LIST = '@@landingPage/SET_RIBER_MAIS_PERTO_LIST',
  CLEAN_DOWNLOADS = '@@landingPage/CLEAN_DOWNLOADS',
  SET_DOWNLOADS = '@@landingPage/SET_DOWNLOADS',
  SET_LOADING_DOWNLOADS = '@@landingPage/SET_LOADING_DOWNLOADS',
  SET_CPF_PRODUTOS = '@@landingPage/SET_CPF_PRODUTOS',
  CLEAN_CPF_PRODUTOS = '@@landingPage/CLEAN_CPF_PRODUTOS',
  SET_CPF_PRODUTOS_INVALID = '@@landingPage/SET_CPF_PRODUTOS_INVALID',
  CLEAN_CPF_PRODUTOS_INVALID = '@@landingPage/CLEAN_CPF_PRODUTOS_INVALID',
  OPEN_MODAL_CPF = '@@landingPage/OPEN_MODAL_CPF',
  SET_MODAL_PDF = '@@landingPage/SET_MODAL_PDF',

  INICIAR_SAGA = '@@landingPage/INICIAR_SAGA',
  SEJA_PARCEIRO_SAGA = '@@landingPage/SEJA_PARCEIRO_SAGA',
  GET_CIDADES_SAGA = '@@landingPage/GET_CIDADES_SAGA',
  GET_ESTADOS_SAGA = '@@landingPage/GET_ESTADOS_SAGA',
  GET_RIBER_MAIS_PERTO_SAGA = '@@landingPage/GET_RIBER_MAIS_PERTO_SAGA',
  GET_AVISO_PRIVACIDADE_SAGA = '@@landingPage/GET_AVISO_PRIVACIDADE_SAGA',
  GET_POLITICA_PRIVACIDADE_SAGA = '@@landingPage/GET_POLITICA_PRIVACIDADE_SAGA',
  GET_TERMO_POLITICA_SAGA = '@@landingPage/GET_TERMO_POLITICA_SAGA',
  INICIAR_PRODUTOS_SAGA = '@@landingPage/INICIAR_PRODUTOS_SAGA'
}

export interface LANDING_PAGE {
  cpfLanding: string
  cpfLandingInvalid: Invalid
  sejaParceiro: SejaParceiro
  invalidSejaParceiro: InvalidSejaParceiro
  riberMaisPerto: RiberMaisPerto
  invalidRiberMaisPerto: InvalidRiberMaisPerto
  optionsSelects: OptionsSelects
  loadingSelects: LoadingSelects
  riberMaisPertoList: RiberMaisPertoList[]
  downloads: Downloads
  loadingDownloads: LoadingDownloads
  cpfProdutos: string
  cpfProdutosInvalid: Invalid
  modalPdf: boolean
  modalCpf: boolean
}

interface SejaParceiro {
  nome: string
  email: string
  telefone: string
}

type InvalidSejaParceiro = {
  [K in keyof SejaParceiro]: Invalid
}

interface RiberMaisPerto {
  siglaEstado: string
  cidade: string
}

type InvalidRiberMaisPerto = {
  [K in keyof RiberMaisPerto]: Invalid
}

interface OptionsSelects {
  cidades: Cidade[]
  estados: Estado[]
}

type LoadingSelects = {
  [K in keyof OptionsSelects]: boolean
}

interface RiberMaisPertoList {
  enderecoExtenso: string
  latitude: number
  longitude: number
}

interface Downloads {
  pdf: Pdf
}

type LoadingDownloads = {
  [k in keyof Downloads]: boolean
}

export interface Pdf {
  base64: string
  nome: string
}

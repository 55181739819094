import useRedux from 'hooks/useRedux'
import React, { useEffect, useState } from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import routes from 'routes'
import { ApplicationState } from 'store'
import { toggleSidebar } from 'store/modules/sidebar/actions'
import { SIDEBAR } from 'store/modules/sidebar/types'
import { Background } from './style'

interface BreadcrumbsProps {
  title?: string
  subtitle?: string
}

function Breadcrumbs({ title, subtitle }: BreadcrumbsProps) {
  const { isOpen } = useSelector<ApplicationState, SIDEBAR>(
    (state) => state.sidebar
  )

  const [breadcrumb, setBreadcrumb] = useState<
    { title: string; icon: JSX.Element; path: string } | undefined
  >({
    title: '',
    icon: <></>,
    path: ''
  })

  const location = useLocation()

  const getLocation = () => {
    const simpleRoutes: { title: string; icon: JSX.Element; path: string }[] =
      []
    routes.forEach((route) => {
      simpleRoutes.push({
        title: route.title,
        icon: route.icon,
        path: route.path
      })
    })
    const pathNameSplited = location.pathname.split('/')
    const newBreadcrumb = simpleRoutes.find(
      (simple) =>
        simple.path.toUpperCase() === '/' + pathNameSplited[1].toUpperCase()
    )
    setBreadcrumb(newBreadcrumb)
  }

  useEffect(() => {
    getLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const { dispatch } = useRedux()

  return (
    <Background>
      <span className="breadcrumb">
        {!isOpen && (
          <IoIosArrowForward
            className="icon onlyMobile pointer"
            onClick={() => {
              dispatch(toggleSidebar())
            }}
          />
        )}
        {breadcrumb?.icon}
        {breadcrumb?.title} /
      </span>
      {(title || subtitle) && (
        <div className="conteudo">
          <label>{title}</label>
          <span>{subtitle}</span>
        </div>
      )}
    </Background>
  )
}

export default Breadcrumbs

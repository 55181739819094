import { Reducer } from 'redux'
import { PROPOSTAS, TYPES } from './types'

const INITIAL_STATE: PROPOSTAS = {
  propostas: []
}

const reducer: Reducer<PROPOSTAS> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_PROPOSTAS_JA_CONTRATADAS:
      return { ...state, propostas: action.value }
    case TYPES.CLEAN_PROPOSTAS_JA_CONTRATADAS:
      return { ...state, propostas: INITIAL_STATE.propostas }
    default:
      return state
  }
}

export default reducer

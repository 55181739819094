import { combineReducers } from 'redux'
import landingPage from './landingPage'
import login from './login'
import navbar from './navbar'
import sidebar from './sidebar'
import loading from './loading'
import simulacao from './simulacao'
import propostas from './propostas'
import meusDados from './meusDados'
import calendario from './utilitarios/calendario'
import calculadora from './utilitarios/calculadora'
import cadastroInicial from './cadastros/cadastroInicial'
import profissional from './cadastros/profissional'
import validacao from './cadastros/validacao'
import dadosComplementaresDadosBancarios from './cadastros/dadosComplementaresDadosBancarios'
import dadosComplementaresDocumento from './cadastros/dadosComplementaresDocumento'
import dadosComplementaresEndereco from './cadastros/dadosComplementaresEndereco'
import dadosComplementaresInfosPessoal from './cadastros/dadosComplementaresInfoPessoal'

export default combineReducers({
  landingPage,
  login,
  navbar,
  sidebar,
  loading,
  simulacao,
  propostas,
  meusDados,
  calendario,
  calculadora,
  cadastroInicial,
  profissional,
  validacao,
  dadosComplementaresDadosBancarios,
  dadosComplementaresDocumento,
  dadosComplementaresEndereco,
  dadosComplementaresInfosPessoal
})

import { PROFISSIONAL, TYPES } from './types'

export const setTipoPerfil = (value: number) => ({
  type: TYPES.SET_TIPO_PERFIL,
  value
})

type OptionsSelectFields = keyof PROFISSIONAL['optionsSelect']
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setOptionsSelect = (value: any[], field: OptionsSelectFields) => ({
  type: TYPES.SET_OPTIONS_SELECT,
  value,
  field
})

export const getPerfisSaga = () => ({
  type: TYPES.GET_PERFIS_SAGA
})

export const getEstadosSaga = () => ({
  type: TYPES.GET_ESTADOS_SAGA
})

export const getCidadesSaga = (value: string) => ({
  type: TYPES.GET_CIDADES_SAGA,
  value
})

export const getConveniosSaga = () => ({
  type: TYPES.GET_CONVENIOS_SAGA
})

type LoadingOptionsSelect = keyof PROFISSIONAL['loadingOptionsSelect']
export const setLoadingOptionsSelect = <T extends LoadingOptionsSelect>(
  value: T extends keyof PROFISSIONAL['loadingOptionsSelect']
    ? PROFISSIONAL['loadingOptionsSelect'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_LOADING_OPTIONS_SELECT,
  value,
  field
})

type CadastroAposentadoFields = keyof PROFISSIONAL['cadastroAposentado']
export const setCadastroAposentado = <T extends CadastroAposentadoFields>(
  value: T extends keyof PROFISSIONAL['cadastroAposentado']
    ? PROFISSIONAL['cadastroAposentado'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_CADASTRO_APOSENTADO,
  value,
  field
})

export const cleanCadastroAposentado = () => ({
  type: TYPES.CLEAN_CADASTRO_APOSENTADO
})

type CadastroAposentadoInvalidsFields =
  keyof PROFISSIONAL['cadastroAposentadoInvalids']
export const setCadastroAposentadoInvalid = <
  T extends CadastroAposentadoInvalidsFields
>(
  value: T extends keyof PROFISSIONAL['cadastroAposentadoInvalids']
    ? PROFISSIONAL['cadastroAposentadoInvalids'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_CADASTRO_APOSENTADO_INVALIDS,
  value,
  field
})

export const cleanCadastroAposentadoInvalids = () => ({
  type: TYPES.CLEAN_CADASTRO_APOSENTADO_INVALIDS
})

type CadastroServidorFields = keyof PROFISSIONAL['cadastroServidor']
export const setCadastroServidor = <T extends CadastroServidorFields>(
  value: T extends keyof PROFISSIONAL['cadastroServidor']
    ? PROFISSIONAL['cadastroServidor'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_CADASTRO_SERVIDOR,
  value,
  field
})

export const cleanCadastroServidor = () => ({
  type: TYPES.CLEAN_CADASTRO_SERVIDOR
})

type CadastroServidorInvalidsFields =
  keyof PROFISSIONAL['cadastroServidorInvalids']
export const setCadastroServidorInvalids = <
  T extends CadastroServidorInvalidsFields
>(
  value: T extends keyof PROFISSIONAL['cadastroServidorInvalids']
    ? PROFISSIONAL['cadastroServidorInvalids'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_CADASTRO_SERVIDOR_INVALIDS,
  value,
  field
})

export const cleanCadastroServidorInvalids = () => ({
  type: TYPES.CLEAN_CADASTRO_SERVIDOR_INVALIDS
})

export const cadastrarAposentadoSaga = () => ({
  type: TYPES.CADASTRAR_APOSENTADO_SAGA
})

export const cadastrarServidorSaga = () => ({
  type: TYPES.CADASTRAR_SERVIDOR_SAGA
})

type CadastroCltFields = keyof PROFISSIONAL['cadastroClt']
export const setCadastroClt = <T extends CadastroCltFields>(
  value: T extends keyof PROFISSIONAL['cadastroClt']
    ? PROFISSIONAL['cadastroClt'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_CADASTRO_CLT,
  value,
  field
})

export const cleanCadastroClt = () => ({
  type: TYPES.CLEAN_CADASTRO_CLT
})

type CadastroCltInvalidsFields = keyof PROFISSIONAL['cadastroCltInvalids']
export const setCadastroCltInvalid = <T extends CadastroCltInvalidsFields>(
  value: T extends keyof PROFISSIONAL['cadastroCltInvalids']
    ? PROFISSIONAL['cadastroCltInvalids'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_CADASTRO_CLT_INVALIDS,
  value,
  field
})

export const cleanCadastroCltInvalids = () => ({
  type: TYPES.CLEAN_CADASTRO_CLT_INVALIDS
})

export const cadastrarCltSaga = () => ({
  type: TYPES.CADASTRAR_CLT_SAGA
})

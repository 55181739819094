import { TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as loadingActions from 'store/modules/loading/actions'
import * as profissionalActions from 'store/modules/cadastros/profissional/actions'
import * as profissionalController from 'controller/profissionalController'
import { PROFISSIONAL } from './types'
import { ApplicationState } from 'store'
import { handlerError } from 'util/handlerError'
import { toast } from 'react-toastify'
import { removeMaskMoney } from 'util/masks'
import history from 'util/history'

interface GenericProps {
  type: string
}

function* getPerfis(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(profissionalController.getPerfis)
    yield put(
      profissionalActions.setOptionsSelect(response?.data?.content, 'perfis')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getEstados(): SagaIterator {
  try {
    yield put(profissionalActions.setLoadingOptionsSelect(true, 'estados'))
    const response = yield call(profissionalController.getEstados)
    yield put(
      profissionalActions.setOptionsSelect(response?.data?.content, 'estados')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(profissionalActions.setLoadingOptionsSelect(false, 'estados'))
  }
}

interface GetCidadesProps extends GenericProps {
  value: string
}

function* getCidades({ value }: GetCidadesProps): SagaIterator {
  try {
    yield put(profissionalActions.setLoadingOptionsSelect(true, 'cidades'))
    const response = yield call(profissionalController.getCidades, value)
    yield put(
      profissionalActions.setOptionsSelect(response?.data?.content, 'cidades')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(profissionalActions.setLoadingOptionsSelect(false, 'cidades'))
  }
}

function* getConvenios(): SagaIterator {
  try {
    yield put(profissionalActions.setLoadingOptionsSelect(true, 'convenios'))
    const response = yield call(profissionalController.getConvenios)
    yield put(
      profissionalActions.setOptionsSelect(response?.data?.content, 'convenios')
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(profissionalActions.setLoadingOptionsSelect(false, 'convenios'))
  }
}

function* cadastroAposentado(): SagaIterator {
  const { cadastroAposentado }: PROFISSIONAL = yield select(
    (state: ApplicationState) => state.profissional
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(profissionalController.cadastroAposentado, {
      matricula: cadastroAposentado.matricula,
      salario: Number(removeMaskMoney(cadastroAposentado.salario)),
      siglaEstado: cadastroAposentado.siglaEstado,
      cidadeId: cadastroAposentado.cidadeId
    })
    history.push('/validacao')
    toast.success('Informações salvas com sucesso')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* cadastroServidor(): SagaIterator {
  const { cadastroServidor }: PROFISSIONAL = yield select(
    (state: ApplicationState) => state.profissional
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(profissionalController.cadastroServidor, {
      matricula: cadastroServidor.matricula,
      convenioId: cadastroServidor.convenioId,
      salario: Number(removeMaskMoney(cadastroServidor.salario)),
      siglaEstado: cadastroServidor.siglaEstado,
      cidadeId: cadastroServidor.cidadeId,
      margemEmprestimoConsignado: Number(
        removeMaskMoney(cadastroServidor.margemEmprestimoConsignado)
      ),
      margemCartaoConsignado: Number(
        removeMaskMoney(cadastroServidor.margemCartaoBeneficio)
      ),
      margemCartaoBeneficio: Number(
        removeMaskMoney(cadastroServidor.margemCartaoBeneficio)
      ),
      servidoresPublicoEnum: cadastroServidor.servidoresPublicoEnum ?? 3
    })
    history.push('/simulacao')
    toast.success('Informações salvas com sucesso')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* cadastroClt(): SagaIterator {
  const { cadastroClt }: PROFISSIONAL = yield select(
    (state: ApplicationState) => state.profissional
  )
  try {
    yield put(loadingActions.setLoading(true))
    yield call(profissionalController.cadastroClt, {
      salario: Number(removeMaskMoney(cadastroClt.salario)),
      siglaEstado: cadastroClt.siglaEstado,
      cidadeId: cadastroClt.cidadeId
    })
    history.push('/simulacao')
    toast.success('Informações salvas com sucesso')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetPerfis() {
  yield takeEvery(TYPES.GET_PERFIS_SAGA, getPerfis)
}

export function* watchGeEstados() {
  yield takeEvery(TYPES.GET_ESTADOS_SAGA, getEstados)
}

export function* watchGetCidades() {
  yield takeEvery(TYPES.GET_CIDADES_SAGA, getCidades)
}

export function* watchGetConvenios() {
  yield takeEvery(TYPES.GET_CONVENIOS_SAGA, getConvenios)
}

export function* watchCadastroAposentado() {
  yield takeEvery(TYPES.CADASTRAR_APOSENTADO_SAGA, cadastroAposentado)
}

export function* watchCadastroServidor() {
  yield takeEvery(TYPES.CADASTRAR_SERVIDOR_SAGA, cadastroServidor)
}

export function* watchCadastroClt() {
  yield takeEvery(TYPES.CADASTRAR_CLT_SAGA, cadastroClt)
}

function* profissional() {
  yield all([
    fork(watchGetPerfis),
    fork(watchGeEstados),
    fork(watchGetCidades),
    fork(watchGetConvenios),
    fork(watchCadastroAposentado),
    fork(watchCadastroServidor),
    fork(watchCadastroClt)
  ])
}

export default profissional

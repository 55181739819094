import { TYPES, VALIDACAO } from './types'

type ValidacaoFields = keyof VALIDACAO['validacaoFields']
export const setValidacaoFields = <T extends ValidacaoFields>(
  value: T extends keyof VALIDACAO['validacaoFields']
    ? VALIDACAO['validacaoFields'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_VALIDACAO_FIELDS,
  value,
  field
})

export const cleanValidacaoFields = () => ({
  type: TYPES.CLEAN_VALIDACAO_FIELDS
})

type ValidacaoFieldsInvalids = keyof VALIDACAO['validacaoFieldsInvalids']
export const setValidacaoFieldsInvalids = <T extends ValidacaoFieldsInvalids>(
  value: T extends keyof VALIDACAO['validacaoFieldsInvalids']
    ? VALIDACAO['validacaoFieldsInvalids'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_VALIDACAO_FIELDS_INVALIDS,
  value,
  field
})

export const cleanValidacaoFieldsInvalids = () => ({
  type: TYPES.CLEAN_VALIDACAO_FIELDS_INVALIDS
})

export const inserirSolicitacoesIn100Saga = () => ({
  type: TYPES.INSERIR_SOLICITACOES_IN100_SAGA
})

export const pesquisarSolicitacaoIn100Saga = () => ({
  type: TYPES.PESQUISAR_SOLICITACAO_IN100_SAGA
})

export const validarTokenIn100Saga = () => ({
  type: TYPES.VALIDAR_TOKEN_IN100_SAGA
})

export const getTelefoneMatriculaInsereSolicitacao = () => ({
  type: TYPES.GET_TELEFONE_MATRICULA_INSERE_SOLICITACAO_SAGA
})

import { CADASTRO_INICIAL, TYPES } from './types'

type FormularioField = keyof CADASTRO_INICIAL['formulario']
export const setFormularioField = <T extends FormularioField>(
  value: T extends keyof CADASTRO_INICIAL['formulario']
    ? CADASTRO_INICIAL['formulario'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_FORMULARIO,
  value,
  field
})

export const cleanFormulario = () => ({
  type: TYPES.CLEAN_FORMULARIO
})

type InvalidFormularioField = keyof CADASTRO_INICIAL['invalidFormulario']
export const setInvalidFormularioField = <T extends InvalidFormularioField>(
  value: T extends keyof CADASTRO_INICIAL['invalidFormulario']
    ? CADASTRO_INICIAL['invalidFormulario'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_INVALID_FORMULARIO,
  value,
  field
})

export const cleanInvalidsFormulario = () => ({
  type: TYPES.CLEAN_INVALIDS_FORMULARIO
})

type ModalSenhaField = keyof CADASTRO_INICIAL['modalSenha']
export const setModalSenhaField = <T extends ModalSenhaField>(
  value: T extends keyof CADASTRO_INICIAL['modalSenha']
    ? CADASTRO_INICIAL['modalSenha'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_MODAL_SENHA_FIELD,
  value,
  field
})

export const cleanModalSenha = () => ({
  type: TYPES.CLEAN_MODAL_SENHA
})

type InvalidModalSenhaField = keyof CADASTRO_INICIAL['invalidModalSenha']
export const setInvalidModalSenhaField = <T extends InvalidModalSenhaField>(
  value: T extends keyof CADASTRO_INICIAL['invalidModalSenha']
    ? CADASTRO_INICIAL['invalidModalSenha'][T]
    : never,
  field: T
) => ({
  type: TYPES.SET_INVALID_MODAL_SENHA_FIELD,
  value,
  field
})

export const cleanInvalidsModalSenha = () => ({
  type: TYPES.CLEAN_INVALIDS_MODAL_SENHA
})

export const setShowModalSenha = (value: boolean) => ({
  type: TYPES.SET_SHOW_MODAL_SENHA,
  value
})

export const setShowModalAvisoPrivacidade = (value: boolean) => ({
  type: TYPES.SET_SHOW_MODAL_AVISO_PRIVACIDADE,
  value
})

export const salvarCadastroInicialSaga = () => ({
  type: TYPES.SALVAR_CADASTRO_INICIAL_SAGA
})

export const setLoadingAvisoPrivacidade = (
  value: CADASTRO_INICIAL['loadingAvisoPrivacidade']
) => ({
  type: TYPES.SET_LOADING_AVISO_PRIVACIDADE,
  value
})

export const setAvisoPrivacidadeBase64 = (
  value: CADASTRO_INICIAL['avisoPrivacidadeBase64']
) => ({
  type: TYPES.SET_AVISO_PRIVACIDADE,
  value
})

export const getAvisoPrivacidadeBase64 = () => ({
  type: TYPES.GET_AVISO_PRIVACIDADE_SAGA
})

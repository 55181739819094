import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { TabContent, TabPane } from 'reactstrap'
import { Tab, Ul } from './style'

interface Tabs {
  id: number
  descricao: string
  component: React.ReactNode
}

interface SimpleTabProps {
  tabs: Tabs[]
  activeTab?: number
  actionSetTab?: (e: number) => void
}

function SimpleTab({ tabs, activeTab, actionSetTab }: SimpleTabProps) {
  const [activeTabState, setActiveTabState] = useState(
    activeTab ? activeTab : 0
  )

  useEffect(() => {
    if (activeTabState > tabs.length - 1 && tabs.length !== 0) {
      setActiveTabState(tabs.length - 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs])

  useEffect(() => {
    if (activeTab || activeTab === 0) {
      if (activeTab > tabs.length - 1 && tabs.length !== 0) {
        setActiveTabState(tabs.length - 1)
      } else {
        setActiveTabState(activeTab)
      }
    }
  }, [activeTab, tabs.length])

  return (
    <>
      <Ul id="tabs" className="nav" data-tabs="tabs">
        {tabs?.map((tab, i) => {
          return (
            <Tab key={i}>
              <a
                role="button"
                className={classNames(activeTabState === i && 'active')}
                onClick={() => {
                  actionSetTab?.(i)
                  setActiveTabState(i)
                }}
              >
                {tab?.descricao}
              </a>
            </Tab>
          )
        })}
      </Ul>
      <TabContent activeTab={activeTabState}>
        {tabs?.map((tab, i) => {
          return (
            <TabPane tabId={i} key={i}>
              {tab.component}
            </TabPane>
          )
        })}
      </TabContent>
    </>
  )
}

export default SimpleTab

import { APICore, setAuthorization } from 'helpers/api/apiCore'

const api = new APICore()

interface Login {
  email: string
  senha: string
}
export function loginSimulador(login: Login) {
  return api.create(`Autenticacao/Logar`, {
    email: login.email,
    senha: login.senha
  })
}

export function logoutSistema() {
  setAuthorization(null)
  !api.isAuthenticated() && window.location.replace('/')
}

export function recuperarAcesso(email: string) {
  return api.create(`Autenticacao/lembrar-senha?login=${email}`, null)
}

export function validaGuidRecuperacao(id: string) {
  return api.get(
    `Autenticacao/verifica-redefinicao-senha?idRecuperacaoSenha=${id}`,
    null
  )
}

export function redefinirSenha(
  id: string,
  senha: string,
  confirmacaoSenha: string
) {
  return api.create(`Autenticacao/redefinir-senha`, {
    novaSenha: senha,
    confirmacaoSenha: confirmacaoSenha,
    idRecuperacaoSenha: id
  })
}

export function getStatusLead() {
  return api.get(`Generic/status-lead`, null)
}

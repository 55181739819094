import React from 'react'
import { Input, InputProps } from 'reactstrap'
import { InputArea } from './style'
import { InfosInputs } from 'styles/global'
import { Tooltip } from '@mui/material'

interface InputCustomProps extends InputProps {
  errorMessage?: string
  infoMessage?: string
}

function InputCustom({ errorMessage, infoMessage, ...rest }: InputCustomProps) {
  return (
    <InputArea type={rest.type || ''}>
      <Input {...rest} max={rest.type === 'date' ? '2999-12-31' : rest.max} />
      {infoMessage && <InfosInputs>{infoMessage}</InfosInputs>}
      {rest.invalid && errorMessage && (
        <Tooltip title={errorMessage} placement="bottom-start">
          <span className="error-message">{errorMessage}</span>
        </Tooltip>
      )}
    </InputArea>
  )
}

export default InputCustom

import React from 'react'
import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import { FiGlobe } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { SimpleTitleCard } from 'styles/global'
import * as meusDadosActions from 'store/modules/meusDados/actions'
import { MEUS_DADOS } from 'store/modules/meusDados/types'
import { maskCEP, maskNumber } from 'util/masks'
import { ApplicationState } from 'store'
import { useSelector } from 'react-redux'
import useRedux from 'hooks/useRedux'

const Endereco = () => {
  const { meusDados, invalidMeusDados, optionsSelects, loadingSelects } =
    useSelector<ApplicationState, MEUS_DADOS>((state) => state.meusDados)

  const { dispatch } = useRedux()

  type MeusDadosField = keyof MEUS_DADOS['meusDados']
  const onChange = <T extends MeusDadosField>(
    value: T extends keyof MEUS_DADOS['meusDados']
      ? MEUS_DADOS['meusDados'][T]
      : never,
    field: T
  ) => {
    switch (field) {
      case 'cep':
        dispatch(meusDadosActions.setMeusDadosField(value, field))
        if (value && value.toString().length === 9) {
          dispatch(meusDadosActions.getDadosEnderecoSaga())
        }
        break
      case 'siglaEstado':
        dispatch(meusDadosActions.setMeusDadosField('', 'cidade'))
        dispatch(meusDadosActions.setMeusDadosField(value, field))
        break
      default:
        dispatch(meusDadosActions.setMeusDadosField(value, field))
        break
    }
  }

  return (
    <>
      <SimpleTitleCard>
        <FiGlobe className="icon" /> Endereço
      </SimpleTitleCard>
      <div className="container-fluid  mb-4">
        <Row>
          <Col md={4}>
            <label className="label-12">CEP*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskCEP(e.target.value), 'cep')
              }}
              value={meusDados.cep}
              invalid={invalidMeusDados.cep.invalid}
              errorMessage={invalidMeusDados.cep.message}
              maxLength={9}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">UF*</label>
            <CustomSelect
              options={optionsSelects.ufs}
              isLoading={loadingSelects.ufs}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'siglaEstado')
              }}
              value={meusDados.siglaEstado}
              accessorLabel="nome"
              accessorValue="sigla"
              invalid={invalidMeusDados.siglaEstado.invalid}
              errorMessage={invalidMeusDados.siglaEstado.message}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Cidade*</label>
            <CustomSelect
              options={optionsSelects.cidades}
              isLoading={loadingSelects.cidades}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'cidade')
              }}
              value={meusDados.cidade}
              accessorLabel="nome"
              accessorValue="nome"
              invalid={invalidMeusDados.cidade.invalid}
              errorMessage={invalidMeusDados.cidade.message}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label className="label-12">Bairro*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'bairro')
              }}
              value={meusDados.bairro}
              invalid={invalidMeusDados.bairro.invalid}
              errorMessage={invalidMeusDados.bairro.message}
              maxLength={100}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Logradouro*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'logradouro')
              }}
              value={meusDados.logradouro}
              invalid={invalidMeusDados.logradouro.invalid}
              errorMessage={invalidMeusDados.logradouro.message}
              maxLength={100}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Número*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskNumber(e.target.value), 'numeroEndereco')
              }}
              value={meusDados.numeroEndereco}
              invalid={invalidMeusDados.numeroEndereco.invalid}
              errorMessage={invalidMeusDados.numeroEndereco.message}
              maxLength={6}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label className="label-12">Complemento</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'complemento')
              }}
              value={meusDados.complemento || ''}
              invalid={invalidMeusDados.complemento?.invalid}
              errorMessage={invalidMeusDados.complemento?.message}
              maxLength={50}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Endereco

import React from 'react'
import { EfetuacaoEmprestimoWrapper } from '../style'
import steps from 'assets/images/steps.png'

const EfetuacaoEmprestimo = () => {
  return (
    <EfetuacaoEmprestimoWrapper>
      <div>
        <img src={steps} />
      </div>
      <div>
        <label>
          Como Solicitar seu Empréstimo <br /> na <span>Riber</span>?
        </label>
        <ul>
          <li>
            <label>
              <div className="number">1</div>
              Início rápido com seu CPF
            </label>
            <span>
              Insira seu CPF e receba uma análise de perfil em tempo recorde –{' '}
              apenas dois minutos!
            </span>
          </li>
          <li>
            <label>
              <div className="number">2</div>
              Escolha a melhor oferta
            </label>
            <span>
              Navegue pelas opções disponíveis e selecione a que melhor atende
              às suas necessidades financeiras para começar a simulação.
            </span>
          </li>
          <li>
            <label>
              <div className="number">3</div>
              Personalize seu empréstimo
            </label>
            <span>
              Customize seu empréstimo ajustando o valor, escolhendo o número de
              parcelas e visualizando as taxas aplicáveis, tudo isso para
              encaixar perfeitamente em seu orçamento.
            </span>
          </li>
          <li>
            <label>
              <div className="number">4</div>
              Cadastro seguro de informações
            </label>
            <span>
              Complete seu cadastro fornecendo alguns dados pessoais e enviando
              fotos dos seus documentos com total segurança. Confira todos os
              detalhes antes de prosseguir – a precisão é essencial para a
              validação do seu contrato.
            </span>
          </li>
          <li>
            <label>
              <div className="number">5</div>
              Assinatura digital e liberação dos fundos
            </label>
            <span>
              Finalize o processo com sua assinatura digital: uma simples selfie
              pode validar seu contrato. Após a aprovação, aguarde o depósito em
              sua conta dentro de 3 dias úteis e aproveite a sua nova
              flexibilidade financeira.
            </span>
          </li>
        </ul>
      </div>
    </EfetuacaoEmprestimoWrapper>
  )
}

export default EfetuacaoEmprestimo

import React from 'react'
import SimpleModal from 'components/SimpleModal'
import { FiSmartphone } from 'react-icons/fi'
import {
  RiToggleLine,
  RiFileListLine,
  RiBankLine,
  RiHandCoinLine,
  RiCheckLine
} from 'react-icons/ri'
import { Col, Row } from 'reactstrap'
import { ModalIndicacaoWrapper, InfoIconGreen } from 'views/simulacao/style'
import { MetodosFgts } from 'store/modules/simulacao/types'
import useRedux from 'hooks/useRedux'
import * as simulacaoActions from 'store/modules/simulacao/actions'
import { CustomButton } from 'styles/global'

interface Indicacao {
  isOpen: boolean
  toggle: () => void
  metodo: MetodosFgts | null
}

const Indicacao2 = ({ isOpen, toggle, metodo }: Indicacao) => {
  const { dispatch } = useRedux()
  function onToggle() {
    if (metodo === MetodosFgts.SolicitarSimulacao) {
      dispatch(simulacaoActions.getParcelasFgtsSaga())
    }
    toggle()
  }
  return (
    <SimpleModal toggle={onToggle} isOpen={isOpen} size="lg">
      <ModalIndicacaoWrapper>
        <div className="container-fluid d-flex flex-column row-gap-15 position-relative">
          {/* Botão de fechar estilizado */}
          <button
            onClick={onToggle}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'none',
              border: 'none',
              fontSize: '20px',
              cursor: 'pointer',
              color: 'gray'
            }}
            aria-label="Close"
          >
            &times;
          </button>
        </div>
        <div className="container-fluid d-flex flex-column row-gap-15">
          <div className="d-flex justify-content-center">
            <InfoIconGreen />
          </div>
          <h3 className="main-title">
            Agora, você precisa autorizar a realização da consulta do saldo do
            seu FGTS pelo Banco BMG.
          </h3>
          <Row className="d-flex row-gap-20">
            <Col
              md={4}
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <FiSmartphone color="var(--roxo-riber)" size={40} />
              <label className="text-center common-text">
                <span className="text-purple">1.</span> Selecione a opção
                &quot;Autorizar Bancos a consultarem seu FGTS&quot;;
              </label>
            </Col>
            <Col
              md={4}
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <RiToggleLine color="var(--roxo-riber)" size={40} />
              <label className="text-center common-text">
                <span className="text-purple">2.</span> Clique em Empréstimo
                Saque Aniversário;
              </label>
            </Col>
            <Col
              md={4}
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <RiFileListLine color="var(--roxo-riber)" size={40} />
              <label className="text-center common-text">
                <span className="text-purple">3.</span> Leia as condições e
                marque a opção de &quot;Li e aceito os termos e condições&quot;;
              </label>
            </Col>
            <Col
              md={4}
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <RiBankLine color="var(--roxo-riber)" size={40} />
              <label className="text-center common-text">
                <span className="text-purple">4.</span> Clique em
                &quot;Adicionar Instituição +&quot;;
              </label>
            </Col>
            <Col
              md={4}
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <RiHandCoinLine color="var(--roxo-riber)" size={40} />
              <label className="text-center common-text">
                <span className="text-purple">5.</span> Procure por &quot;Banco
                Bmg S.A&quot;;
              </label>
            </Col>
            <Col
              md={4}
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <RiCheckLine color="var(--roxo-riber)" size={40} />
              <label className="text-center common-text">
                <span className="text-purple">6.</span> Autorize a consulta
                clicando em &quot;sim&quot; e pronto;
              </label>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex align-items-center justify-content-center">
              <CustomButton
                background="var(--roxo-riber)"
                onClick={() => {
                  dispatch(simulacaoActions.getParcelasFgtsSaga())
                  toggle()
                }}
              >
                SIMULAR
              </CustomButton>
            </Col>
          </Row>
        </div>
      </ModalIndicacaoWrapper>
    </SimpleModal>
  )
}

export default Indicacao2

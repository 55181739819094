import styled, { keyframes } from 'styled-components'
import { createGlobalStyle } from 'styled-components'

export const goRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

export const goLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

interface SimpleTitleCardProps {
  color?: string
  colorIcon?: string
}

export const SimpleTitleCard = styled.div<SimpleTitleCardProps>`
  align-items: center;
  color: ${(props) => (props.color ? `${props.color}` : 'var(--dark-gray)')};
  display: flex;
  flex-direction: row;
  font-family: 'Open Sans', serif;
  font-size: 18px !important;
  font-weight: 700;
  gap: 10px;
  justify-content: left;
  padding-bottom: 20px;

  & .icon {
    color: ${(props) =>
      props.colorIcon ? `#${props.colorIcon}` : 'var(--verde-riber)'};
    width: 25px;
    height: 25px;
  }
`

interface ButtonProps {
  width?: number | string
  background?: string
  borderRadius?: number
  letterSpacing?: number
  justifyContent?: string
  color?: string
  padding?: string
  height?: string
}

export const CustomButton = styled.button<ButtonProps>`
  align-items: center;
  background: ${(props) =>
    props.background
      ? props.background
      : 'linear-gradient(264.61deg, #87df41 -9.34%, var(--verde-riber) 101.57%)'};
  border: none;
  border-radius: ${(props) =>
    props.borderRadius ? `${props.borderRadius}px` : '15px'};
  color: ${(props) => (props.color ? props.color : '#fff')};
  display: flex;
  padding: ${(props) => (props.padding ? `${props.padding}px` : '10px')};
  font-weight: 700;
  font-size: 14px;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'center'};
  letter-spacing: ${(props) =>
    props.letterSpacing ? `${props.letterSpacing}px` : '0px'};
  line-height: 18px;
  height: ${(props) => (props.height ? `${props.height}` : '40px')};
  width: ${(props) => (props.width ? `${props.width}` : '180px')};

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`

export const InfosInputs = styled.div`
  color: var(--cinza-riber);
  font-size: 10px;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
`

export const Range = styled.input.attrs({ type: 'range' })`
  appearance: none;
  margin: 10px 0;
  width: 100%;
  height: 7px;
  border-radius: 5px;
  background: var(--cinzaClaro-riber)
    linear-gradient(to left, #87df41, var(--verde-riber)) no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--branco);
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
  }

  &::-moz-range-thumb {
    appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--branco);
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
  }

  &::-ms-thumb {
    appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--branco);
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background 0.3s ease-in-out;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-moz-range-track {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-ms-track {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px var(--preto), 0 0 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }

  &::-moz-range-thumb {
    box-shadow: 1px 1px 1px var(--preto), 0px 0px 1px #0d0d0d;
    border: 1px solid var(--preto);
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: var(--branco);
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px var(--preto), 0 0 1px #0d0d0d;
  }

  &::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px var(--preto), 0 0 1px #0d0d0d;
  }

  &::-ms-thumb {
    box-shadow: 1px 1px 1px var(--preto), 0 0 1px #0d0d0d;
    border: 1px solid var(--preto);
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: var(--branco);
    cursor: pointer;
  }

  &:focus::-ms-fill-lower {
    background: #3071a9;
  }

  &:focus::-ms-fill-upper {
    background: #367ebd;
  }
`

const GlobalStyle = createGlobalStyle`
  :root {
    --amarelo-riber: #F7931E;
    --vermelho-riber: #F15A24;
    --azul-riber: #29ABE2;
    --marinho-riber: #0071BC;
    --roxo-riber: #662D91;
    --verde-riber: #00AB4F;
    --preto-riber: #353535;
    --cinza-riber: #8E8E8E;
    --cinzaClaro-riber: #CFCFCF;
    --verdeGradiente-riber: linear-gradient(264.61deg, #87DF41 -9.34%, #00AB4F 101.57%);
    --laranjaGradiente-riber: linear-gradient(264.61deg, #F7931E -9.34%, #F15A24 101.57%);
    --violetaGradiente-riber: linear-gradient(43.84deg, #662D91 -5.32%, #A64DFF 97.27%);
    --azulGradiente-riber: linear-gradient(216.79deg, #13C2C2 3.97%, #4285F4 102.27%);
    --branco: #FFFFFF;
    --preto: #000000;
    --light-green: #B7D9C7;
    --light-orange: #E8D4BD;
    --light-gray: #F9F9F9;
    --dark-gray: #474A57;
	}

 *{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
 }

 html, body {
  background-color: var(--light-gray);
  height: 100%;
  font-family: Open Sans;
 }

 .navbar{
  padding: 6px 10vw;
  min-height: 50px;
  position: fixed;
  top: 0;
  z-index: 100;
  background: var(--branco);
  width: 100%;
  flex-wrap: nowrap;

  & .nav-link {
    align-items: center;
    color: var(--roxo-riber) !important;
    display: flex;
    font-weight: bold;
    gap: 10px;

    & .icon {
      color: var(--verde-riber);
    }
  }
 }

 footer {
  background-image: var(--verdeGradiente-riber);
  bottom: 0;
  color: var(--branco);
  padding: 2%;
  width: 100%;

  & a {
    color: var(--branca);
    text-decoration: none;
  }

  & .redes-sociais {
    display: grid;
    grid-template-columns: 4fr 2fr;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid hsla(0,0%,100%,.4);
  }

  & .contato {
    display: flex;
    align-items: center;
    padding: 25px 10px;
    row-gap: 15px;
    border-bottom: 1px solid hsla(0,0%,100%,.4);
  }

  & .informacoes {
    border-bottom: 1px solid hsla(0,0%,100%,.4);
    display: flex;
    flex-direction: column;
    padding: 10px 2%;
    text-align: justify;
  }

  & .copyright {
    align-items: center;
    padding: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;
    row-gap: 10px;

    & button {
      color: #fff;
      font-weight: 700;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      border: none;
    }
  }
 }

 .pointer{
   cursor: pointer;
 }

 .label-12 {
  color: var(--preto);
  font-family: Open Sans;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
 }

 .row-gap-10 {
  row-gap: 10px;
 }

 .row-gap-15 {
  row-gap: 15px;
 }

 .row-gap-20 {
  row-gap: 20px;
 }

 .column-gap-10 {
  column-gap: 10px;
 }

 .column-gap-15 {
  column-gap: 15px;
 }

 .column-gap-20 {
  column-gap: 20px;
 }

 div.btn-outline-white,
 div.btn-outline-purple,
 div.btn-white,
 div.btn-purple  {
   cursor: pointer;
   min-width: 180px;
   height: 30px;
   border-radius: 5px;
   padding: 10px;
   align-items: center;
   justify-content: space-between;
   display: flex;
   gap: 5px;
 }

 div.btn-outline-purple {
   color: var(--roxo-riber);
   border: var(--roxo-riber) solid 1px;
 }

 div.btn-outline-white {
   border: var(--branco) solid 1px;
 }

 div.btn-purple {
   color: var(--branco);
   border: var(--roxo-riber) solid 1px;
   background: var(--roxo-riber);
 }

 div.btn-white {
  color: var(--roxo-riber);
  border: var(--roxo-riber) solid 1px;
  background: var(--branco);
 }

 .linha-horizontal{
  width: 100%;
  border-bottom: 1px solid var(--cinzaClaro-riber);
 }

 .onlyMobile{
   display: none !important;
 }

 .table-header--purple{
  color: var(--branco);
  background: var(--roxo-riber);
 }

 .table-header--lines{
  color: var(--branco);
  font-size: 12px;
    & .MuiDataGrid-columnHeaderTitle {
      white-space: break-spaces !important;
      line-height: 14px !important;
    }
  }

 .table-cell--lines {
    font-size: 14px;
    white-space: normal !important;
    word-wrap: break-word !important;
  }

  .table-cell-trucate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .error-message {
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    color: #dc3545;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }

  .dropdown-custom {
    button {
      background-color: transparent !important;
      border: none;
    }
  }

 @media (max-width: 992px) {
  .onlyMobile{
    display: block !important;
  }
}
`

export const AtualizacaoTokenWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  & .input-group {
    width: 120px;

    & input {
      height: 35px;
      font-size: 12px;
    }

    & .input-group-text {
      display: flex;
      justify-content: center;
      height: 35px;
      width: 20px;
      padding: 2px;
    }
  }

  & .btn-envio {
    color: var(--verde-riber);
  }
`

export default GlobalStyle

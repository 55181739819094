import { SagaIterator } from 'redux-saga'
import { call, fork, put, all, takeEvery, select } from 'redux-saga/effects'
import { LOGIN, TYPES } from './types'
import * as loginController from 'controller/loginController'
import * as loginActions from 'store/modules/login/actions'
import { setAuthorization } from 'helpers/api/apiCore'
import { toast } from 'react-toastify'
import { ApplicationState } from 'store'
import history from 'util/history'
import { handlerError } from 'util/handlerError'
import { abandonoClienteRedirect } from 'util/abandonoClienteRedirect'

function* login(): SagaIterator {
  try {
    yield put(loginActions.setLoading(true))
    const { login }: LOGIN = yield select(
      (state: ApplicationState) => state.login
    )
    const responseCrm = yield call(loginController.loginSimulador, {
      email: login.email,
      senha: login.senha
    })
    if (login.lembrar) {
      localStorage.setItem('email_login', login.email)
    } else {
      localStorage.removeItem('email_login')
    }
    yield put(loginActions.cleanLogin())
    setAuthorization(responseCrm?.data?.content?.token || null)
    abandonoClienteRedirect(responseCrm?.data?.content.status)
  } catch (error) {
    handlerError(error, 'Não foi possível realizar o login!')
  } finally {
    yield put(loginActions.setLoading(false))
  }
}

function* logout(): SagaIterator {
  yield call(loginController.logoutSistema)
}

interface RecuperarAcessoProps {
  type: string
  id: string
}

function* recuperarAcesso(): SagaIterator {
  const { login }: LOGIN = yield select(
    (state: ApplicationState) => state.login
  )
  try {
    yield put(loginActions.setLoading(true))
    yield call(loginController.recuperarAcesso, login.email)
    yield put(loginActions.cleanLogin())
    toast.info(
      'O e-mail para redefinição de senha será enviado para o e-mail informado caso seja de algum usuário pré-cadastrado'
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loginActions.setLoading(false))
  }
}

function* validaGuidRecuperacao({ id }: RecuperarAcessoProps): SagaIterator {
  try {
    yield put(loginActions.setLoading(true))
    yield call(loginController.validaGuidRecuperacao, id)
  } catch (error) {
    handlerError(error)
    history.push('/Login')
  } finally {
    yield put(loginActions.setLoading(false))
  }
}

function* redefinirSenha({ id }: RecuperarAcessoProps): SagaIterator {
  const { recuperacao }: LOGIN = yield select(
    (state: ApplicationState) => state.login
  )
  try {
    yield put(loginActions.setLoading(true))
    yield call(
      loginController.redefinirSenha,
      id,
      recuperacao.senha,
      recuperacao.confirmacaoSenha
    )
    toast.success('Senha redefinida com sucesso!')
    history.push('/Login')
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loginActions.setLoading(false))
  }
}

function* getStatusLead(): SagaIterator {
  try {
    yield put(loginActions.setLoading(true))
    const response = yield call(loginController.getStatusLead)
    yield call(abandonoClienteRedirect, response?.data?.content.status)
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loginActions.setLoading(false))
  }
}

export function* watchLogin() {
  yield takeEvery(TYPES.LOGIN_SAGA, login)
}

export function* watchLogout() {
  yield takeEvery(TYPES.LOGOUT_SAGA, logout)
}

export function* watchRecuperarAcesso() {
  yield takeEvery(TYPES.RECUPERAR_ACESSO_SAGA, recuperarAcesso)
}

export function* watchValidaGuidRecuperacao() {
  yield takeEvery(TYPES.VALIDA_GUID_RECUPERACAO_SAGA, validaGuidRecuperacao)
}

export function* watchRedefinirSenha() {
  yield takeEvery(TYPES.REDEFINIR_SENHA_SAGA, redefinirSenha)
}

export function* watchGetStatusLead() {
  yield takeEvery(TYPES.GET_STATUS_LEAD_SAGA, getStatusLead)
}

function* loginSaga() {
  yield all([
    fork(watchLogin),
    fork(watchLogout),
    fork(watchRecuperarAcesso),
    fork(watchValidaGuidRecuperacao),
    fork(watchRedefinirSenha),
    fork(watchGetStatusLead)
  ])
}

export default loginSaga

import React, { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Collapse } from 'reactstrap'
import { CardFaq, FaqWrapper } from '../style'
import imageFaq from 'assets/images/apresentacao/creditoPessoal4.png'

const FaqCreditoPessoal = () => {
  const [cardsState, setCardsState] = useState({
    firstCard: false,
    secondCard: false,
    thirdCard: false,
    fourthCard: false,
    fifthCard: false,
    sixthCard: false,
    seventhCard: false,
    eighthCard: false,
    ninthCard: false,
    tenthCard: false
  })

  const toggleCardVisibility = (cardName: keyof typeof cardsState) => {
    setCardsState((prevCards) => ({
      ...prevCards,
      [cardName]: !prevCards[cardName]
    }))
  }

  return (
    <FaqWrapper backgroundImage={imageFaq}>
      <div className="d-none d-lg-flex">
        <img className="img-faq" src={imageFaq} />
      </div>
      <div className="faq">
        <h3>Perguntas Frequentes</h3>
        <CardFaq>
          <label>
            Quem pode contratar o empréstimo pessoal Dinheiro Extra?
            {cardsState.firstCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('firstCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('firstCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.firstCard}>
            <p>
              Aposentados e/ou pensionistas (INSS), Servidores Públicos
              (Federal, Estadual e Municipal) podem solicitar o empréstimo
              pessoal.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Estou sem margem no consignado, posso fazer um empréstimo pessoal?
            {cardsState.secondCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('secondCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('secondCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.secondCard}>
            <p>
              Sim, após aprovada a análise de crédito! Envie um &quot;Oi&quot;
              para o nosso número 0800 941 1111 para falar com um de nossos
              consultores e conferir as ofertas e condições disponíveis para
              você.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Qual o limite de crédito por pessoa para o empréstimo pessoal
            Dinheiro Extra?
            {cardsState.thirdCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('thirdCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('thirdCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.thirdCard}>
            <p>
              O valor do limite do empréstimo pessoal é concedido conforme a
              análise de crédito. Converse com um de nossos consultores pelo
              whatsapp 0800 941 1111 e confira as ofertas e condições
              disponíveis para você!
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Qual a diferença entre empréstimo consignado e o empréstimo pessoal?
            {cardsState.fourthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('fourthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('fourthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.fourthCard}>
            <p>
              O dinheiro extra, empréstimo pessoal, é a modalidade de crédito
              mais oferecida em todo o país, por quase todas as instituições
              financeiras, é a opção que concede crédito a pessoas físicas em
              geral. Já o empréstimo consignado é destinado, especialmente, a
              aposentados, pensionistas do INSS e servidores públicos, e
              apresentam juros mais baixos que o empréstimo pessoal, pois as
              parcelas são descontadas diretamente na folha de pagamento ou
              benefício recebido.
            </p>
          </Collapse>
        </CardFaq>
        <CardFaq>
          <label>
            Qual o prazo para pagamento do empréstimo pessoa Dinheiro Extra?
            {cardsState.fifthCard ? (
              <FiChevronUp
                size={20}
                onClick={() => {
                  toggleCardVisibility('fifthCard')
                }}
                className="icon"
              />
            ) : (
              <FiChevronDown
                size={20}
                onClick={() => {
                  toggleCardVisibility('fifthCard')
                }}
                className="icon"
              />
            )}
          </label>
          <Collapse isOpen={cardsState.fifthCard}>
            <p>Nosso empréstimo pessoal você consegue pagar em até 18 meses</p>
          </Collapse>
        </CardFaq>
      </div>
    </FaqWrapper>
  )
}

export default FaqCreditoPessoal

import styled from 'styled-components'

export const CorpoConteudo = styled.main`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  min-height: calc(100vh - 60px);
  position: relative;
  row-gap: 15px;
  top: 60px;
  width: 100%;
`

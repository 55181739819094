import { EnumProdutos } from 'models/enums'
export enum TYPES {
  SET_PROPOSTAS_JA_CONTRATADAS = '@@propostas/SET_PROPOSTAS_JA_CONTRATADAS',
  CLEAN_PROPOSTAS_JA_CONTRATADAS = '@@propostas/CLEAN_PROPOSTAS_JA_CONTRATADAS',

  GET_PROPOSTAS_JA_CONTRATADAS_SAGA = '@@propostas/GET_PROPOSTAS_JA_CONTRATADAS_SAGA'
}

export interface PROPOSTAS {
  propostas: PropostasJaContratadas[]
}

interface PropostasJaContratadas {
  quantidadeParcelas: number
  valorParcela: number
  valorTotal: number
  produto: keyof typeof EnumProdutos
  dataCriacao: string
  statusProposta: string
}

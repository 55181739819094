import React from 'react'
import InputCustom from 'components/Inputs'
import { Col, Row } from 'reactstrap'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { SIMULACAO } from 'store/modules/simulacao/types'
import CustomSelect from 'components/Select'
import { SimpleTitleCard } from 'styles/global'
import { FiGlobe } from 'react-icons/fi'

interface InssProps {
  indexPerfil: number
}

const Inss = ({ indexPerfil }: InssProps) => {
  const { infosClienteResumo, optionsSelect } = useSelector<
    ApplicationState,
    SIMULACAO
  >((state) => state.simulacao)

  return (
    <div className="d-flex flex-column row-gap-15">
      <div className="container-fluid">
        <Row>
          <Col md={4}>
            <label className="label-12">Perfil</label>
            <CustomSelect
              options={optionsSelect.perfis}
              placeholder="Selecione"
              value={
                infosClienteResumo.matriculas?.[indexPerfil]?.tipoPerfilId || ''
              }
              accessorLabel="descricao"
              accessorValue="id"
              disabled
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Matrícula</label>
            <InputCustom
              name="matricula"
              type="text"
              placeholder="Digite aqui"
              value={
                infosClienteResumo.matriculas?.[indexPerfil]?.numeroMatricula ||
                ''
              }
              maxLength={15}
              disabled
            />
          </Col>
        </Row>
      </div>
      <div className="container-fluid">
        <SimpleTitleCard>
          <FiGlobe className="icon" /> Dados bancários
        </SimpleTitleCard>
        <Row>
          <Col md={4}>
            <label className="label-12">Tipo conta</label>
            <CustomSelect
              options={optionsSelect.tiposConta}
              placeholder="Selecione"
              value={
                infosClienteResumo.matriculas?.[indexPerfil]?.tipoContaId || ''
              }
              accessorLabel="descricao"
              accessorValue="value"
              disabled
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Banco</label>
            <CustomSelect
              options={optionsSelect.bancos}
              placeholder="Selecione"
              value={
                infosClienteResumo.matriculas?.[indexPerfil]
                  ?.bancoDadosBancariosId || ''
              }
              accessorLabel="descricao"
              accessorValue="id"
              disabled
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Agência</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              value={
                infosClienteResumo.matriculas?.[indexPerfil]?.numeroAgencia ||
                ''
              }
              maxLength={10}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label className="label-12">Dígito agência</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              value={
                infosClienteResumo.matriculas?.[indexPerfil]
                  ?.digitoVerificadorAgencia || ''
              }
              maxLength={1}
              disabled
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Número conta</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              value={
                infosClienteResumo.matriculas?.[indexPerfil]?.numeroConta || ''
              }
              maxLength={10}
              disabled
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Dígito conta</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              value={
                infosClienteResumo.matriculas?.[indexPerfil]
                  ?.digitoVerificadorConta || ''
              }
              maxLength={1}
              disabled
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export { Inss }

import { TYPES } from './types'
import { SagaIterator } from 'redux-saga'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as loadingActions from 'store/modules/loading/actions'
import * as dadosComplementaresDocumentoController from 'controller/dadosComplementaresDocumentoController'
import * as dadosComplementaresDocumentoActions from 'store/modules/cadastros/dadosComplementaresDocumento/actions'
import { DADOS_COMPLEMENTARES_DOCUMENTO } from './types'
import { ApplicationState } from 'store'
import { handlerError } from 'util/handlerError'
import { toast } from 'react-toastify'
import { maskDate, removeMaskCPF } from 'util/masks'
import { stepCadastroComplementarRedirect } from 'util/stepCadastroComplementarRedirect'
import * as simulacaoActions from 'store/modules/simulacao/actions'

function* getDadosComplementaresDocumento(): SagaIterator {
  const { cpf }: DADOS_COMPLEMENTARES_DOCUMENTO = yield select(
    (state: ApplicationState) => state.dadosComplementaresDocumento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpf)
    const response = yield call(
      dadosComplementaresDocumentoController.getDadosComplementaresDocumento,
      cpfFormatted
    )
    yield put(
      dadosComplementaresDocumentoActions.setFormulario({
        dataEmissao: maskDate(
          response?.data?.content.dataEmissao,
          'YYYY-MM-DD'
        ),
        nomeMae: response?.data?.content.nomeMae,
        nomePai: response?.data?.content.nomePai,
        orgaoEmissorId: response?.data?.content.orgaoEmissorId,
        numeroRg: response?.data?.content.numeroRg,
        ufOrgaoEmissor: response?.data?.content.ufOrgaoEmissor
      })
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* salvarCadastro(): SagaIterator {
  const { formulario, cpf }: DADOS_COMPLEMENTARES_DOCUMENTO = yield select(
    (state: ApplicationState) => state.dadosComplementaresDocumento
  )
  try {
    yield put(loadingActions.setLoading(true))
    const cpfFormatted = removeMaskCPF(cpf)
    const response = yield call(
      dadosComplementaresDocumentoController.salvarCadastro,
      {
        cpf: cpfFormatted,
        dataEmissao: formulario.dataEmissao,
        nomeMae: formulario.nomeMae,
        nomePai: formulario.nomePai,
        orgaoEmissorId: formulario.orgaoEmissorId,
        numeroRg: formulario.numeroRg,
        ufOrgaoEmissor: formulario.ufOrgaoEmissor
      }
    )
    yield put(dadosComplementaresDocumentoActions.cleanFormulario())
    toast.success('Informações salvas com sucesso!')
    yield put(simulacaoActions.validaStatusIN100Saga())
    stepCadastroComplementarRedirect(
      response?.data?.content.dadosComplementaresEnum
    )
  } catch (error) {
    handlerError(error)
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

function* getOrgaosEmissores(): SagaIterator {
  try {
    yield put(
      dadosComplementaresDocumentoActions.setLoadingSelect(
        true,
        'orgaosEmissor'
      )
    )
    const response = yield call(
      dadosComplementaresDocumentoController.getOrgaosEmissores
    )
    yield put(
      dadosComplementaresDocumentoActions.setOptionsSelect(
        response?.data?.content,
        'orgaosEmissor'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresDocumentoActions.setLoadingSelect(
        false,
        'orgaosEmissor'
      )
    )
  }
}

function* getUfsOrgaosEmissor(): SagaIterator {
  try {
    yield put(
      dadosComplementaresDocumentoActions.setLoadingSelect(
        true,
        'ufsOrgaoEmissor'
      )
    )
    const response = yield call(
      dadosComplementaresDocumentoController.getEstados
    )
    yield put(
      dadosComplementaresDocumentoActions.setOptionsSelect(
        response?.data?.content,
        'ufsOrgaoEmissor'
      )
    )
  } catch (error) {
    //
  } finally {
    yield put(
      dadosComplementaresDocumentoActions.setLoadingSelect(
        false,
        'ufsOrgaoEmissor'
      )
    )
  }
}

function* getCpf(): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true))
    const response = yield call(dadosComplementaresDocumentoController.getCpf)
    yield put(
      dadosComplementaresDocumentoActions.setCpf(response?.data?.content.cpf)
    )
    yield put(
      dadosComplementaresDocumentoActions.getDadosComplementaresDocumentoSaga()
    )
  } catch (error) {
    handlerError(
      error,
      'Não foi possível obter CPF. Tente novamente mais tarde'
    )
  } finally {
    yield put(loadingActions.setLoading(false))
  }
}

export function* watchGetDadosComplementaresDocumento() {
  yield takeEvery(
    TYPES.GET_DADOS_COMPLEMENTARES_DOCUMENTO_SAGA,
    getDadosComplementaresDocumento
  )
}

export function* watchSalvarCadastro() {
  yield takeEvery(TYPES.SALVAR_CADASTRO_SAGA, salvarCadastro)
}

export function* watchGetOrgaosEmissores() {
  yield takeEvery(TYPES.GET_ORGAOS_EMISSORES_SAGA, getOrgaosEmissores)
}

export function* watchGetUfsOrgaosEmissor() {
  yield takeEvery(TYPES.GET_UFS_ORGAOS_EMISSOR_SAGA, getUfsOrgaosEmissor)
}

export function* watchGetCpfSaga() {
  yield takeEvery(TYPES.GET_CPF_SAGA, getCpf)
}

function* dadosComplementaresDocumento() {
  yield all([
    fork(watchGetDadosComplementaresDocumento),
    fork(watchSalvarCadastro),
    fork(watchGetOrgaosEmissores),
    fork(watchGetUfsOrgaosEmissor),
    fork(watchGetCpfSaga)
  ])
}

export default dadosComplementaresDocumento

import InputCustom from 'components/Inputs'
import CustomSelect from 'components/Select'
import React from 'react'
import { FiGlobe } from 'react-icons/fi'
import { Col, Row } from 'reactstrap'
import { SimpleTitleCard } from 'styles/global'
import * as meusDadosActions from 'store/modules/meusDados/actions'
import { MEUS_DADOS } from 'store/modules/meusDados/types'
import { maskCPF } from 'util/masks'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import useRedux from 'hooks/useRedux'

const InformacoesBasicas = () => {
  const { meusDados, invalidMeusDados, optionsSelects, loadingSelects } =
    useSelector<ApplicationState, MEUS_DADOS>((state) => state.meusDados)

  const { dispatch } = useRedux()

  type MeusDadosField = keyof MEUS_DADOS['meusDados']
  const onChange = <T extends MeusDadosField>(
    value: T extends keyof MEUS_DADOS['meusDados']
      ? MEUS_DADOS['meusDados'][T]
      : never,
    field: T
  ) => {
    dispatch(meusDadosActions.setMeusDadosField(value, field))
  }

  return (
    <>
      <SimpleTitleCard>
        <FiGlobe className="icon" /> Informações básicas
      </SimpleTitleCard>
      <div className="container-fluid mb-4">
        <Row>
          <Col md={4}>
            <label className="label-12">Nome completo*</label>
            <InputCustom
              name="nome"
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'nome')
              }}
              value={meusDados.nome}
              invalid={invalidMeusDados.nome.invalid}
              errorMessage={invalidMeusDados.nome.message}
              maxLength={100}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Email*</label>
            <InputCustom
              name="nome"
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'email')
              }}
              value={meusDados.email}
              invalid={invalidMeusDados.email.invalid}
              errorMessage={invalidMeusDados.email.message}
              maxLength={100}
              autoComplete="new-email"
            />
          </Col>
          {/* <Col md={4}>
            <label className="label-12">Senha</label>
            <InputCustom
              name="nome"
              type="password"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'novaSenha')
              }}
              value={meusDados?.novaSenha ?? ''}
              invalid={invalidMeusDados?.novaSenha?.invalid}
              errorMessage={invalidMeusDados?.novaSenha?.message}
              maxLength={100}
              autoComplete="new-password"
            />
          </Col> */}
        </Row>
        <Row>
          <Col md={6}>
            <label className="label-12">Gênero*</label>
            <CustomSelect
              name="sexo"
              options={optionsSelects.generos}
              isLoading={loadingSelects.generos}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'sexo')
              }}
              value={meusDados.sexo}
              accessorLabel="descricao"
              accessorValue="value"
              invalid={invalidMeusDados.sexo.invalid}
              errorMessage={invalidMeusDados.sexo.message}
            />
          </Col>
          <Col md={6}>
            <label className="label-12">Profissão*</label>
            <CustomSelect
              name="profissaoId"
              options={optionsSelects.profissoes}
              isLoading={loadingSelects.profissoes}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'profissaoId')
              }}
              value={meusDados.profissaoId}
              accessorLabel="titulo"
              accessorValue="id"
              invalid={invalidMeusDados.profissaoId.invalid}
              errorMessage={invalidMeusDados.profissaoId.message}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <label className="label-12">RG*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'rg')
              }}
              value={meusDados.rg}
              invalid={invalidMeusDados.rg.invalid}
              errorMessage={invalidMeusDados.rg.message}
              maxLength={15}
            />
          </Col>
          <Col md={2}>
            <label className="label-12">Orgão emissor*</label>
            <CustomSelect
              options={optionsSelects.orgaosEmissor}
              isLoading={loadingSelects.orgaosEmissor}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'orgaoEmissorId')
              }}
              value={meusDados.orgaoEmissorId}
              accessorLabel="nome"
              accessorValue="id"
              invalid={invalidMeusDados.orgaoEmissorId.invalid}
              errorMessage={invalidMeusDados.orgaoEmissorId.message}
            />
          </Col>
          <Col md={2}>
            <label className="label-12">UF emissor*</label>
            <CustomSelect
              options={optionsSelects.ufsOrgaoEmissor}
              isLoading={loadingSelects.ufsOrgaoEmissor}
              placeholder="Selecione"
              onChange={(e) => {
                onChange(e, 'ufOrgaoEmissor')
              }}
              value={meusDados.ufOrgaoEmissor}
              accessorLabel="nome"
              accessorValue="sigla"
              invalid={invalidMeusDados.ufOrgaoEmissor.invalid}
              errorMessage={invalidMeusDados.ufOrgaoEmissor.message}
            />
          </Col>
          <Col md={4}>
            <label className="label-12">Data de expedição*</label>
            <InputCustom
              type="date"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'dataEmissao')
              }}
              value={meusDados.dataEmissao}
              invalid={invalidMeusDados.dataEmissao.invalid}
              errorMessage={invalidMeusDados.dataEmissao.message}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label className="label-12">CPF*</label>
            <InputCustom
              type="text"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(maskCPF(e.target.value), 'cpf')
              }}
              value={meusDados.cpf}
              invalid={invalidMeusDados.cpf.invalid}
              errorMessage={invalidMeusDados.cpf.message}
              maxLength={14}
            />
          </Col>
          <Col md={6}>
            <label className="label-12">Data de nascimento*</label>
            <InputCustom
              type="date"
              placeholder="Digite aqui"
              onChange={(e) => {
                onChange(e.target.value, 'dataNascimento')
              }}
              value={meusDados.dataNascimento}
              invalid={invalidMeusDados.dataNascimento.invalid}
              errorMessage={invalidMeusDados.dataNascimento.message}
            />
          </Col>
        </Row>
      </div>
      <div className="linha-horizontal mb-2"></div>
    </>
  )
}

export default InformacoesBasicas

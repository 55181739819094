import { Reducer } from 'redux'
import { LANDING_PAGE, TYPES } from './types'

const INITIAL_STATE: LANDING_PAGE = {
  cpfLanding: '',
  cpfLandingInvalid: {
    invalid: false,
    message: ''
  },
  sejaParceiro: {
    nome: '',
    email: '',
    telefone: ''
  },
  invalidSejaParceiro: {
    nome: {
      invalid: false,
      message: ''
    },
    email: {
      invalid: false,
      message: ''
    },
    telefone: {
      invalid: false,
      message: ''
    }
  },
  riberMaisPerto: {
    cidade: '',
    siglaEstado: ''
  },
  invalidRiberMaisPerto: {
    cidade: {
      invalid: false,
      message: ''
    },
    siglaEstado: {
      invalid: false,
      message: ''
    }
  },
  optionsSelects: {
    cidades: [],
    estados: []
  },
  loadingSelects: {
    cidades: false,
    estados: false
  },
  riberMaisPertoList: [],
  downloads: {
    pdf: {
      base64: '',
      nome: ''
    }
  },
  loadingDownloads: {
    pdf: false
  },
  cpfProdutos: '',
  cpfProdutosInvalid: {
    invalid: false,
    message: ''
  },
  modalPdf: false,
  modalCpf: false
}

const reducer: Reducer<LANDING_PAGE> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_CPF_LANDING:
      return { ...state, cpfLanding: action.value }
    case TYPES.CLEAN_CPF_LANDING:
      return { ...state, cpfLanding: INITIAL_STATE.cpfLanding }
    case TYPES.SET_CPF_LANDING_INVALID:
      return { ...state, cpfLandingInvalid: action.value }
    case TYPES.CLEAN_CPF_LANDING_INVALID:
      return { ...state, cpfLandingInvalid: INITIAL_STATE.cpfLandingInvalid }
    case TYPES.SET_SEJA_PARCEIRO:
      return {
        ...state,
        sejaParceiro: {
          ...state.sejaParceiro,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_SEJA_PARCEIRO:
      return { ...state, sejaParceiro: INITIAL_STATE.sejaParceiro }
    case TYPES.SET_INVALID_SEJA_PARCEIRO:
      return {
        ...state,
        invalidSejaParceiro: {
          ...state.invalidSejaParceiro,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_INVALID_SEJA_PARCEIRO:
      return {
        ...state,
        invalidSejaParceiro: INITIAL_STATE.invalidSejaParceiro
      }
    case TYPES.SET_RIBER_MAIS_PERTO:
      return {
        ...state,
        riberMaisPerto: {
          ...state.riberMaisPerto,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_RIBER_MAIS_PERTO:
      return { ...state, riberMaisPerto: INITIAL_STATE.riberMaisPerto }
    case TYPES.SET_INVALID_RIBER_MAIS_PERTO:
      return {
        ...state,
        invalidRiberMaisPerto: {
          ...state.invalidRiberMaisPerto,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_INVALID_RIBER_MAIS_PERTO:
      return {
        ...state,
        invalidRiberMaisPerto: INITIAL_STATE.invalidRiberMaisPerto
      }
    case TYPES.SET_OPTIONS_SELECT:
      return {
        ...state,
        optionsSelects: {
          ...state.optionsSelects,
          [action.field]: action.value
        }
      }
    case TYPES.SET_LOADING_SELECTS:
      return {
        ...state,
        loadingSelects: {
          ...state.loadingSelects,
          [action.field]: action.value
        }
      }
    case TYPES.SET_RIBER_MAIS_PERTO_LIST:
      return {
        ...state,
        riberMaisPertoList: action.value
      }
    case TYPES.CLEAN_DOWNLOADS:
      return {
        ...state,
        downloads: INITIAL_STATE.downloads
      }
    case TYPES.SET_DOWNLOADS:
      return {
        ...state,
        downloads: {
          ...state.downloads,
          [action.field]: action.value
        }
      }
    case TYPES.SET_LOADING_DOWNLOADS:
      return {
        ...state,
        loadingDownloads: {
          ...state.loadingDownloads,
          [action.field]: action.value
        }
      }
    case TYPES.SET_CPF_PRODUTOS:
      return {
        ...state,
        cpfProdutos: action.value
      }
    case TYPES.CLEAN_CPF_PRODUTOS:
      return {
        ...state,
        cpfProdutos: INITIAL_STATE.cpfProdutos
      }
    case TYPES.SET_CPF_PRODUTOS_INVALID:
      return {
        ...state,
        cpfProdutosInvalid: action.value
      }
    case TYPES.CLEAN_CPF_PRODUTOS_INVALID:
      return {
        ...state,
        cpfProdutosInvalid: INITIAL_STATE.cpfProdutosInvalid
      }
    case TYPES.SET_MODAL_PDF:
      return {
        ...state,
        modalPdf: action.value
      }
    case TYPES.OPEN_MODAL_CPF:
      return {
        ...state,
        modalCpf: action.value
      }
    default:
      return state
  }
}

export default reducer

import { Reducer } from 'redux'
import { MEUS_DADOS, TYPES } from './types'

const INITIAL_STATE: MEUS_DADOS = {
  meusDados: {
    nome: '',
    cpf: '',
    dataNascimento: '',
    nomePai: '',
    nomeMae: '',
    rg: '',
    orgaoEmissorId: '',
    ufOrgaoEmissor: '',
    dataEmissao: '',
    ddd: '',
    numero: '',
    email: '',
    sexo: '',
    estadoCivil: '',
    nomeConjuge: '',
    cidadeNascimento: '',
    ufNascimento: '',
    matriculas: [],
    escolaridadeId: '',
    cep: '',
    cidade: '',
    siglaEstado: '',
    logradouro: '',
    numeroEndereco: '',
    bairro: '',
    complemento: '',
    profissaoId: '',
    novaSenha: ''
  },
  invalidMeusDados: {
    nome: {
      invalid: false,
      message: ''
    },
    cpf: {
      invalid: false,
      message: ''
    },
    dataNascimento: {
      invalid: false,
      message: ''
    },
    nomePai: {
      invalid: false,
      message: ''
    },
    nomeMae: {
      invalid: false,
      message: ''
    },
    rg: {
      invalid: false,
      message: ''
    },
    orgaoEmissorId: {
      invalid: false,
      message: ''
    },
    ufOrgaoEmissor: {
      invalid: false,
      message: ''
    },
    dataEmissao: {
      invalid: false,
      message: ''
    },
    ddd: {
      invalid: false,
      message: ''
    },
    numero: {
      invalid: false,
      message: ''
    },
    email: {
      invalid: false,
      message: ''
    },
    sexo: {
      invalid: false,
      message: ''
    },
    estadoCivil: {
      invalid: false,
      message: ''
    },
    nomeConjuge: {
      invalid: false,
      message: ''
    },
    cidadeNascimento: {
      invalid: false,
      message: ''
    },
    ufNascimento: {
      invalid: false,
      message: ''
    },
    matriculas: [],
    escolaridadeId: {
      invalid: false,
      message: ''
    },
    cep: {
      invalid: false,
      message: ''
    },
    cidade: {
      invalid: false,
      message: ''
    },
    siglaEstado: {
      invalid: false,
      message: ''
    },
    logradouro: {
      invalid: false,
      message: ''
    },
    numeroEndereco: {
      invalid: false,
      message: ''
    },
    bairro: {
      invalid: false,
      message: ''
    },
    complemento: {
      invalid: false,
      message: ''
    },
    profissaoId: {
      invalid: false,
      message: ''
    },
    novaSenha: {
      invalid: false,
      message: ''
    }
  },
  activeTabBeneficio: 0,
  optionsSelects: {
    perfis: [],
    bancos: [],
    cidades: [],
    cidadesNascimento: [],
    convenios: [],
    escolaridades: [],
    estadosCivis: [],
    generos: [],
    orgaosEmissor: [],
    profissoes: [],
    tiposConta: [],
    ufs: [],
    ufsNascimento: [],
    ufsOrgaoEmissor: []
  },
  loadingSelects: {
    perfis: false,
    bancos: false,
    cidades: false,
    cidadesNascimento: false,
    convenios: false,
    escolaridades: false,
    estadosCivis: false,
    generos: false,
    orgaosEmissor: false,
    profissoes: false,
    tiposConta: false,
    ufs: false,
    ufsNascimento: false,
    ufsOrgaoEmissor: false
  },
  modalIN100: false
}

const reducer: Reducer<MEUS_DADOS> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_MEUS_DADOS:
      return { ...state, meusDados: action.value }
    case TYPES.SET_MEUS_DADOS_FIELD:
      return {
        ...state,
        meusDados: {
          ...state.meusDados,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_MEUS_DADOS:
      return { ...state, meusDados: INITIAL_STATE.meusDados }
    case TYPES.SET_INVALID_MEUS_DADOS:
      return {
        ...state,
        invalidMeusDados: action.value
      }
    case TYPES.SET_INVALID_MEUS_DADOS_FIELD:
      return {
        ...state,
        invalidMeusDados: {
          ...state.invalidMeusDados,
          [action.field]: action.value
        }
      }
    case TYPES.SET_INVALID_MEUS_DADOS_FIELD_ARRAY: {
      const invalids = JSON.parse(JSON.stringify({ ...state.invalidMeusDados }))
      invalids[action.field][action.index] = {
        ...invalids[action.field][action.index],
        [action.nestedNameArray]: action.value
      }
      return {
        ...state,
        invalidMeusDados: invalids
      }
    }
    case TYPES.CLEAN_INVALID_MEUS_DADOS: {
      let invalids = JSON.parse(JSON.stringify({ ...state.invalidMeusDados }))
      invalids = {
        nome: {
          invalid: false,
          message: ''
        },
        cpf: {
          invalid: false,
          message: ''
        },
        dataNascimento: {
          invalid: false,
          message: ''
        },
        nomePai: {
          invalid: false,
          message: ''
        },
        nomeMae: {
          invalid: false,
          message: ''
        },
        rg: {
          invalid: false,
          message: ''
        },
        orgaoEmissorId: {
          invalid: false,
          message: ''
        },
        ufOrgaoEmissor: {
          invalid: false,
          message: ''
        },
        dataEmissao: {
          invalid: false,
          message: ''
        },
        ddd: {
          invalid: false,
          message: ''
        },
        numero: {
          invalid: false,
          message: ''
        },
        email: {
          invalid: false,
          message: ''
        },
        sexo: {
          invalid: false,
          message: ''
        },
        estadoCivil: {
          invalid: false,
          message: ''
        },
        nomeConjuge: {
          invalid: false,
          message: ''
        },
        cidadeNascimento: {
          invalid: false,
          message: ''
        },
        ufNascimento: {
          invalid: false,
          message: ''
        },
        matriculas: invalids.matriculas.map(() => {
          return {
            id: {
              invalid: false,
              message: ''
            },
            numeroMatricula: {
              invalid: false,
              message: ''
            },
            tipoPerfil: {
              invalid: false,
              message: ''
            },
            descricaoPerfil: {
              invalid: false,
              message: ''
            },
            tipoPerfilId: {
              invalid: false,
              message: ''
            },
            convenioId: {
              invalid: false,
              message: ''
            },
            margemDisponivel: {
              invalid: false,
              message: ''
            },
            margemCartaoDisponivel: {
              invalid: false,
              message: ''
            },
            margemCartaoBeneficio: {
              invalid: false,
              message: ''
            },
            contaCorrenteId: {
              invalid: false,
              message: ''
            },
            tipoContaId: {
              invalid: false,
              message: ''
            },
            bancoDadosBancariosId: {
              invalid: false,
              message: ''
            },
            numeroAgencia: {
              invalid: false,
              message: ''
            },
            digitoVerificadorAgencia: {
              invalid: false,
              message: ''
            },
            numeroConta: {
              invalid: false,
              message: ''
            },
            digitoVerificadorConta: {
              invalid: false,
              message: ''
            }
          }
        }),
        escolaridadeId: {
          invalid: false,
          message: ''
        },
        cep: {
          invalid: false,
          message: ''
        },
        cidade: {
          invalid: false,
          message: ''
        },
        siglaEstado: {
          invalid: false,
          message: ''
        },
        logradouro: {
          invalid: false,
          message: ''
        },
        numeroEndereco: {
          invalid: false,
          message: ''
        },
        bairro: {
          invalid: false,
          message: ''
        },
        complemento: {
          invalid: false,
          message: ''
        },
        profissaoId: {
          invalid: false,
          message: ''
        },
        novaSenha: {
          invalid: false,
          message: ''
        }
      }
      return {
        ...state,
        invalidMeusDados: invalids
      }
    }
    case TYPES.SET_ACTIVE_TAB_BENEFICIO:
      return { ...state, activeTabBeneficio: action.value }
    case TYPES.ADICIONAR_PERFIL: {
      const matriculas = [...state.meusDados.matriculas]
      const matriculasInvalids = [...state.invalidMeusDados.matriculas]
      matriculas.push({
        id: '',
        numeroMatricula: '',
        tipoPerfil: action.value.tipoPerfil,
        descricaoPerfil: action.value.descricao,
        tipoPerfilId: action.value.id,
        convenioId: '',
        margemDisponivel: '',
        margemCartaoDisponivel: '',
        margemCartaoBeneficio: '',
        contaCorrenteId: '',
        tipoContaId: '',
        bancoDadosBancariosId: '',
        numeroAgencia: null,
        digitoVerificadorAgencia: null,
        numeroConta: null,
        digitoVerificadorConta: null
      })
      matriculasInvalids.push({
        id: {
          invalid: false,
          message: ''
        },
        numeroMatricula: {
          invalid: false,
          message: ''
        },
        tipoPerfil: {
          invalid: false,
          message: ''
        },
        descricaoPerfil: {
          invalid: false,
          message: ''
        },
        tipoPerfilId: {
          invalid: false,
          message: ''
        },
        convenioId: {
          invalid: false,
          message: ''
        },
        margemDisponivel: {
          invalid: false,
          message: ''
        },
        margemCartaoDisponivel: {
          invalid: false,
          message: ''
        },
        margemCartaoBeneficio: {
          invalid: false,
          message: ''
        },
        contaCorrenteId: {
          invalid: false,
          message: ''
        },
        tipoContaId: {
          invalid: false,
          message: ''
        },
        bancoDadosBancariosId: {
          invalid: false,
          message: ''
        },
        numeroAgencia: {
          invalid: false,
          message: ''
        },
        digitoVerificadorAgencia: {
          invalid: false,
          message: ''
        },
        numeroConta: {
          invalid: false,
          message: ''
        },
        digitoVerificadorConta: {
          invalid: false,
          message: ''
        }
      })
      return {
        ...state,
        meusDados: {
          ...state.meusDados,
          matriculas: matriculas
        },
        invalidMeusDados: {
          ...state.invalidMeusDados,
          matriculas: matriculasInvalids
        }
      }
    }
    case TYPES.REMOVER_PERFIL: {
      const perfisCliente = [...(state.meusDados.matriculas || [])]
      const invalidsMatriculas = [...(state.invalidMeusDados.matriculas || [])]
      perfisCliente.splice(action.indexPerfil, 1)
      invalidsMatriculas.splice(action.indexPerfil, 1)
      return {
        ...state,
        meusDados: {
          ...state.meusDados,
          matriculas: perfisCliente
        },
        invalidMeusDados: {
          ...state.invalidMeusDados,
          matriculas: invalidsMatriculas
        }
      }
    }
    case TYPES.SET_OPTIONS_SELECT:
      return {
        ...state,
        optionsSelects: {
          ...state.optionsSelects,
          [action.field]: action.value
        }
      }
    case TYPES.SET_LOADING_SELECTS:
      return {
        ...state,
        loadingSelects: {
          ...state.loadingSelects,
          [action.field]: action.value
        }
      }
    case TYPES.CLEAN_FIELDS_INVALID_CEP:
      return {
        ...state,
        meusDados: {
          ...state.meusDados,
          bairro: '',
          cep: '',
          cidade: '',
          complemento: '',
          logradouro: '',
          numeroEndereco: '',
          siglaEstado: ''
        }
      }
    case TYPES.TOGGLE_MODAL_IN100:
      return {
        ...state,
        modalIN100: action.value
      }
    default:
      return state
  }
}

export default reducer

import { TYPES } from './types'

export const setNomeUsuario = (value: string) => ({
  type: TYPES.SET_NOME_USUARIO,
  value
})

export const setModalTrocarSenha = (value: { isOpen: boolean }) => ({
  type: TYPES.SET_MODAL_TROCAR_SENHA,
  value
})

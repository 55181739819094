import React from 'react'
import Main from 'layouts/main'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid' // a plugin!
import ptLocale from '@fullcalendar/core/locales/pt'
import SimpleCard from 'components/SimpleCard'
import { CalendarWrapper } from 'views/utilitarios/style'
import { SimpleTitleCard } from 'styles/global'
import { BsCalendarWeek } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store'
import { NAVBAR } from 'store/modules/navbar/types'

const Calendario = () => {
  const { nomeUsuario } = useSelector<ApplicationState, NAVBAR>(
    (state) => state.navbar
  )

  const calendarRef = React.createRef<FullCalendar>()

  return (
    <Main
      titleBreadcrumbs={`Olá, ${nomeUsuario}!`}
      subtitleBreadcrumbs="Confira seu calendário."
    >
      <SimpleCard>
        <SimpleTitleCard>
          <BsCalendarWeek className="icon" />
          Calendário
        </SimpleTitleCard>
        <CalendarWrapper>
          <FullCalendar
            ref={calendarRef}
            locale={ptLocale}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            dayMaxEventRows={2}
            headerToolbar={{
              right: 'today prev,next',
              center: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
          />
        </CalendarWrapper>
      </SimpleCard>
    </Main>
  )
}

export default Calendario

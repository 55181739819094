import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const MenuItemWrapper = styled(Link)`
  text-decoration: none;

  div {
    align-items: center;
    background: var(--verdeGradiente-riber);
    border-radius: 20px;
    color: var(--branco);
    display: flex;
    flex-direction: column;
    font-weight: 700;
    justify-content: center;
    padding: 40px;
    row-gap: 20px;
    width: 200px;
  }

  img {
    width: 80px;
  }
`

export const CalendarWrapper = styled.div`
  & a {
    text-decoration: none;
    color: inherit;
  }

  & .fc-toolbar-title {
    text-transform: uppercase;
  }

  & .fc-col-header-cell-cushion {
    text-transform: capitalize;
  }

  & tr th .fc-scrollgrid-sync-inner {
    background: var(--roxo-riber);
    color: var(--branco);
  }
`

import React from 'react'
import { Input, InputProps } from 'reactstrap'
import { RadioMatriculaWrapper } from '../style'

interface RadioMatriculaProps extends InputProps {
  matricula: string
  index: number
}

const RadioMatricula = ({ ...props }: RadioMatriculaProps) => {
  return (
    <RadioMatriculaWrapper>
      <Input id={`${props.matricula}-${props.index}`} type="radio" {...props} />
      <label htmlFor={`${props.matricula}-${props.index}`}>
        {props.matricula}
      </label>
    </RadioMatriculaWrapper>
  )
}

export default RadioMatricula
